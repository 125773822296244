import gql from 'graphql-tag';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetProfileFollowingQuery } from '../../generated/graphql';
import { Loading } from '../Loading';
import ProfileList from '../ProfileList';

export default function Following() {
  const { slug } = useParams();

  const { data, loading, error } = useGetProfileFollowingQuery({
    variables: { input: { slug: slug } },
    fetchPolicy: 'cache-and-network',
  });

  if (error) {
    console.error(error);
  }

  if (loading) {
    return <Loading />;
  }

  const profiles = data?.profile?.user?.following?.edges ?? [];

  if (!profiles.length) {
    return <div>No users found</div>;
  } else {
    // @ts-ignore
    return <ProfileList profiles={profiles} />;
  }
}

gql`
  query getProfileFollowing($input: ProfileSearchInput!) {
    profile(input: $input) @connection(key: "profile") {
      id
      slug
      user {
        id
        following {
          edges {
            node {
              ...ProfileData
            }
          }
        }
      }
    }
  }
  ${ProfileList.fragments.profile}
`;
