import { Transition } from '@headlessui/react';
import React from 'react';
import { useAuthentication } from '../../lib/hooks';
import { ReactComponent as Logo } from '../../icons/Giftory_Icon.svg';
import { Link, useLocation } from 'react-router-dom';

export const LoginToast = () => {
  const isAuthenticated = useAuthentication();
  const location = useLocation();
  const isNotLoginOrSignUp =
    location.pathname !== '/login' && location.pathname !== '/signup';

  return (
    <Transition
      show={!isAuthenticated && isNotLoginOrSignUp}
      className='fixed z-10 flex p-2 transform -translate-x-1/2 bg-white border rounded-full shadow-lg w-60 border-gray-light h-14 bottom-20 left-1/2 lg:hidden lg:top-10 lg:right-10 lg:left-auto lg:translate-x-0'
    >
      <div className='flex justify-center w-10 h-10 mr-2 rounded-full bg-primary'>
        <Logo className='h-full text-white transform fill-current scale-60' />
      </div>
      <span className='inline-flex flex-col text-sm'>
        <div className='whitespace-nowrap'>Don't have an account?</div>
        <div>
          <Link to='/login' className='text-blue-500'>
            Login
          </Link>{' '}
          or{' '}
          <Link to='/signup' className='text-blue-500'>
            Sign Up
          </Link>
        </div>
      </span>
    </Transition>
  );
};
