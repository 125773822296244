import React, { useState, Fragment, ReactNode } from 'react';
import { Link, useHistory, Route, Switch } from 'react-router-dom';
import logo from '../Logo.png';
import blackLogo from '../icons//blacklogo.png';
import { ReactComponent as MenuIcon } from '../icons/Menu.svg';
import { useAuthentication, useQueryParams } from '../lib/hooks';
import { Disclosure, Popover, Transition } from '@headlessui/react';
import Input from './Input';
import { ReactComponent as Plus } from '../icons/Plus.svg';
import { ProfileImage } from './Profile/Link';
import SearchIcon from '@heroicons/react/solid/SearchIcon';
import ProfileList from './ProfileList';
import isEmpty from 'lodash/isEmpty';
import UserIcon from '@heroicons/react/outline/UserIcon';
import TagIcon from '@heroicons/react/outline/TagIcon';
import CogIcon from '@heroicons/react/outline/CogIcon';
import LogoutIcon from '@heroicons/react/outline/LogoutIcon';
import { AddItemSlideover } from './AddItemSlidover';
import {
  GetInitialInfoQuery,
  useGetProfilesLazyQuery,
} from '../generated/graphql';
import ReactDOM from 'react-dom';
import { Spinner } from './Loading';
import BackButton from './BackButton';
import mixpanel from 'mixpanel-browser';
import { Button, PrimaryButton } from './Button';

const appRoot = document.getElementById('root') as HTMLFormElement;

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

interface UserMenuProps {
  data: GetInitialInfoQuery;
}

const UserMenu = ({ data }: UserMenuProps) => {
  const slug = data.viewer.profile?.slug ?? '';

  const routes = [
    {
      title: 'Profile',
      Icon: UserIcon,
      as: Link,
      props: {
        to: `/wishlist/${slug}`,
      },
    },
    {
      title: 'Purchased',
      Icon: TagIcon,
      as: Link,
      props: {
        to: `/wishlist/${slug}/purchased`,
      },
    },
    {
      title: 'Settings',
      Icon: CogIcon,
      as: Link,
      props: {
        to: '/settings',
      },
    },
    {
      title: 'Sign Out',
      Icon: LogoutIcon,
      as: 'a',
      props: {
        href: '/logout',
      },
    },
  ];

  return (
    <Popover className='relative mr-3'>
      {({ open }) => (
        <>
          <Popover.Button
            className={classNames(
              open ? 'text-gray-900' : 'text-gray-500',
              'group bg-gray-100 py-2 px-4 rounded-md space-x-2 inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary focus:ring-opacity-30'
            )}
          >
            <ProfileImage profile={data?.viewer.profile ?? {}} diameter={26} />
            <i className='fas fa-chevron-down'></i>
          </Popover.Button>

          <Transition
            show={open}
            as={Fragment}
            enter='transition ease-out duration-200'
            enterFrom='opacity-0 translate-y-1'
            enterTo='opacity-100 translate-y-0'
            leave='transition ease-in duration-150'
            leaveFrom='opacity-100 translate-y-0'
            leaveTo='opacity-0 translate-y-1'
          >
            <Popover.Panel className='absolute right-0 z-20 w-screen max-w-xs px-2 mt-3 transform sm:px-0'>
              <div className='overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5'>
                <div className='relative grid grid-cols-1 py-2 bg-white'>
                  {routes.map(({ title, Icon, as, props }) => (
                    <Popover.Button
                      as={as}
                      key={title}
                      {...props}
                      className='flex items-center px-4 py-3 text-gray-900 hover:bg-gray-100'
                    >
                      <Icon className='w-6 h-6 mr-4 text-gray' />
                      <span className='text-base text-gray-900'>{title}</span>
                    </Popover.Button>
                  ))}
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

const SearchInput = () => {
  const history = useHistory();
  const params = useQueryParams();
  const [getProfiles, { data, loading }] = useGetProfilesLazyQuery();
  const [value, setValue] = useState(params.get('q') || '');
  const [open, setOpen] = useState(false);

  const onSubmit = e => {
    e.preventDefault();
    e.stopPropagation();
    history.push(`/search?q=${value}`);
    setOpen(false);
  };

  const onValueChange = e => {
    if (e.target.value) {
      getProfiles({
        variables: {
          search: e.target.value,
          count: 5,
        },
      });
      setOpen(true);
    } else {
      setOpen(false);
    }
    setValue(e.target.value);
  };

  const onProfileClick = () => {
    setValue('');
    setOpen(false);
  };

  const icon = (
    <button
      type='submit'
      className='p-1 px-2 text-gray-500 transition duration-200 rounded-md hover:bg-primary hover:text-white'
    >
      <SearchIcon className='w-5 h-5' />
    </button>
  );

  let results: ReactNode = null;

  if (loading) {
    results = (
      <div className='flex justify-center items-center h-20'>
        <Spinner className='text-primary' />
      </div>
    );
  } else {
    if (isEmpty(data?.profiles?.edges)) {
      results = (
        <div className='flex justify-center items-center h-20'>
          <div>No users match your search criteria</div>
        </div>
      );
    } else {
      results = (
        <ProfileList
          // @ts-ignore
          profiles={data?.profiles?.edges ?? []}
          onClick={onProfileClick}
        />
      );
    }
  }

  return (
    <form className='md:w-64 lg:w-96' onSubmit={onSubmit}>
      <div className='relative'>
        <Input
          placeholder='Search'
          onChange={onValueChange}
          value={value}
          icon={icon}
          data-cy='search-input'
          onKeyDown={e => {
            if (e.key === 'Escape') {
              setOpen(false);
            }
          }}
        />
        {(loading || open) &&
          ReactDOM.createPortal(
            <div
              className='absolute top-0 z-50 w-screen h-screen'
              onClick={() => {
                setOpen(false);
              }}
            >
              <div className='absolute z-20 w-screen max-w-lg p-5 mt-2 transform -translate-x-1/2 bg-white border-gray-300 rounded-lg shadow-xl top-20 left-1/2'>
                {results}
              </div>
            </div>,
            appRoot
          )}
      </div>
    </form>
  );
};

interface NavProps {
  data: GetInitialInfoQuery;
}

export default function Nav({ data }: NavProps) {
  const isAuthenticated = useAuthentication();
  const [isOpen, setOpen] = useState(false);

  if (isAuthenticated) {
    mixpanel.identify(data.viewer.user?.id);
    mixpanel.people.set({
      $name: data.viewer.profile?.name,
    });
  }

  return (
    <>
      <Disclosure as='nav' className='w-full md:mb-3 bg-primary md:bg-gray-50'>
        {({ open }) => (
          <>
            <div className='px-2 mx-auto sm:px-6 md:container'>
              <div className='relative flex justify-between h-16 xl:grid xl:grid-cols-12 lg:gap-8'>
                <div className='absolute inset-y-0 left-0 flex items-center md:hidden'>
                  {/* Mobile menu button */}
                  <Switch>
                    <Route
                      exact
                      path={`/wishlist/:profileSlug/:itemSlug`}
                      render={() => <BackButton />}
                    />
                    <Route
                      render={() => (
                        <Disclosure.Button className='inline-flex items-center justify-center p-2 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'>
                          <span className='sr-only'>Open main menu</span>
                          <MenuIcon />
                        </Disclosure.Button>
                      )}
                    />
                  </Switch>
                </div>

                {/* Logo */}
                <div className='flex items-center justify-center flex-1 md:justify-start sm:items-stretch md:absolute md:left-0 md:inset-y-0 xl:col-span-2'>
                  <div className='flex items-center flex-shrink-0'>
                    <Link to={'/'} className='block'>
                      <img className='h-8 md:hidden' src={logo} alt='logo' />
                      <img
                        className='hidden h-8 md:block'
                        src={blackLogo}
                        alt='logo'
                      />
                    </Link>
                    <div className='hidden md:ml-6 md:flex md:space-x-8'>
                      <Link
                        to='/feed'
                        className='text-sm font-medium text-gray'
                      >
                        Discover
                      </Link>
                    </div>
                  </div>
                </div>

                <div className='flex-1 hidden min-w-0 md:flex md:px-8 lg:px-0 xl:col-span-full'>
                  <div className='flex items-center md:max-w-3xl md:mx-auto'>
                    <SearchInput />
                  </div>
                </div>

                {isAuthenticated && (
                  <div className='absolute inset-y-0 right-0 flex items-center md:hidden'>
                    {/* Mobile settings button */}
                    <Switch>
                      <Route
                        exact
                        path={`/wishlist/${data.viewer.profile!.slug}`}
                        render={() => (
                          <Link to='/settings'>
                            <CogIcon className='w-7 h-7 text-white' />
                          </Link>
                        )}
                      />
                    </Switch>
                  </div>
                )}

                <div className='items-center hidden md:flex md:absolute md:right-0 md:inset-y-0'>
                  {isAuthenticated ? (
                    <>
                      <UserMenu data={data} />
                      <div className='flex-shrink-0'>
                        <PrimaryButton
                          onClick={() => setOpen(true)}
                          className='flex items-center px-4 py-2 text-sm text-white rounded-md bg-primary hover:no-underline'
                        >
                          <Plus className='mr-3 text-white transform w-3.5 h-3.5 fill-current' />
                          <span>Add Item</span>
                        </PrimaryButton>
                      </div>
                    </>
                  ) : (
                    <>
                      <Link
                        to='/login'
                        className='px-4 py-2 mr-3 text-white rounded-md bg-primary'
                      >
                        Login
                      </Link>
                      <Link to='/signup' className='text-gray-700'>
                        Sign Up
                      </Link>
                    </>
                  )}
                </div>
              </div>
            </div>

            <Transition show={open}>
              <Disclosure.Panel static className='md:hidden'>
                <div className='pb-4 pl-2 space-y-1 sm:px-6 lg:px-8'>
                  {isAuthenticated && (
                    <Link to='/settings' className='block text-white'>
                      Settings
                    </Link>
                  )}
                  <Link to='/privacy' className='block text-white'>
                    Privacy Policy
                  </Link>
                  <Link to='/terms' className='block text-white'>
                    Terms of Service
                  </Link>
                  {isAuthenticated && (
                    <a className='block text-white' href='/logout'>
                      Sign Out
                    </a>
                  )}
                </div>
              </Disclosure.Panel>
            </Transition>
          </>
        )}
      </Disclosure>
      {isAuthenticated && (
        <AddItemSlideover
          open={isOpen}
          setOpen={setOpen}
          // @ts-ignore
          profile={data.viewer.profile}
        />
      )}
    </>
  );
}
