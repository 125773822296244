import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { gql } from '@apollo/client';
import { useItemSorting, getSortObj } from '../components/SortButton';
import { ProfileLink } from '../components/Profile/Link';
import Options from '../components/Items/Options';
import Items from '../components/Items';
import isEmpty from 'lodash/isEmpty';
import { useItemLayout, usePageView } from '../lib/hooks';
import { Loading } from '../components/Loading';
import { useGetPurchasedItemsLazyQuery } from '../generated/graphql';

const Purchased = () => {
  usePageView('Purchased');
  const { slug } = useParams();
  const [sort] = useItemSorting();
  const [layout] = useItemLayout();
  const [
    getItems,
    { data, loading, error, fetchMore },
  ] = useGetPurchasedItemsLazyQuery({
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-first',
    returnPartialData: true,
  });

  useEffect(() => {
    const obj = getSortObj(sort);

    getItems({
      variables: {
        input: {
          slug,
        },
        ...obj,
      },
    });
  }, [sort, getItems, slug]);

  if (error) {
    console.log('Error', error);
  }

  if (isEmpty(data?.profile) || loading) {
    return (
      <>
        <div className='container pb-3 md:pb-0'>
          <div className='flex items-center flex-grow'>
            <h3 className='inline mb-0 mr-2 text-2xl font-bold'>
              My Purchases
            </h3>
          </div>
          <Loading />
        </div>
      </>
    );
  }

  const listItems = data?.profile?.archived?.listItems;
  const profile = data?.profile;

  return (
    <>
      <div className='container pb-3 md:pb-0'>
        <div className='flex items-center flex-grow'>
          <h3 className='inline mb-0 mr-2 text-2xl font-bold'>
            {data?.viewer.profile?.id === data?.profile?.id
              ? 'My Purchases'
              : data?.profile?.name}
          </h3>
        </div>
        <div className='flex justify-between mt-4 mb-2'>
          <div className='flex items-center text-gray'>
            {profile && (
              <>
                <ProfileLink profile={profile} />
                <span className='px-2'>|</span>
              </>
            )}
            <span className='text-sm'>{listItems?.total} Items</span>
          </div>
          <Options />
        </div>
        <Items
          fetchMore={fetchMore}
          items={listItems}
          sort={sort}
          layout={layout}
          hideName
          showReserved={false}
          pageKey='purchased'
        />
      </div>
    </>
  );
};

gql`
  query getPurchasedItems(
    $input: ProfileSearchInput!
    $first: Int! = 0
    $after: String! = ""
    $last: Int! = 0
    $before: String! = ""
    $orderBy: [ListItemsOrderBy]! = TITLE
  ) {
    viewer {
      isAuthenticated
      profile {
        id
        slug
      }
    }
    profile(input: $input) @connection(key: "profile") {
      id
      slug
      name
      viewerCanEditList
      ...ProfileThumbnail
      archived {
        ... on ProfileArchived {
          ...ItemPagination
        }
      }
      user {
        id
      }
    }
  }
  ${Items.fragments.items}
  ${ProfileLink.fragments.profile}
`;

export default Purchased;
