import React from 'react';

export const Tooltip = ({ children }) => (
  <div className='relative group'>{children}</div>
);

Tooltip.Content = ({ children, className = '' }) => (
  <div
    className={`invisible group-hover:visible absolute transform origin-center rounded-lg z-20 whitespace-nowrap text-white bg-gray-800 p-3 translate-y-full -bottom-2 left-1/2 -translate-x-1/2 ${className}`}
  >
    {children}
  </div>
);
