import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useItemSorting, getSortObj } from '../../components/SortButton';
import Options from '../../components/Items/Options';
import Items from '../../components/Items';
import isEmpty from 'lodash/isEmpty';
import NotFound from '../404';
import Settings from '../../components/Collection/settings';
import EditName from '../../components/Collection/EditName';
import { useItemLayout } from '../../lib/hooks';
import { Loading } from '../../components/Loading';
import { useGetProfileCollectionLazyQuery } from '../../generated/graphql';
import gql from 'graphql-tag';
import Image from '../../components/Collection/image';
import { Image as CollImage } from '../../components/Item/Image';

const Collection = ({ setPage }) => {
  const { slug, collectionSlug } = useParams();
  const [sort] = useItemSorting();
  const [layout] = useItemLayout();
  const [
    getCollectionItems,
    { data, loading, error, fetchMore },
  ] = useGetProfileCollectionLazyQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    returnPartialData: true,
  });

  useEffect(() => {
    const obj = getSortObj(sort);

    getCollectionItems({
      variables: {
        input: {
          profileSlug: slug,
          collectionSlug,
        },
        ...obj,
      },
    });
  }, [sort, getCollectionItems, slug, collectionSlug]);

  if (error) {
    console.log('Error', error);
  }

  if (isEmpty(data?.collection) && loading) {
    return <Loading />;
  }

  if (isEmpty(data?.collection)) {
    return <NotFound />;
  }

  const listItems = data?.collection?.listItems;
  const profile = data?.collection?.profile;
  const isViewerChildProfile =
    profile?.viewerCanEditList && data?.viewer.profile?.id !== profile?.id;
  const isProxyProfile = profile?.user === null;

  return (
    <>
      <div className='pb-3 md:pb-0'>
        <div className='flex items-center justify-between flex-grow md:justify-start'>
          <h3 className='inline mb-0 mr-2 text-2xl font-bold'>
            {data?.collection?.title}
          </h3>
          {profile?.viewerCanEditList && collectionSlug !== 'uncollected' && (
            <button
              className='px-4 py-1 border rounded-md border-gray'
              onClick={() => setPage('settings')}
            >
              Edit
            </button>
          )}
        </div>
        <div className='flex justify-between mt-4 mb-2'>
          <div className='flex items-center text-gray'>
            <span className='text-sm whitespace-nowrap'>
              {listItems?.total} Items
            </span>
          </div>
          <Options />
        </div>
        <Items
          fetchMore={fetchMore}
          items={listItems}
          sort={sort}
          layout={layout}
          hideName
          pageKey='collectionSlug'
          breakpointCols={'w-1/2 sm:w-1/3 md:w-1/3 lg:w-1/4 xl:w-1/5'}
          showReserved={
            (data?.viewer.isAuthenticated &&
              (!profile?.viewerCanEditList || isViewerChildProfile)) ||
            isProxyProfile
          }
        />
      </div>
    </>
  );
};

export default function Slug() {
  const [page, setPage] = useState('collection');

  const pages = {
    collection: <Collection setPage={setPage} />,
    settings: <Settings setPage={setPage} />,
    editName: <EditName setPage={setPage} />,
    image: <Image setPage={setPage} />,
  };

  return pages[page];
}

gql`
  query getProfileCollection(
    $input: CollectionSearchInput!
    $orderBy: [ListItemsOrderBy]! = TITLE
    $first: Int! = 0
    $last: Int! = 0
    $after: String! = ""
    $before: String! = ""
  ) {
    viewer {
      isAuthenticated
      profile {
        id
        slug
      }
    }
    collection(input: $input) @connection(key: "collection") {
      id
      title
      slug
      image {
        ...ItemImage
      }
      profile {
        id
        slug
        user {
          id
        }
        viewerCanEditList
      }
      ...ItemPagination
    }
  }
  ${Items.fragments.items}
  ${CollImage.fragments.image}
`;
