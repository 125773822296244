import React, { useState, useReducer } from 'react';
import { Link } from 'react-router-dom';
import { gql } from '@apollo/client';
import { validate } from 'email-validator';
import ReactGa from 'react-ga';
import GoogleBtn from '../components/GoogleBtn';
import Input from '../components/Input';
import { Spinner } from '../components/Loading';
import { StatusToast, StatusType } from '../components/Toasts/Status';
import { Alert } from '../types/lib';
import { useNewAccountMutation } from '../generated/graphql';
import mixpanel from 'mixpanel-browser';
import { usePageView } from 'src/lib/hooks';

const initialState = {
  success: null,
  error: null,
  inProg: false,
};

function reducer(state, action) {
  switch (action.type) {
    case 'success':
      return {
        success: action.payload,
        error: null,
        inProg: false,
      };
    case 'error':
      return {
        success: null,
        error: action.payload,
        inProg: false,
      };
    case 'progress':
      return {
        success: null,
        error: null,
        inProg: true,
      };
    case 'reset':
      return initialState;
    default:
      throw new Error();
  }
}

export default function Login() {
  usePageView('Sign up');
  const [state, dispatch] = useReducer(reducer, initialState);
  const [email, setEmail] = useState('');
  const [pass, setPass] = useState('');
  const [pass2, setPass2] = useState('');
  const [name, setName] = useState('');
  const [alert, setAlert] = useState<Alert>({
    open: false,
    type: StatusType.SUCCESS,
    message: null,
  });

  const [addUser] = useNewAccountMutation({
    onCompleted: ({ createAccount }) => {
      if (createAccount?.__typename === 'CreateAccountError') {
        if (createAccount.error === 'DuplicateEmail') {
          console.error('ERROR DUPLICATE');
          setAlert({
            open: true,
            type: StatusType.DANGER,
            message: (
              <div>
                This email is already in use.{' '}
                <Link to='/login' className='underline'>
                  Login
                </Link>{' '}
                or{' '}
                <Link to='/forgotpassword' className='underline'>
                  reset your password
                </Link>{' '}
                to access your profile.
              </div>
            ),
          });
        }
      } else {
        setAlert({
          open: true,
          type: StatusType.SUCCESS,
          message:
            'Almost done! We sent you an email with a confirmation link. Check your email to get started.',
        });
      }

      dispatch({ type: 'reset' });
      setEmail('');
      setPass('');
      setPass2('');
      setName('');
      mixpanel.track('Account Created');
    },
    onError: e => {
      setAlert({
        open: true,
        type: StatusType.DANGER,
        message: 'There was an error creating your account. Please try again.',
      });
      dispatch({ type: 'error' });

      ReactGa.exception({
        description: 'Error Creating account: ' + e,
      });
    },
  });

  function createUser(e) {
    e.preventDefault();
    dispatch({ type: 'progress' });

    addUser({
      variables: {
        data: {
          email,
          password: pass,
          name,
        },
      },
    });
  }

  return (
    <div className='flex justify-center px-3 md:px-0'>
      <div className='flex flex-col w-full max-w-md'>
        <div className='pb-2 text-base font-medium md:text-lg'>
          Create account with
        </div>
        <div className='md:w-72 md:mx-auto'>
          <GoogleBtn />
        </div>
        <div className='relative py-6'>
          <div className='absolute inset-0 flex items-center'>
            <div className='w-full border-t border-gray-300' />
          </div>
          <div className='relative flex justify-center text-sm'>
            <span className='px-2 text-gray-500 bg-white'>
              Or continue with
            </span>
          </div>
        </div>
        <form onSubmit={createUser} className='flex flex-col space-y-3'>
          <Input
            label='Name'
            type='text'
            id='name'
            onChange={e => setName(e.target.value)}
            value={name}
            data-cy='name'
          />

          <div>
            <Input
              label='Email Address'
              type='text'
              id='email'
              onChange={e => setEmail(e.target.value)}
              value={email}
              data-cy='email'
            />
            {email && !validate(email) && (
              <div className='mt-1 text-sm text-primary'>Not a valid email</div>
            )}
          </div>

          <Input
            label='Password'
            type='password'
            onChange={e => setPass(e.target.value)}
            value={pass}
            data-cy='password'
          />

          <div>
            <Input
              label='Confirm Password'
              type='password'
              onChange={e => setPass2(e.target.value)}
              value={pass2}
              data-cy='confirmPassword'
            />
            {pass && pass !== pass2 && (
              <div className='mt-1 text-sm text-primary'>
                Passwords do not match
              </div>
            )}
          </div>

          <button
            type='submit'
            className='w-full py-3 text-sm text-white rounded-md bg-primary md:text-base'
            disabled={
              !email ||
              !validate(email) ||
              !pass ||
              !pass2 ||
              !name ||
              pass !== pass2 ||
              state.inProg
            }
          >
            {!state.inProg ? (
              'Create Account'
            ) : (
              <Spinner className='!w-5 !h-5' />
            )}
          </button>
          <Link to='/login' className='mx-auto text-sm text-gray-700'>
            Sign in
          </Link>
          <StatusToast
            open={alert.open}
            status={alert.type}
            onClose={() => setAlert({ open: false, type: StatusType.SUCCESS })}
            dismiss={false}
          >
            {alert.message}
          </StatusToast>
        </form>
      </div>
    </div>
  );
}

gql`
  mutation newAccount($data: CreateAccountInput!) {
    createAccount(input: $data) {
      ... on CreateAccountPayload {
        profile {
          id
          slug
          name
          url
        }
      }
      ... on CreateAccountError {
        error
      }
    }
  }
`;
