import React from 'react';

export const Detail = ({ title, text, alt, width }) => {
  return (
    <div
      className='text-muted'
      style={{ fontSize: '.8rem', lineHeight: 1, width: width }}
    >
      <small className='text-center'>{title}</small>
      <div
        className='whitespace-nowrap'
        style={{ fontSize: '.9rem' }}
        title={alt || ''}
      >
        {text}
      </div>
    </div>
  );
};

export const Label = ({ text, children }) => (
  <div className='my-3'>
    <small className='text-gray'>{text}</small>
    <div className='-mt-1'>{children}</div>
  </div>
);

export const EditBtn = ({ text = 'Edit', className = '', ...other }) => (
  <button
    {...other}
    className={`${className} text-primary hover:underline p-0`}
  >
    {text}
  </button>
);
