import React, { useState } from 'react';
import { Transition } from '@headlessui/react';

interface IProgressiveImageProps extends React.ComponentPropsWithoutRef<'img'> {
  tiny: string;
  onError: React.ReactEventHandler<HTMLImageElement>;
}

export default ({ tiny, onError, ...imgProps }: IProgressiveImageProps) => {
  const [isLoaded, setLoaded] = useState(false);

  return (
    <div className='relative z-10 w-full overflow-hidden rounded-2xl'>
      <img
        className={`w-full rounded-2xl`}
        {...imgProps}
        onLoad={() => setLoaded(true)}
        onError={onError}
        alt=''
        loading='lazy'
      />
      <Transition
        show={!isLoaded}
        as={React.Fragment}
        // leave='transition-opacity duration-75'
        // leaveFrom='opacity-100'
        // leaveTo='opacity-50'
      >
        <img
          className={`w-full rounded-2xl absolute left-0 top-0 h-full filter blur-xs`}
          src={tiny}
          alt=''
          height={imgProps.height}
          width={imgProps.width}
        />
      </Transition>
    </div>
  );
};
