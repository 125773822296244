import { gql, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router';
import {
  useGenerateSignedUploadMutation,
  useGetInitialInfoQuery,
} from '../generated/graphql';
import { itemLayoutVar } from './apollo';
import mixpanel from 'mixpanel-browser';

gql`
  mutation generateSignedUpload {
    generateSignedUpload(input: { kind: IMAGE }) {
      signedUrl
      completionToken
      error {
        code
      }
    }
  }
`;

export function useImageUpload(image): Promise<string> {
  // const [image, setImage] = useState(null);
  const [promise, setPromise] = useState(Promise.resolve(''));
  const [generateUploadSession] = useGenerateSignedUploadMutation();

  useEffect(() => {
    if (image) {
      setPromise(Promise.resolve(''));

      let token: string = '';
      const promise = generateUploadSession()
        .then(result => {
          if (!result.data) {
            throw new Error('Mising mutation data');
          }

          const {
            data: { generateSignedUpload },
          } = result;
          return generateSignedUpload;
        })
        .then(res => {
          if (res?.completionToken && res?.signedUrl) {
            token = res.completionToken;

            return fetch(res.signedUrl, {
              method: 'PUT',
              headers: { 'Content-Type': '' },
              body: image,
            });
          }
        })
        .then(() => {
          return token;
        });
      setPromise(promise);
    }
  }, [image, generateUploadSession]);

  // return [setImage, promise];
  return promise;
}

export const useAuthentication = (): boolean => {
  const { data } = useGetInitialInfoQuery();

  return data?.viewer.isAuthenticated ?? false;
};

export const usePreference = (key, defaultValue) => {
  const [preference, setPreference] = useState(
    localStorage.getItem(key) || defaultValue
  );

  useEffect(() => {
    localStorage.setItem(key, preference);
  }, [key, preference]);

  return [preference, setPreference];
};

export const useDelay = delay => {
  const [done, setDone] = useState(delay === 0);

  const timeout = setTimeout(() => {
    setDone(true);
  }, delay);

  useEffect(() => {
    return () => {
      clearTimeout(timeout);
    };
  });

  return done;
};

export const useItemLayout = () => {
  const { data } = useQuery(
    gql`
      query getItemLayout {
        itemLayout @client
      }
    `
  );

  // useEffect(() => {
  //   // Set the Item Layout if passed in and not already set
  //     itemLayoutVar(layout);
  // }, [layout, data.itemLayout]);

  return [data.itemLayout, itemLayoutVar];
};

export const useScrollToTop = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
};

export const useScrollPosition = (key: string) => {
  const history = useHistory();

  useEffect(() => {
    if (history.action === 'POP') {
      const pageYOffset = sessionStorage.getItem(key);
      window.scrollTo(0, Number(pageYOffset));
    }

    return () => {
      sessionStorage.setItem(key, window.pageYOffset.toString());
    };
  }, [history.action, key]);
};

export function useQueryParams() {
  return new URLSearchParams(useLocation().search);
}

export const usePrefetch = (fetch: () => void) => {
  let timeout: NodeJS.Timeout;

  useEffect(() => {
    return () => {
      clearTimeout(timeout);
    };
  });

  const onFetchStart = () => {
    timeout = setTimeout(() => {
      fetch();
    }, 200);
  };

  const onFetchCancel = () => {
    clearTimeout(timeout);
  };

  return [onFetchStart, onFetchCancel];
};

export const usePageView = (page: string) => {
  useEffect(() => {
    mixpanel.track('Page View', { page });
    /* eslint-disable */
  }, []);
};
