import React, { Dispatch, SetStateAction, useState } from 'react';
import Input from '../../components/Input';
import { Alert } from './index';
import { StatusType } from '../../components/Toasts/Status';
import { useChangePasswordMutation } from '../../generated/graphql';
import gql from 'graphql-tag';
import { Button } from 'src/components/Button';

interface IPasswordProps {
  setPage: Dispatch<SetStateAction<string>>;
  setAlert: Dispatch<SetStateAction<Alert>>;
}

export default ({ setPage, setAlert }: IPasswordProps) => {
  const [oldPass, setOldPass] = useState('');
  const [newPass, setNewPass] = useState('');
  const [confirmPass, setConfPass] = useState('');

  const [changePassword, { loading }] = useChangePasswordMutation({
    onCompleted: ({ changePassword }) => {
      if (changePassword?.error) {
        setAlert({
          open: true,
          type: StatusType.DANGER,
          message: 'There was an unexpected error changing your password.',
        });
      } else {
        setAlert({
          open: true,
          type: StatusType.SUCCESS,
          message: 'Password successfully updated',
        });
      }

      setPage('settings');
    },
    onError: () => {
      setAlert({
        open: true,
        type: StatusType.DANGER,
        message: 'There was an unexpected error changing your password.',
      });
    },
  });

  const onSubmit = e => {
    e.preventDefault();

    changePassword({
      variables: {
        input: {
          current: oldPass,
          target: newPass,
        },
      },
    });
  };

  const isSaveDisabled = () =>
    !oldPass || !newPass || !confirmPass || newPass !== confirmPass || loading;

  return (
    <form onSubmit={onSubmit} className='max-w-md space-y-2'>
      <h3 className='font-bold'>Change Password</h3>
      <input className='hidden' autoComplete='username' />
      <Input
        label='Current Password'
        type='password'
        value={oldPass}
        onChange={e => setOldPass(e.target.value)}
        autoComplete='current-password'
        data-cy='currentPassword'
      />
      <Input
        label='New Password'
        type='password'
        value={newPass}
        onChange={e => setNewPass(e.target.value)}
        autoComplete='new-password'
        data-cy='newPassword'
      />
      <Input
        label='Confirm New Password'
        type='password'
        value={confirmPass}
        onChange={e => setConfPass(e.target.value)}
        autoComplete='new-password'
        data-cy='confirmPassword'
        error={
          newPass && confirmPass && newPass !== confirmPass
            ? 'Passwords do not match'
            : ''
        }
      />

      <div className='space-x-2'>
        <Button
          className='w-20 py-2 text-white rounded-md bg-primary'
          disabled={isSaveDisabled()}
          loading={loading}
          type='submit'
        >
          Save
        </Button>
        <button
          type='button'
          className='text-gray-700'
          onClick={() => setPage('settings')}
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

gql`
  mutation changePassword($input: ChangePasswordInput!) {
    changePassword(input: $input) {
      user {
        id
      }
      error {
        code
        message
      }
    }
  }
`;
