import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Icon } from '../icons/Giftory_Icon.svg';

export default function NotFound() {
  return (
    <div className='min-h-full pt-16 pb-12 flex flex-col bg-white'>
      <main className='flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8'>
        <div className='flex-shrink-0 flex justify-center'>
          <Link to='/' className='inline-flex'>
            <span className='sr-only'>Workflow</span>
            <Icon className='w-20 h-20 fill-current text-primary' />
          </Link>
        </div>
        <div className='py-16'>
          <div className='text-center'>
            <p className='text-sm font-semibold text-indigo-600 uppercase tracking-wide'>
              404 error
            </p>
            <h1 className='mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl'>
              Page not found.
            </h1>
            <p className='mt-2 text-base text-gray-500'>
              Sorry, we couldn’t find the page you’re looking for.
            </p>
            <div className='mt-6'>
              <Link
                to='/'
                className='text-base font-medium text-blue-600 hover:text-blue-500'
                alt=''
              >
                Go back home<span aria-hidden='true'> &rarr;</span>
              </Link>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
