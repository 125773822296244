import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import { usePageView } from 'src/lib/hooks';
import Slug from './slug';
import Uncollected from './uncollected';

export default () => {
  usePageView('Collection');
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/uncollected`} component={Uncollected} />
      <Route path={`${path}/:collectionSlug`} component={Slug} />
    </Switch>
  );
};
