import fetch from 'node-fetch';
// import ApolloClient from "apollo-client";
// import { createHttpLink } from "apollo-link-http";
import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  makeVar,
} from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';
import { relayStylePaginationWithFields } from './utils';

const uri = `${document.location.origin}/graphql`;
//process.env.REACT_APP_ENVIRONMENT === "development" ? 'http://localhost:8888/graphql' : "https://unnamedwishlistapp.herokuapp.com/graphql"

const link = createHttpLink({
  uri,
  credentials: 'same-origin',
  fetch,
});

const client = new ApolloClient({
  link,
  cache: new InMemoryCache({
    dataIdFromObject: o => o.id,
    possibleTypes: {
      HasListItems: [
        'Profile',
        'TailoredFeed',
        'GenericFeed',
        'ProfileUncollection',
        'ListItemCollection',
        'ProfileArchived',
      ],
    },
    typePolicies: {
      Query: {
        fields: {
          viewer: {
            merge: true,
          },
          feed: {
            merge: true,
          },
          profile: {
            read: (_, { args, toReference }) => {
              return toReference({
                __typename: 'Profile',
                slug: args.input.slug,
              });
            },
          },
          item: {
            read: (_, { args, toReference }) => {
              return toReference({
                __typename: 'ListItem',
                slug: args.input.item,
              });
            },
          },
          collection: {
            read: (_, { args, toReference }) => {
              return toReference({
                __typename: 'ListItemCollection',
                slug: args.input.collectionSlug,
              });
            },
          },
          itemLayout: {
            read() {
              return itemLayoutVar();
            },
          },
          itemSort: {
            read() {
              return itemSortVar();
            },
          },
        },
      },
      Feed: {
        fields: {
          viewer: relayStylePagination(),
          explore: relayStylePaginationWithFields(),
        },
      },
      Viewer: {
        fields: {
          profile: {
            merge: true,
          },
          user: {
            merge: true,
          },
        },
      },
      Profile: {
        keyFields: ['slug'],
        fields: {
          listItems: relayStylePaginationWithFields(),
          uncollectedListItems: relayStylePaginationWithFields(),
          uncollected: {
            merge: true,
          },
          followers: {
            merge: true,
          },
        },
      },
      HasListItems: {
        fields: {
          listItems: relayStylePaginationWithFields(),
        },
      },
      ListItemCollection: {
        keyFields: ['slug'],
        fields: {
          listItems: relayStylePaginationWithFields(),
          profile: {
            read: (_, { variables, toReference }) => {
              return toReference({
                __typename: 'Profile',
                slug: variables.input.profileSlug,
              });
            },
          },
        },
      },
      User: {
        fields: {
          profile: {
            merge: true,
          },
          following: {
            merge: true,
          },
          proxy_profiles: {
            merge: true,
          },
        },
      },
      ListItem: {
        keyFields: ['slug'],
        fields: {
          image: {
            merge: true,
          },
          _deleted: {
            // Field policy for the isInCart field
            read(deleted = false) {
              // The read function for the isInCart field
              return deleted; // I'm not sure this actually does anything
            },
          },
        },
      },
    },
  }),
});

const ITEM_SORT_KEY = 'item-sort';

const layoutInitialValue = localStorage.getItem('ITEM_VIEW') || 'masonry';
console.log('Initial layout value', layoutInitialValue);
export const itemLayoutVar = makeVar(layoutInitialValue);

const sortInitialValue = localStorage.getItem(ITEM_SORT_KEY) || 'name-desc';
export const itemSortVar = makeVar(sortInitialValue);

export default client;
