import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { gql } from '@apollo/client';
import {
  ProfileImageFragment,
  ProfileThumbnailFragment,
  useGetProfileLazyQuery,
} from '../../generated/graphql';
import { usePrefetch } from '../../lib/hooks';

function hashCode(str = '') {
  return str
    .split('')
    .reduce(
      (prevHash, currVal) =>
        ((prevHash << 5) - prevHash + currVal.charCodeAt(0)) | 0,
      0
    );
}

const colors = [
  '#43BCCD',
  '#95BAD8',
  '#A2DA99',
  '#CDADAD',
  '#E6A082',
  '#D895B9',
  '#BC94DB',
  '#84CDA6',
];
export interface ProfileImageProps {
  profile: ProfileImageFragment;
  diameter?: number;
}

const ProfileImage = ({ profile, diameter = 20 }: ProfileImageProps) => {
  const { name, image } = profile;

  const index = useMemo(() => Math.abs(hashCode(name || '')) % colors.length, [
    name,
  ]);
  const color = colors[index];
  const background = image
    ? `url(${image.fixed}) center/cover no-repeat`
    : color;

  return (
    <div
      className='flex items-center justify-center rounded-full'
      style={{
        height: diameter,
        width: diameter,
        minWidth: diameter,
        background: background,
      }}
    >
      {!image && (
        <div
          className='text-white select-none text-capitalize'
          style={{ fontSize: diameter / 2 }}
        >
          {name ? name[0] : ''}
        </div>
      )}
    </div>
  );
};

ProfileImage.fragments = {
  profile: gql`
    fragment ProfileImage on Profile {
      ... on Profile {
        name
        image {
          fixed(input: { ratio: 1, width: 125 })
        }
      }
    }
  `,
};

interface IProfileThumbnailProps extends ProfileImageProps {
  profile: ProfileThumbnailFragment;
  diameter: number;
}

const ProfileThumbnail = ({ profile, diameter }: IProfileThumbnailProps) => {
  const { name, slug } = profile;

  // FIXME: #20 Profile name is getting cut off when too long
  return (
    <Link
      to={`/wishlist/${slug}`}
      className='text-center text-gray-700 text-decoration-none'
    >
      <div className='flex items-center justify-center duration-300 transform hover:-rotate-12'>
        <ProfileImage profile={profile} diameter={diameter} />
      </div>
      <div className='line-clamp-2 leading-4 text-xs mt-1.5'>{name}</div>
    </Link>
  );
};

const profileThumbnail = gql`
  fragment ProfileThumbnail on Profile {
    name
    slug
    ...ProfileImage
  }
  ${ProfileImage.fragments.profile}
`;

ProfileThumbnail.fragments = {
  profile: profileThumbnail,
};

export interface ProfileLinkProps extends ProfileImageProps {
  profile: ProfileThumbnailFragment;
  className?: string;
  diameter?: number;
}

const ProfileLink = ({
  profile = { name: '', slug: '', image: null },
  diameter = 20,
  className = '',
}: ProfileLinkProps) => {
  const { name, slug } = profile;
  const [getProfile] = useGetProfileLazyQuery();
  const [onFetchProfile, onFetchCancel] = usePrefetch(() => {
    getProfile({
      variables: { input: { slug: profile.slug } },
    });
  });

  return (
    <Link
      to={`/wishlist/${slug}`}
      className='inline-flex items-center text-black no-underline group'
      onMouseOver={onFetchProfile}
      onMouseLeave={onFetchCancel}
      onTouchStart={onFetchProfile}
      onTouchCancel={onFetchCancel}
    >
      <div className='flex justify-center mr-1'>
        <ProfileImage profile={profile} diameter={diameter} />
      </div>
      <span
        className={`text-gray group-hover:underline text-sm font-light whitespace-nowrap ${className}`}
      >
        {name}
      </span>
    </Link>
  );
};

ProfileLink.fragments = {
  profile: profileThumbnail,
};

export { ProfileLink, ProfileThumbnail, ProfileImage };
