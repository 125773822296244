import React, { useState } from 'react';
import { ProfileImage } from '../../components/Profile/Link';
import { arrayBufferToBase64 } from '../../lib/utils';
import { useImageUpload } from '../../lib/hooks';
import { Spinner } from '../../components/Loading';
import cloneDeep from 'lodash/cloneDeep';
import { ISettingsProps } from '.';
import { StatusType } from '../../components/Toasts/Status';
import { useUpdateProfileMutation } from '../../generated/graphql';
import { Button } from 'src/components/Button';

// const useParseImage = (file) => {
//   const [imageData, setImageData] = useState({ data: data.viewer.user.profile.image?.fixed, buffer: null });

//   useEffect(() => {
//     const reader = new FileReader();

//     reader.readAsArrayBuffer(file);
//     // reader.readAsDataURL(blob);
//     reader.onloadend = function() {
//       var res = reader.result;
//       setImageData({ data: `data:image/png;base64,${arrayBufferToBase64(res)}`, buffer: res })
//     }
//   }, [file])
// }

export default ({ setPage, setAlert, profile, setProfile }: ISettingsProps) => {
  const [imageData, setImageData] = useState<any>({
    data: profile.image?.fixed,
    buffer: null,
  });
  const imageUploadPromise = useImageUpload(imageData.buffer);

  const [updateProfile, { loading }] = useUpdateProfileMutation({
    onCompleted: data => {
      setAlert({
        open: true,
        type: StatusType.SUCCESS,
        message: 'Profile image successfully updated',
      });
      setPage('settings');
      setProfile(data.updateProfile?.profile);
    },
    onError: () => {
      setAlert({
        open: true,
        type: StatusType.DANGER,
        message: 'There was an unexpected error changing your profile image.',
      });
    },
  });

  const readImage = blob => {
    const reader = new FileReader();

    reader.readAsArrayBuffer(blob);
    // reader.readAsDataURL(blob);
    reader.onloadend = function() {
      var res = reader.result;
      setImageData({
        data: `data:image/png;base64,${arrayBufferToBase64(res)}`,
        buffer: res,
      });
    };
  };

  const onUpload = e => {
    const file = e.target.files[0];
    readImage(file);
  };

  const onSubmit = e => {
    e.preventDefault();

    imageUploadPromise.then(imageToken => {
      updateProfile({
        variables: {
          input: {
            profileId: profile.id,
            imageToken,
          },
        },
      });
    });
  };

  const isSaveDisabled = () => loading;
  let prof = cloneDeep(profile);
  if (imageData.data) {
    prof.image = {
      fixed: imageData.data,
    };
  }

  return (
    <form onSubmit={onSubmit} className='space-y-2'>
      <h3 className='font-bold'>Change Profile Image</h3>
      <div className='flex items-center'>
        <ProfileImage profile={prof} diameter={125} />
        <label className='px-4 font-bold cursor-pointer text-primary'>
          <input type='file' accept='image/*' onChange={onUpload} />
          <i className='fas fa-upload mr-1'></i> Upload Image
        </label>
      </div>
      <div className='space-x-2'>
        <Button
          className='py-2 text-white rounded-md bg-primary w-20'
          disabled={isSaveDisabled()}
          loading={loading}
          type='submit'
        >
          Save
        </Button>
        <button
          type='button'
          className='text-gray-700'
          onClick={() => setPage('settings')}
        >
          Cancel
        </button>
      </div>
    </form>
  );
};
