import React from 'react';
interface InputProps extends React.ComponentPropsWithoutRef<'input'> {
  label?: string;
  error?: string;
  icon?: any;
}

export default ({
  className = '',
  label,
  type = 'text',
  error,
  icon,
  ...other
}: InputProps) => (
  <div>
    {label && (
      <label className='text-sm font-medium text-gray-700'>{label}</label>
    )}

    <div className='relative'>
      <input
        className={`${className} block rounded-md w-full border-gray-light focus:border-gray-light focus:ring-primary/20 shadow-sm focus:ring-4 text-sm ${
          icon ? 'pr-10' : ''
        }`}
        type={type}
        {...other}
      />
      <div className='absolute inset-y-0 right-0 flex items-center pr-1'>
        {icon}
      </div>
    </div>

    {error && <p className='text-sm text-primary'>{error}</p>}
  </div>
);
