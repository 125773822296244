import React from 'react';
import { ReactComponent as ErrorLogo } from '../icons/ErrorLogo.svg';

const ErrBoundary = () => (
  <div className='flex flex-col place-items-center mt-30 px-2'>
    <ErrorLogo />
    <div className='text-red-500 text-sm font-semibold mt-16'>PAGE ERROR</div>
    <div className='text-2xl md:text-5xl font-bold text-gray-900'>
      Something went wrong
    </div>
    <div className='mt-1 mb-3 text-base text-gray-500 max-w-md text-center'>
      Uh oh. Not sure what happened, try refreshing the page!
    </div>
  </div>
);

export default ErrBoundary;
