import React from 'react';
import Following from '../../components/Following';

import { useMediaQuery } from 'react-responsive';
import { ProfileImage } from '../../components/Profile/Link';
import Markdown from 'react-remarkable';

export default function ListHeader({ data }) {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  if (!data) {
    return null;
  }

  const { profile = {}, viewer } = data;
  const { id, viewerCanEditList, viewerIsFollowing, name } = profile;
  const isChildAccount = viewerCanEditList && viewer.profile.id !== id;

  return (
    <div className='mb-3'>
      <div className='flex flex-col items-center justify-between'>
        <div className='flex flex-col items-center truncate'>
          <div className='py-3'>
            <ProfileImage profile={profile} diameter={isMobile ? 96 : 129} />
          </div>
          <div className='pb-3'>
            <h4 className='inline m-0 text-xl font-bold truncate'>
              {viewerCanEditList && !isChildAccount ? 'My Wishlist' : name}
            </h4>
          </div>
        </div>
        {viewer.isAuthenticated && (
          <Following
            profile={profile}
            following={viewerIsFollowing}
            className='w-40 py-1.5 my-3 rounded-md md:w-full'
          />
        )}
      </div>
      {data?.profile?.bio && (
        <div className='max-w-md pt-3 pb-1 mx-auto text-sm prose-sm text-center text-justify'>
          <Markdown source={data?.profile?.bio} />
        </div>
      )}
    </div>
  );
}
