import React, { useState } from 'react';
import ProfileList from '../ProfileList';
import { gql } from '@apollo/client';
import PlusIcon from '@heroicons/react/solid/PlusIcon';
import Input from '../Input';
import {
  useCreateProfileMutation,
  useGetProxyProfilesQuery,
} from '../../generated/graphql';

gql`
  mutation createProfile($input: CreateProfileInput!) {
    createProfile(input: $input) {
      profile {
        ...ProfileData
      }
    }
  }
  ${ProfileList.fragments.profile}
`;

gql`
  query getProxyProfiles {
    viewer {
      user {
        proxy_profiles {
          edges {
            node {
              ...ProfileData
            }
          }
        }
      }
    }
  }
  ${ProfileList.fragments.profile}
`;

const AddChild = () => {
  const [addChild, setChild] = useState(false);
  const [name, setName] = useState('');
  const [createChild] = useCreateProfileMutation();

  const create = e => {
    e.preventDefault();
    setChild(false);
    setName('');
    createChild({
      variables: { input: { name: name } },
      refetchQueries: ['getProxyProfiles'],
    });
  };

  const AddButton = () => (
    <button
      onClick={() => setChild(true)}
      className='px-4 py-2 text-sm border rounded-md flex items-center'
    >
      <PlusIcon className='w-4 h-4 mr-2' />
      <span>New Child Profile</span>
    </button>
  );

  const Form = (
    <form onSubmit={create} className='max-w-md space-y-3'>
      <Input
        id='name'
        onChange={e => setName(e.target.value)}
        value={name}
        label='Name'
      />
      <div className='flex'>
        <button
          className='text-sm text-gray'
          onClick={() => setChild(false)}
          type='button'
        >
          Cancel
        </button>
        <button
          type='submit'
          className='inline-flex items-center px-4 py-2 ml-5 text-white rounded-md bg-primary'
        >
          <PlusIcon className='w-5 h-5' />
          <span>New Child</span>
        </button>
      </div>
    </form>
  );

  return !addChild ? <AddButton /> : Form;
};

export default function Child() {
  const { data, loading, error } = useGetProxyProfilesQuery();

  if (loading) {
    return null;
  }

  if (error) {
    console.error(error);
    return <div>There was an error fetching the data</div>;
  }

  const profiles = data?.viewer.user?.proxy_profiles?.edges ?? [];

  // @ts-ignore
  let elem = <ProfileList profiles={profiles} />;
  if (!profiles.length) {
    elem = <div>You have not added any child lists</div>;
  }

  return (
    <>
      <AddChild />
      {elem}
    </>
  );
}
