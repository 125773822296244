import { gql } from '@apollo/client';
import { validate } from 'email-validator';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useForgotPasswordMutation } from 'src/generated/graphql';
import { Alert } from 'src/types/lib';
import Input from '../Input';
import { Spinner } from '../Loading';
import { StatusToast, StatusType } from '../Toasts/Status';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const query = useQuery();
  const error = query.get('error') ?? '';
  const [alert, setAlert] = useState<Alert>({
    open: !!error,
    type: error ? StatusType.DANGER : StatusType.SUCCESS,
    message: '',
  });

  const [forgotPassword, { loading }] = useForgotPasswordMutation({
    onCompleted: () => {
      setAlert({
        open: true,
        type: StatusType.SUCCESS,
        message:
          'If an account matches the provided email, you will receive an email with your temporary password',
      });
      setEmail('');
    },
    onError: () => {
      setAlert({
        open: true,
        type: StatusType.DANGER,
        message: 'There was an unexpected error reseting your password.',
      });
    },
  });

  const onSubmit = e => {
    e.preventDefault();
    forgotPassword({
      variables: {
        input: {
          email: email,
        },
      },
    });
  };

  return (
    <>
      <div className='flex justify-center px-3 md:px-0'>
        <div className='flex flex-col w-full max-w-md'>
          <form onSubmit={onSubmit} className='flex flex-col space-y-4'>
            <div className='text-base font-medium md:text-lg'>
              Forgot Password
            </div>
            <Input
              name='email'
              type='text'
              id='email'
              onChange={e => setEmail(e.target.value)}
              value={email}
              label='Email address'
              autoComplete='email'
              error={
                email && !validate(email) ? 'Not a valid email address' : ''
              }
            />
            <button
              type='submit'
              className='w-full py-3 mt-3 text-sm text-white rounded-md bg-primary md:text-base disabled:cursor-not-allowed'
              disabled={!email || !validate(email)}
            >
              {!loading ? 'Reset Password' : <Spinner className='w-5 h-5' />}
            </button>
            <Link to='/login' className='mx-auto text-sm text-gray-700'>
              Cancel
            </Link>
          </form>
        </div>
      </div>
      <StatusToast
        open={alert.open}
        status={alert.type}
        onClose={() => setAlert({ open: false, type: StatusType.SUCCESS })}
        dismiss={false}
      >
        {alert.message}
      </StatusToast>
    </>
  );
};

export { ForgotPassword };

gql`
  mutation forgotPassword($input: ForgotPasswordInput!) {
    forgotPassword(input: $input) {
      error {
        code
        message
      }
    }
  }
`;
