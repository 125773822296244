import React from 'react';
import { Link } from 'react-router-dom';

interface IPillTabProps {
  isActive: boolean;
  as: React.ElementType<any>;
  children: React.ReactNode;
  show?: boolean;
  props: React.ComponentPropsWithoutRef<any>;
}

export const PillTab = ({
  isActive,
  as: Component,
  children,
  show = true,
  props,
}: IPillTabProps) => {
  const cls = isActive ? `bg-primary bg-opacity-10` : 'text-gray';

  if (!show) {
    return null;
  }

  return (
    <li className={`inline-block whitespace-nowrap`}>
      <Component
        {...props}
        className={`rounded-full p-3 text-center block ${cls} ${props.className}`}
      >
        {children}
      </Component>
    </li>
  );
};

interface TabProps {
  isActive: boolean;
  children: React.ReactNode;
  className?: string;
}

export const Tab = ({ isActive, children, className }: TabProps) => {
  const cls = isActive
    ? `text-primary bg-primary bg-opacity-10 rounded-md `
    : 'text-gray';

  return (
    <div className={`py-1.5 px-3 text-center font-medium ${cls} ${className}`}>
      {children}
    </div>
  );
};

const TabBase = ({ to, children, className, href }) => {
  const props = {
    className: `rounded-full p-2 px-4 text-center ${className}`,
    to,
    href,
  };

  return (
    <li className='inline whitespace-nowrap'>
      {href ? <a {...props}>{children}</a> : <Link {...props}>{children}</Link>}
    </li>
  );
};

export default TabBase;
