import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { validate } from 'email-validator';
import ReactGa from 'react-ga';
import { Spinner } from '../Loading';
import Input from '../Input';
import GoogleBtn from '../GoogleBtn';
import { StatusToast, StatusType } from '../Toasts/Status';
import { Alert } from '../../types/lib';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const errMsg = {
  INACTIVE: 'Please confirm your email before you sign in.',
  PASSWORD: 'Either your email or password is incorrect.',
  UNKNOWN: 'Either your email or password is incorrect.',
};

export const Login = () => {
  const query = useQuery();
  const error = query.get('error') ?? '';
  const [email, setEmail] = useState('');
  const [pass, setPass] = useState('');
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState<Alert>({
    open: !!error,
    type: error ? StatusType.DANGER : StatusType.SUCCESS,
    message: errMsg[error] || errMsg.UNKNOWN,
  });
  const referrer = query.get('r');

  const onLogin = () => {
    setLoading(true);
    ReactGa.event({
      category: 'User',
      action: 'Logged In',
    });
  };

  const Toast = () => {
    return (
      <StatusToast
        open={alert.open}
        status={alert.type}
        onClose={() => setAlert({ open: false, type: StatusType.SUCCESS })}
        dismiss={false}
      >
        {alert.message}
      </StatusToast>
    );
  };

  return (
    <div className='flex justify-center px-3 md:px-0'>
      <div className='flex flex-col w-full max-w-md'>
        <div className='pb-2 text-base font-medium md:text-lg'>
          Sign in with
        </div>
        <div className='md:w-72 md:mx-auto'>
          <GoogleBtn />
        </div>
        <div className='relative py-6'>
          <div className='absolute inset-0 flex items-center'>
            <div className='w-full border-t border-gray-300' />
          </div>
          <div className='relative flex justify-center text-sm'>
            <span className='px-2 text-gray-500 bg-white'>
              Or continue with
            </span>
          </div>
        </div>
        <Toast />
        <form
          action={
            !referrer ? '/login' : `/login?r=${encodeURIComponent(referrer)}`
          }
          method='post'
          className='flex flex-col space-y-3'
        >
          <Input
            name='email'
            type='text'
            id='email'
            onChange={e => setEmail(e.target.value)}
            value={email}
            label='Email address'
            autoComplete='username'
          />
          <Input
            name='password'
            type='password'
            id='password'
            label='Password'
            onChange={e => setPass(e.target.value)}
            value={pass}
            autoComplete='current-password'
          />
          <Link
            to='/forgotpassword'
            className='py-3 ml-auto text-sm hover:underline'
          >
            Forgot your password?
          </Link>

          <button
            type='submit'
            className='w-full py-3 text-sm text-white rounded-md bg-primary md:text-base'
            onClick={onLogin}
            disabled={!email || !pass || !validate(email)}
          >
            {!loading ? 'Sign In' : <Spinner className='!w-5 !h-5' />}
          </button>
          <Link to='/signup' className='mx-auto text-sm text-gray-700'>
            Create Account
          </Link>
        </form>
      </div>
    </div>
  );
};
