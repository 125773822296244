import { Listbox } from '@headlessui/react';
import ChevronDownIcon from '@heroicons/react/solid/ChevronDownIcon';
import React, { Dispatch, SetStateAction } from 'react';
import { ProfileImage } from './Profile/Link';
import { gql } from '@apollo/client';
import { ProfileOptionFragment } from '../generated/graphql';

interface ProfileInputProps {
  value: ProfileOptionFragment;
  onChange: Dispatch<SetStateAction<any>>;
  options: ProfileOptionFragment[];
  label: string;
}

export const ProfileInput = ({
  value,
  onChange,
  options,
  label,
}: ProfileInputProps) => {
  return (
    <div className='relative mb-6'>
      <div className='text-sm font-medium text-gray-700'>{label}</div>
      <Listbox value={value} onChange={onChange}>
        <Listbox.Button className='relative flex items-center w-full py-1 pl-3 pr-10 text-left bg-white border border-gray-200 rounded-lg cursor-pointer focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-primary focus-visible:ring-offset-2 focus-visible:border-primary'>
          <ProfileImage profile={value} diameter={30} />
          <span className='ml-3 text-sm'>{value.name}</span>
          <span className='absolute inset-y-0 right-0 flex items-center pl-4 pr-4 border-l-2 pointer-events-none'>
            <ChevronDownIcon
              className='w-6 h-6 text-gray-400'
              aria-hidden='true'
            />
          </span>
        </Listbox.Button>
        <Listbox.Options className='absolute z-20 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
          {options.map(profile => (
            <Listbox.Option
              key={profile.id}
              value={profile}
              className={`flex items-center px-4 py-2 text-sm text-gray-900 cursor-pointer hover:bg-primary hover:bg-opacity-20 ${
                profile === value ? 'bg-gray-100' : ''
              }`}
            >
              <ProfileImage profile={profile} diameter={30} />
              <span className='ml-3'>{profile.name}</span>
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Listbox>
    </div>
  );
};

ProfileInput.fragments = {
  profile: gql`
    fragment ProfileOption on Profile {
      id
      name
      slug
      ...ProfileImage
    }
  `,
};

gql`
  query getUserProfilesInput {
    viewer {
      user {
        profile {
          id
          name
          slug
          ...ProfileImage
        }
        proxy_profiles {
          edges {
            node {
              id
              name
              slug
              ...ProfileImage
            }
          }
        }
      }
    }
  }
  ${ProfileImage.fragments.profile}
`;
