import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { OutboundLink } from 'react-ga';
import ProgressiveImage from '../ProgressiveImage';
import gql from 'graphql-tag';
import { ItemDataFragment, ItemImageFragment } from '../../generated/graphql';
import UploadIcon from '@heroicons/react/solid/UploadIcon';

function arrayBufferToBase64(buffer) {
  var binary = '';
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return btoa(binary);
}

function importAll(r) {
  return r.keys().map(r);
}

function hashCode(str = '') {
  return str
    .split('')
    .reduce(
      (prevHash, currVal) =>
        ((prevHash << 5) - prevHash + currVal.charCodeAt(0)) | 0,
      0
    );
}

const images = importAll(
  require.context(
    '../../icons/item_placeholders/600',
    false,
    /\.(png|jpe?g|svg)$/
  )
);
const images10 = importAll(
  require.context(
    '../../icons/item_placeholders/004',
    false,
    /\.(png|jpe?g|svg)$/
  )
);
const images150 = importAll(
  require.context(
    '../../icons/item_placeholders/150',
    true,
    /\.(png|jpe?g|svg)$/
  )
);
const images250 = importAll(
  require.context(
    '../../icons/item_placeholders/250',
    false,
    /\.(png|jpe?g|svg)$/
  )
);

const imageSizes = [
  {
    height: 822,
    width: 600,
  },
  {
    height: 600,
    width: 600,
  },
  {
    height: 412,
    width: 600,
  },
  {
    height: 822,
    width: 600,
  },
  {
    height: 600,
    width: 600,
  },
  {
    height: 412,
    width: 600,
  },
];

const useBackupImage = (id: string): ItemImageFragment => {
  const index = useMemo(() => Math.abs(hashCode(id)) % images.length, [id]);
  return useMemo(
    () => ({
      tiny: encodeURI(images150[index].default),
      small: encodeURI(images150[index].default),
      large: encodeURI(images250[index].default),
      height: imageSizes[index].height,
      width: imageSizes[index].width,
    }),
    [index]
  );
};

interface IDefaultImageProps {
  str: string;
  className?: string;
}

export const DefaultImage = ({ str, className }: IDefaultImageProps) => {
  const backupImage = useBackupImage(str);

  return (
    <img
      className={className}
      alt={str}
      srcSet={`
        ${backupImage.small} 150w,
        ${backupImage.large} 250w
      `}
      sizes={`((max-width:600px)) 150px, 250px`}
    />
  );
};

interface IImageProps {
  image: ItemImageFragment | null;
  item: Pick<ItemDataFragment, 'id'>;
  fixed?: boolean;
  className?: string;
}

export const Image = ({ className, image, item, fixed }: IImageProps) => {
  const backupImage = useBackupImage(item.id);
  const [img, setImage] = useState<ItemImageFragment>(image || backupImage);

  let props: any = {
    src: img?.small,
  };

  if (fixed && img.fixed) {
    props = {
      src: img.fixed,
    };
  } else if (img?.small && img?.large) {
    props = {
      srcSet: `
        ${img?.small} 150w,
        ${img?.large} 250w
      `,
      sizes: `((max-width:600px)) 150px, 250px`,
    };
  }

  return (
    <img
      className={className}
      {...props}
      alt=''
      onError={() => {
        setImage(backupImage);
      }}
    />
  );
};

Image.fragments = {
  image: gql`
    fragment ItemImage on Image {
      tiny: pixelated
      small: max(input: { width: 150 })
      large: max(input: { width: 250 })
      fixed(input: { ratio: 1, width: 40 })
      height
      width
    }
  `,
};

interface IThumbnailImageProps {
  to: string;
  image: ItemImageFragment | null;
  item: Pick<ItemDataFragment, 'id'>;
  className?: string;
}

export const ThumbnailImage = ({
  image,
  to,
  item,
  className,
}: IThumbnailImageProps) => {
  const index = useMemo(() => Math.abs(hashCode(item.id)) % images.length, [
    item.id,
  ]);
  const backupImage = useBackupImage(item.id);
  const [img, setImage] = useState<ItemImageFragment>(image || backupImage);

  let props = {};
  if (img?.small && img?.large) {
    props = {
      srcSet: `
        ${img?.small} 150w,
        ${img?.large} 250w
      `,
      sizes: `((max-width:600px)) 150px, 250px`,
      src: img.large,
      height: img.height || imageSizes[index].height,
      width: img.width || imageSizes[index].width,
    };
  }

  return (
    <Link to={to}>
      <ProgressiveImage
        {...props}
        tiny={img?.tiny || img?.small || ''}
        onError={() => setImage(backupImage)}
        alt=''
        className={className}
      />
    </Link>
  );
};

ThumbnailImage.fragments = {
  image: Image.fragments.image,
};

const ImageBase = ({
  image,
  item,
  to,
  uri,
  isEditable,
  className,
  setImageData,
}) => {
  const index = useMemo(() => Math.abs(hashCode(item.id)) % images.length, [
    item.id,
  ]);
  const backupImage = useMemo(
    () => ({
      tiny: encodeURI(images10[index].default),
      source: encodeURI(images[index].default),
    }),
    [index]
  );

  const [img, setImage] = useState(image?.source ? image : backupImage);

  const dimensions = {
    height: img.height || imageSizes[index].height,
    width: img.width || imageSizes[index].width,
  };

  useEffect(() => {
    setImage(image || backupImage);
  }, [image, backupImage]);

  const readImage = blob => {
    const reader = new FileReader();

    reader.readAsArrayBuffer(blob);
    // reader.readAsDataURL(blob);
    reader.onloadend = function() {
      var res = reader.result;
      setImageData({
        data: `data:image/png;base64,${arrayBufferToBase64(res)}`,
        buffer: res,
      });
      // setImage({ source: `data:image/png;base64,${arrayBufferToBase64(res)}`, buffer: res })

      // setImageUrl(null)
    };
  };

  const onUpload = e => {
    const file = e.target.files[0];
    readImage(file);
  };

  const child = (
    <div className={`${className} relative`}>
      <ProgressiveImage
        src={img?.source}
        tiny={img?.tiny}
        onError={() => setImage(backupImage)}
        alt={item.title}
        {...dimensions}
      />
      {isEditable && (
        <label className='absolute z-20 px-4 py-2 text-white rounded-lg cursor-pointer bg-primary top-3 right-3 flex'>
          <input type='file' accept='image/*' onChange={onUpload} />
          <UploadIcon className='w-5 h-5' />
          <span className='text-sm ml-1'>Upload Image</span>
        </label>
      )}
    </div>
  );

  if (to) {
    return <Link to={to}>{child}</Link>;
  } else if (uri) {
    return (
      <OutboundLink eventLabel='Outbound Click' to={uri} target='_blank'>
        {child}
      </OutboundLink>
    );
  } else {
    return child;
  }
};

ImageBase.fragments = {
  image: gql`
    fragment ItemSourceImage on Image {
      tiny: pixelated
      source: max(input: { width: 700 })
      height
      width
    }
  `,
};

export default ImageBase;
