import { gql } from '@apollo/client';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  useGetProfileCollectionSettingsQuery,
  useUpdateCollectionMutation,
} from '../../generated/graphql';
import { Button } from '../Button';
import Input from '../Input';
import { Spinner } from '../Loading';

interface IEditNameProps {
  setPage: Dispatch<SetStateAction<string>>;
}

export default ({ setPage }: IEditNameProps) => {
  const history = useHistory();
  const { slug, collectionSlug } = useParams();
  const { data } = useGetProfileCollectionSettingsQuery({
    fetchPolicy: 'cache-only',
    variables: {
      input: {
        profileSlug: slug,
        collectionSlug,
      },
    },
  });
  const [name, setName] = useState(data?.collection?.title ?? '');
  const [editCollection, { loading }] = useUpdateCollectionMutation({
    onCompleted: res => {
      const newSlug = res.updateListItemCollection?.collection.slug;
      history.replace(`/wishlist/${slug}/collection/${newSlug}`);
      setPage('settings');
    },
  });

  const onSave = e => {
    e.preventDefault();

    editCollection({
      variables: {
        input: {
          collectionId: data?.collection?.id ?? '',
          title: name,
        },
      },
    });
  };

  return (
    <div className='container'>
      <h4 className='font-bold'>Edit Collection Name</h4>

      <form onSubmit={onSave} className='max-w-xl my-3'>
        <Input
          label='New Collection Name'
          value={name}
          onChange={e => setName(e.target.value)}
        />
        <div className='mt-2'>
          <Button className='text-gray' onClick={() => setPage('settings')}>
            Cancel
          </Button>
          <button
            type='submit'
            className='w-20 py-1 text-white rounded-md bg-primary'
          >
            {loading ? <Spinner className='w-5 h-5' /> : 'Save'}
          </button>
        </div>
      </form>
    </div>
  );
};

gql`
  mutation editCollectionName($input: UpdateListItemCollectionInput!) {
    updateListItemCollection(input: $input) {
      collection {
        id
        title
        slug
      }
      error {
        code
        message
      }
    }
  }
`;
