import React from 'react';
import { Spinner } from './Loading';

export const PrimaryButton = ({
  children,
  className,
  ...other
}: ButtonProps) => {
  return (
    <Button
      className={`text-white bg-primary hover:bg-primary-dark disabled:bg-primary-light ${className}`}
      {...other}
    >
      {children}
    </Button>
  );
};

interface ButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  loading?: boolean;
}

export const Button = ({
  children,
  className,
  disabled,
  loading,
  ...other
}: ButtonProps) => (
  <button
    className={`px-3 min-w-max md:px-4 py-1 text-sm rounded-md whitespace-nowrap disabled:cursor-not-allowed ${className}`}
    type='button'
    disabled={disabled || loading}
    {...other}
  >
    {!loading ? children : <Spinner className='w-4 h-4' />}
  </button>
);
