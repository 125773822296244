import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { gql } from '@apollo/client';
import { useItemSorting, getSortObj } from '../../components/SortButton';
import Options from '../../components/Items/Options';
import Items from '../../components/Items';
import isEmpty from 'lodash/isEmpty';
import { useItemLayout } from '../../lib/hooks';
import { useGetUncollectedItemsLazyQuery } from '../../generated/graphql';
import { Loading } from '../../components/Loading';

const Collection = () => {
  const { slug } = useParams();
  const [sort] = useItemSorting();
  const [layout] = useItemLayout();

  const [
    getItems,
    { data, error, fetchMore },
  ] = useGetUncollectedItemsLazyQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    returnPartialData: true,
  });

  useEffect(() => {
    const obj = getSortObj(sort);

    getItems({
      variables: {
        input: {
          slug: slug,
        },
        ...obj,
      },
    });
  }, [sort, getItems, slug]);

  if (error) {
    console.log('Error', error);
  }

  if (isEmpty(data?.profile?.uncollected)) {
    return <Loading />;
  }

  const listItems = data?.profile?.uncollected?.listItems;
  const profile = data?.profile;
  const isChildProfile =
    profile?.viewerCanEditList && data?.viewer.profile?.id !== profile?.id;
  const isProxyProfile = profile?.user === null;

  return (
    <>
      <div className='pb-3 md:pb-0'>
        <div className='flex items-center flex-grow'>
          <div className='inline m-0 mb-0 mr-2 text-2xl font-bold'>
            Uncategorized
          </div>
        </div>
        <div className='flex justify-between mt-2 mb-4'>
          <div className='flex items-center text-gray'>
            <span className='text-sm whitespace-nowrap'>
              {listItems?.total} Items
            </span>
          </div>
          <Options />
        </div>
        <Items
          fetchMore={fetchMore}
          items={listItems}
          sort={sort}
          layout={layout}
          hideName
          pageKey='uncollected'
          breakpointCols={'w-1/2 sm:w-1/3 md:w-1/3 lg:w-1/4 xl:w-1/5'}
          showReserved={
            (data?.viewer.isAuthenticated &&
              (!profile?.viewerCanEditList || isChildProfile)) ||
            isProxyProfile
          }
        />
      </div>
    </>
  );
};

gql`
  query getUncollectedItems(
    $input: ProfileSearchInput!
    $orderBy: [ListItemsOrderBy]! = TITLE
    $first: Int! = 0
    $last: Int! = 0
    $after: String! = ""
    $before: String! = ""
  ) {
    viewer {
      isAuthenticated
      profile {
        id
        slug
      }
    }
    profile(input: $input) @connection(key: "profile") {
      id
      viewerCanEditList
      ...ProfileThumbnail
      uncollected {
        ...ItemPagination
      }
      user {
        id
      }
    }
  }
  ${Items.fragments.items}
`;

export default Collection;
