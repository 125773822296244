import { gql } from '@apollo/client';
import React, { Dispatch, useState } from 'react';
import { SetStateAction } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  useDeleteCollectionMutation,
  useGetProfileCollectionSettingsQuery,
} from '../../generated/graphql';
import { Image } from '../Item/Image';
import { Modal } from '../Modal';
import { Label, EditBtn } from '../Utility';

interface DeleteModalProps {
  collectionId: string;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const DeleteModal = ({ collectionId, open, setOpen }: DeleteModalProps) => {
  const history = useHistory();

  const [deleteCollection] = useDeleteCollectionMutation({
    onCompleted: () => {
      history.goBack();
    },
  });

  const onDelete = () => {
    deleteCollection({
      variables: {
        input: { collectionId },
      },
    });
  };

  return (
    <Modal
      title='Delete Collection'
      onSave={onDelete}
      label='Delete'
      open={open}
      setOpen={setOpen}
    >
      <div>Deleting your collection will not delete your items.</div>
    </Modal>
  );
};

interface SettingsProps {
  setPage: Dispatch<SetStateAction<string>>;
}

export default ({ setPage }: SettingsProps) => {
  const { profileSlug, collectionSlug } = useParams();
  const [open, setOpen] = useState(false);
  const { data } = useGetProfileCollectionSettingsQuery({
    fetchPolicy: 'cache-only',
    variables: {
      input: {
        profileSlug,
        collectionSlug,
      },
    },
  });

  return (
    <>
      <div className='container'>
        <div className='flex items-center md:space-x-2'>
          <div className='inline m-0 text-2xl font-bold'>Settings</div>
        </div>

        <Label text='Cover Image'>
          <div className='h-48 mt-2 overflow-hidden w-96 rounded-2xl'>
            <Image
              className={`h-full w-full object-cover`}
              image={data?.collection?.image ?? null}
              item={data?.collection ?? { id: '' }}
            />
          </div>
          <EditBtn onClick={() => setPage('image')} />
        </Label>

        <Label text='Collection Name'>
          {data?.collection?.title}
          <EditBtn className='ml-2' onClick={() => setPage('editName')} />
        </Label>

        <div className='my-10 border-b-2 border-gray-light'></div>

        <button
          className='underline text-primary'
          onClick={() => setOpen(true)}
        >
          Delete Collection
        </button>
      </div>
      <DeleteModal
        collectionId={data?.collection?.id ?? ''}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
};

gql`
  query getProfileCollectionSettings($input: CollectionSearchInput!) {
    collection(input: $input) @connection(key: "collection") {
      id
      title
      slug
      image {
        ...ItemImage
      }
    }
  }
  ${Image.fragments.image}
`;

gql`
  mutation deleteCollection($input: DestroyListItemCollectionInput!) {
    destroyListItemCollection(input: $input) {
      error {
        code
        message
      }
    }
  }
`;
