import React, { Dispatch, SetStateAction } from 'react';
import { gql } from '@apollo/client';
import { Modal } from '../Modal';
import { useHistory } from 'react-router';
import { Profile, useRemoveItemMutation } from '../../generated/graphql';

gql`
  mutation removeItem($input: DestroyListItemInput!) {
    destroyListItem(input: $input) {
      profile {
        id
        slug
        listItems {
          total
        }
      }
    }
  }
`;

interface DeleteModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  id: string;
  profile: Pick<Profile, 'id' | 'slug'>;
}

export default ({ open, setOpen, id, profile }: DeleteModalProps) => {
  const history = useHistory();
  const [removeListItem] = useRemoveItemMutation({
    onCompleted: () => {
      history.goBack();
    },
  });

  const removeItem = () => {
    removeListItem({
      variables: {
        input: {
          itemId: id,
        },
      },
      optimisticResponse: {
        destroyListItem: {
          profile: {
            id: profile.id,
            slug: profile.slug,
            listItems: {
              total: 0,
              __typename: 'ListItemsConnection',
            },
            __typename: 'Profile',
          },
          __typename: 'DestroyListItemPayload',
        },
      },
    });
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title='Delete Item'
      onSave={removeItem}
      label='Confirm'
    >
      <></>
    </Modal>
  );
};
