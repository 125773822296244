import React from 'react';
import { useState } from 'react';
import Input from '../../components/Input';
import { ISettingsProps } from './index';
import { StatusType } from '../../components/Toasts/Status';
import { useUpdateProfileMutation } from '../../generated/graphql';
import { Button } from 'src/components/Button';

export default ({ setPage, setAlert, profile, setProfile }: ISettingsProps) => {
  const [name, setName] = useState(profile.name || '');

  const [updateProfile, { loading }] = useUpdateProfileMutation({
    onCompleted: data => {
      setAlert({
        open: true,
        type: StatusType.SUCCESS,
        message: 'Name successfully updated',
      });
      setPage('settings');
      setProfile(data.updateProfile?.profile);
    },
    onError: () => {
      setAlert({
        open: true,
        type: StatusType.DANGER,
        message: 'There was an unexpected error changing your name.',
      });
    },
  });

  const onSubmit = e => {
    e.preventDefault();
    updateProfile({
      variables: {
        input: {
          profileId: profile.id,
          name: name,
        },
      },
    });
  };

  const isSaveDisabled = () => !name || loading;

  return (
    <form onSubmit={onSubmit}>
      <div className='max-w-md space-y-2'>
        <h3 className='font-bold'>Change Name</h3>
        <Input
          value={name}
          onChange={e => setName(e.target.value)}
          label='Name'
        />
        <div className='space-x-2'>
          <Button
            type='submit'
            className='w-20 py-2 text-white rounded-md bg-primary'
            disabled={isSaveDisabled()}
            loading={loading}
          >
            Save
          </Button>
          <button
            type='button'
            className='text-gray-700'
            onClick={() => setPage('settings')}
          >
            Cancel
          </button>
        </div>
      </div>
    </form>
  );
};
