import React, { Dispatch, SetStateAction, useState } from 'react';
import { Slideover } from '../Slideover';
import { EditItem, EditItemType } from '../Forms/editItem';
import { StatusToast, StatusType } from '../Toasts/Status';
import {
  UpdateListItemInput,
  useUpdateItemMutation,
} from '../../generated/graphql';

interface Alert {
  open: boolean;
  type: StatusType;
  message?: string;
}

interface IEditProps {
  item: EditItemType;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export default function Edit({ open, setOpen, item }: IEditProps) {
  const [alert, setAlert] = useState<Alert>({
    open: false,
    type: StatusType.SUCCESS,
  });

  const [updateListItem, { loading }] = useUpdateItemMutation({
    onCompleted: () => {
      setAlert({
        type: StatusType.SUCCESS,
        open: true,
        message: 'Item successfully updated',
      });
    },
    onError: () => {
      setAlert({
        type: StatusType.DANGER,
        open: true,
        message: 'There was an error updating the item',
      });
    },
  });

  const updateItem = (i: Omit<UpdateListItemInput, 'itemId'>) => {
    updateListItem({
      variables: {
        input: {
          ...i,
          itemId: item.id,
        },
      },
    }).then(() => {
      setOpen(false);
    });
  };

  return (
    <>
      <Slideover title='Edit Item' open={open} setOpen={setOpen}>
        <EditItem
          item={item}
          onSave={updateItem}
          setOpen={setOpen}
          loading={loading}
        />
      </Slideover>
      <StatusToast
        open={alert.open}
        status={alert.type}
        onClose={() => setAlert({ open: false, type: StatusType.SUCCESS })}
      >
        {alert.message}
      </StatusToast>
    </>
  );
}
