import React, { SetStateAction, useEffect, useState } from 'react';
import {
  Route,
  Switch,
  useParams,
  useRouteMatch,
  useLocation,
} from 'react-router-dom';
import Helmet from 'react-helmet';
import ListHeader from './ListHeader';
import ErrBoundary from '../../components/ErrBoundary';
import NotFound from '../404';
import Items from '../../components/Profile/Items';
import Followers from '../../components/Profile/Followers';
import Following from '../../components/Profile/Following';
import Child from '../../components/Profile/Child';
import isEmpty from 'lodash/isEmpty';
import Collections from '../collections';
import Disclaimer from '../../components/AffiliateDisclaimer';
import { useGetProfileQuery } from '../../generated/graphql';
import { Dispatch } from 'react';
import gql from 'graphql-tag';
import { usePageView } from 'src/lib/hooks';

const ProfileInfo = ({ label, onClick, count, active }) => {
  return (
    <button
      onClick={onClick}
      className={`${
        active
          ? 'border-primary text-primary'
          : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200'
      } whitespace-nowrap flex pt-2 pb-4 px-1 border-b-2 font-medium text-sm md:text-base`}
      aria-current={active ? 'page' : undefined}
    >
      {label}
      <span
        className={`${
          active
            ? 'bg-primary bg-opacity-10 text-primary'
            : 'bg-gray-100 text-gray-900'
        } ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:text-sm`}
      >
        {count}
      </span>
    </button>
  );
};

interface TabsProps {
  className?: string;
  page: string;
  setPage: Dispatch<SetStateAction<string>>;
  data: any;
}

const Tabs = ({ className, page, setPage, data }: TabsProps) => {
  const { profile, viewer } = data;

  return (
    <div className={`block py-3 xl:py-0 xl:ml-2 ${className}`}>
      <div className='border-b border-gray-200'>
        <nav
          className='flex -mb-px space-x-8 overflow-x-auto'
          aria-label='Tabs'
        >
          <ProfileInfo
            label='Items'
            active={page === 'items'}
            onClick={() => setPage('items')}
            count={profile?.listItems?.total ?? 0}
          />
          <ProfileInfo
            label='Followers'
            active={page === 'followers'}
            onClick={() => setPage('followers')}
            count={profile?.followers?.total ?? 0}
          />
          <ProfileInfo
            label='Following'
            active={page === 'following'}
            onClick={() => setPage('following')}
            count={profile?.user?.following?.total ?? 0}
          />
          {viewer?.profile?.id === profile?.id && (
            <ProfileInfo
              label='Child Profiles'
              active={page === 'child'}
              onClick={() => setPage('child')}
              count={viewer.user?.proxy_profiles?.total ?? 0}
            />
          )}
        </nav>
      </div>
    </div>
  );
};

Tabs.fragments = {
  tabs: gql`
    fragment TabsSummary on Viewer {
      user {
        proxy_profiles {
          total
        }
        following {
          total
        }
      }
    }
  `,
};

export default function Profile() {
  usePageView('Profile');
  const { slug } = useParams();
  const { state } = useLocation();
  const { path } = useRouteMatch();
  const [page, setPage] = useState(state?.following ? 'following' : 'items');

  useEffect(() => {
    setPage(state?.following ? 'following' : 'items');
  }, [slug, state]);

  const { data, loading, error } = useGetProfileQuery({
    variables: { input: { slug } },
    returnPartialData: true,
  });

  if (error) {
    console.error(error);
    return <ErrBoundary />;
  }

  if (data && isEmpty(data.profile) && !loading) {
    return <NotFound />;
  }

  const pages = {
    items: <Items />,
    followers: <Followers />,
    following: <Following />,
    child: <Child />,
  };

  return (
    <div className='px-3 pb-16 md:p-0'>
      <Helmet>
        <title>{data?.profile?.name ?? ''}</title>
      </Helmet>
      <div className='pb-4'>
        <Disclaimer />
      </div>
      <div className='grid grid-cols-1 md:gap-9 md:grid-cols-12'>
        <div className='col-span-3 xl:col-span-2'>
          <ListHeader data={data} />
        </div>
        <div className='col-span-9 space-y-6 xl:col-span-10'>
          <Switch>
            <Route path={`${path}/collection`} component={Collections} />
            <Route
              key={slug}
              render={() => (
                <>
                  <Tabs page={page} setPage={setPage} data={data} />

                  {pages[page]}
                </>
              )}
            />
          </Switch>
        </div>
      </div>
    </div>
  );
}

gql`
  query getProfile($input: ProfileSearchInput!) {
    viewer {
      isAuthenticated
      profile {
        id
        slug
      }
      user {
        proxy_profiles {
          total
        }
      }
    }
    profile(input: $input) @connection(key: "profile") {
      id
      slug
      url
      name
      bio
      image {
        fixed(input: { ratio: 1, width: 125 })
      }
      viewerIsFollowing
      viewerCanEditList
      listItems {
        total
      }
      followers {
        total
      }
      user {
        id
        following {
          total
        }
      }
    }
  }
`;
