import React from 'react';
import MasonryComp from 'react-masonry-component';
import Item from '../Item/Thumbnail';

const Masonry = ({
  items,
  isLoading,
  hideName,
  showReserved,
  breakpointCols = 'w-1/2 md:w-1/3 lg:w-1/5 xl:w-1/6',
}) => {
  const itemsResult = [
    ...items.map(({ node: item }, idx) => (
      <div className={`px-1.5 ${breakpointCols}`}>
        <Item
          key={item.id}
          item={item}
          hideName={hideName}
          showReserved={showReserved}
          delay={(idx % 15) * 30}
        />
      </div>
    )),
  ];

  return (
    <MasonryComp
      className='w-full -pl-1.5'
      disableImagesLoaded={false}
      updateOnEachImageLoad={false}
    >
      {itemsResult}
    </MasonryComp>
  );
};

export default Masonry;
