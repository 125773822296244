import React from 'react';
import { Modal } from './Modal';

function arrayBufferToBase64( buffer ) {
  var binary = '';
  var bytes = new Uint8Array( buffer );
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
      binary += String.fromCharCode( bytes[ i ] );
  }
  return btoa( binary );
}

export default ({ setImageUrl, setImageData}) => {

  const readImage = (blob) => {
    const reader = new FileReader();

    reader.readAsArrayBuffer(blob); 
    // reader.readAsDataURL(blob); 
    reader.onloadend = function() {
      var res = reader.result;
      console.log("Res", res);
      setImageData({ data: `data:image/png;base64,${arrayBufferToBase64(res)}`, buffer: res })
      setImageUrl(null)
    }
  }

  // const onPaste = async (e) => {
  //   try {
  //     const clipboardItems = await navigator.clipboard.read();

  //     for (const clipboardItem of clipboardItems) {

  //         // we can now use blob here
  //         if (clipboardItem.types.includes('text/plain')) {
  //           const blob = await clipboardItem.getType('text/plain')
  //           const reader = new FileReader();

  //           reader.readAsText(blob)
  //           reader.onloadend = function() {
  //             var res = reader.result;
  //             setImageData({data: null, buffer: null })
  //             setImageUrl(res)
  //           }
  //         } else if (clipboardItem.types.includes('image/png')) {
  //           const blob = await clipboardItem.getType('image/png')
  //           readImage(blob)
  //       }
  //     }
  
  //   } catch (err) {
  //     console.error(err.name, err.message);
  //   }
  // }

  const onUpload = (e) => {
    const file = e.target.files[0]
    readImage(file)
  }

  return (
    <Modal id="image-select-modal" title="Edit Image"> 
      <div className="container pt-1 pb-4">
        <div className="row no-gutters justify-content-around">
          <div className="col-auto">
            <label className="btn btn-primary px-4">
                <input type="file" accept="image/*" onChange={onUpload} data-target="#image-select-modal" data-toggle="modal" />
                <i className="fas fa-upload mr-1"></i> Upload
            </label>
          </div>
          {/* <div className="col-auto">
            <button
              type="button"
              className="btn btn-outline-secondary text-nowrap px-md-4"
              data-target="#image-select-modal"
              data-toggle="modal"
              onClick={onPaste}
            >
              <i className="fas fa-paste mr-1"></i> Paste from Clipboard
            </button>
          </div> */}
        </div>
      </div>
    </Modal>
  )
}