import React, { useEffect } from 'react';
import { useQuery, gql } from '@apollo/client';
import { itemSortVar } from '../lib/apollo';
import { Menu, Transition } from '@headlessui/react';

const LAST = 'last';
const FIRST = 'first';
const AFTER = 'after';
const UPDATED = 'UPDATED';
const TITLE = 'TITLE';

const ITEM_SORT_KEY = 'item-sort';

const icons = {
  'name-asc': {
    name: 'Name',
    icon: <i className='fas fa-sort-amount-up'></i>,
    type: LAST,
    orderBy: TITLE,
    cursor: AFTER,
    key: 'name-asc',
  },
  'name-desc': {
    name: 'Name',
    icon: <i className='fas fa-sort-amount-down'></i>,
    type: FIRST,
    orderBy: TITLE,
    cursor: AFTER,
    key: 'name-desc',
  },
  'updated-asc': {
    name: 'Last Updated',
    icon: <i className='fas fa-sort-amount-up'></i>,
    type: LAST,
    orderBy: UPDATED,
    cursor: AFTER,
    key: 'updated-asc',
  },
  'updated-desc': {
    name: 'Last Updated',
    icon: <i className='fas fa-sort-amount-down'></i>,
    type: FIRST,
    orderBy: UPDATED,
    cursor: AFTER,
    key: 'updated-desc',
  },
};

export const getSortObj = (sort, cursor = '') => {
  const obj = {
    after: '',
    before: '',
    first: 0,
    last: 0,
  };

  return {
    ...obj,
    [sort.type]: 25,
    orderBy: sort.orderBy,
    [sort.cursor]: cursor,
  };
};

export const useItemSorting = () => {
  const { data } = useQuery(
    gql`
      query getItemSort {
        itemSort @client
      }
    `
  );

  useEffect(() => {
    localStorage.setItem(ITEM_SORT_KEY, data.itemSort);
  }, [data]);

  return [icons[data.itemSort], itemSortVar];
};

const MenuItem = ({ onClick, children, selected }) => (
  <Menu.Item disabled={selected}>
    {({ active }) => (
      <button
        className={`block px-4 py-2 bg-opacity-10 text-xs w-full text-left ${active &&
          'bg-primary'} ${selected && 'text-primary'}`}
        onClick={onClick}
      >
        {children}
      </button>
    )}
  </Menu.Item>
);

const SortOptions = ({ activeKey, setSort }) => {
  return (
    <Menu.Items
      static
      className='z-10 py-3 mt-2 bg-white border rounded-lg shadow-lg outline-none w-max border-gray-light'
    >
      <MenuItem
        selected={activeKey === 'name-desc'}
        onClick={() => setSort('name-desc')}
      >
        Name: A to Z
      </MenuItem>
      <MenuItem
        selected={activeKey === 'name-asc'}
        onClick={() => setSort('name-asc')}
      >
        Name: Z to A
      </MenuItem>
      <MenuItem
        selected={activeKey === 'updated-desc'}
        onClick={() => setSort('updated-desc')}
      >
        Last Updated: Newest First
      </MenuItem>
      <MenuItem
        selected={activeKey === 'updated-asc'}
        onClick={() => setSort('updated-asc')}
      >
        Last Updated: Oldest First
      </MenuItem>
    </Menu.Items>
  );
};

const SortButton = ({ className }) => {
  const [sort, setSort] = useItemSorting();

  return (
    <div className={`relative inline-block ${className}`}>
      <Menu>
        {({ open }) => (
          <>
            <Menu.Button className='text-gray-400 border-solid md:border border-gray md:text-gray rounded-2xl md:px-4 focus:outline-none focus:ring-primary focus:ring-opacity-20 focus:ring-4'>
              <span className='hidden md:block'>
                <small className='mr-2'>{sort.name}</small> {sort.icon}
              </span>
              <div className='md:hidden'>
                <i className='fas fa-sliders-h'></i>
              </div>
            </Menu.Button>
            <Transition
              show={open}
              className='absolute right-0 z-20 origin-top-right'
              enter='transition duration-100 ease-out '
              enterFrom='transform scale-95 opacity-0'
              enterTo='transform scale-100 opacity-100'
              leave='transition duration-75 ease-out'
              leaveFrom='transform scale-100 opacity-100'
              leaveTo='transform scale-95 opacity-0'
            >
              <SortOptions activeKey={sort.key} setSort={setSort} />
            </Transition>
          </>
        )}
      </Menu>
    </div>
  );
};

export default SortButton;
