import { gql } from '@apollo/client';
import React, { Dispatch, SetStateAction } from 'react';
import CreatableSelect from 'react-select/creatable';
import {
  CreateCollectionMutationResult,
  useCreateCollectionMutation,
} from '../../generated/graphql';

const getStyles = () => {
  return {
    control: (provided, state) => {
      const borderColor = state.isFocused ? 'none' : '#DFDFDF';

      return {
        ...provided,
        borderRadius: '.375rem',
        borderColor: borderColor,
        // boxShadow: boxShadow,
        ':hover': {
          borderColor: borderColor,
        },
      };
    },
    input: provided => ({
      ...provided,
      outline: 'none',
    }),
    multiValue: provided => ({
      ...provided,
      padding: '0px 0px 0px 5px',
      borderRadius: '1rem',
    }),
    multiValueRemove: provided => ({
      ...provided,
      borderRadius: '0px 1rem 1rem 0rem',
      marginLeft: '5px',
      ':hover': {
        backgroundColor: '#e13e3959',
      },
    }),
    option: (provided, state) => {
      return {
        ...provided,
        padding: '10px 10px',
        backgroundColor: state.isFocused ? '#e13e3959' : 'none',
        ':hover': {
          backgroundColor: '#e13e3959',
        },
      };
    },
  };
};

interface Collection {
  value: string;
  label: string;
}

interface CollectionSelectProps {
  profileId: string;
  value: Collection[];
  setCollections: Dispatch<SetStateAction<Collection[]>>;
  setPromise: Dispatch<SetStateAction<Promise<void>>>;
  options: Collection[];
}

export const CollectionSelect = ({
  profileId,
  value,
  setCollections,
  setPromise,
  ...other
}: CollectionSelectProps) => {
  const [createCollection] = useCreateCollectionMutation();

  const onCollectionChange = (newValue, actionMeta) => {
    if (actionMeta.action === 'create-option') {
      setPromise(
        createCollection({
          variables: {
            input: {
              profileId: profileId,
              title: newValue[newValue.length - 1].value,
            },
          },
        }).then(({ data }: CreateCollectionMutationResult) => {
          const collection = data?.createListItemCollection?.collection;
          if (collection) {
            const { id, title } = collection;
            setCollections([
              ...value,
              {
                value: id,
                label: title,
              },
            ]);
          }
        })
      );
    } else {
      setCollections(newValue);
    }
  };

  return (
    <CreatableSelect
      isMulti
      styles={getStyles()}
      onChange={onCollectionChange}
      value={value}
      placeholder=''
      noOptionsMessage={() => (
        <div>No Collections. (Start typing to add a new collection)</div>
      )}
      {...other}
    />
  );
};

gql`
  mutation createCollection($input: CreateListItemCollectionInput!) {
    createListItemCollection(input: $input) {
      collection {
        id
        slug
        title
      }
      error {
        code
        message
      }
    }
  }
`;
