import React from 'react';
import { ThumbnailImage } from './Image';
import { ProfileLink } from '../Profile/Link';
import { Transition } from '@headlessui/react';
import { gql } from '@apollo/client';
import {
  ItemThumbnailFragment,
  useGetItemLazyQuery,
} from '../../generated/graphql';
import { ReservedIcon } from './Reserved';
import { useAuthentication, usePrefetch } from '../../lib/hooks';

interface IThumbnailProps {
  item: ItemThumbnailFragment;
  showReserved: boolean;
  delay: number;
  hideName: boolean;
}

export const ThumbnailLoader = () => (
  <div className={`container px-0 py-1 pb-2 mb-3`}>
    <div className='row no-gutters'>
      <div className='item-img loading w-100' style={{ height: 125 }}></div>
      <div className='col-12 mt-2'>
        <div className='loading' style={{ height: 25 }} />
      </div>
    </div>
  </div>
);

function Thumbnail({ item, showReserved, hideName }: IThumbnailProps) {
  const isAuthenticated = useAuthentication();
  const { title, image, slug, quantity, reserved, profile } = item;
  const [getItem] = useGetItemLazyQuery();
  const isReservedVisible =
    isAuthenticated && showReserved && quantity - reserved === 0;
  const [onFetchItem, onFetchCancel] = usePrefetch(() => {
    if (item.slug) {
      getItem({
        variables: { input: { item: item.slug } },
      });
    }
  });

  return (
    <Transition
      show={true}
      appear
      className={`py-1 pb-2 mb-2`}
      enter='transition duration-300'
      enterFrom='opacity-0 transform translate-y-2'
      enterTo='opacity-100'
    >
      <div className='relative w-full'>
        <div
          className={`${isReservedVisible ? 'opacity-40' : 'opacity-100'}`}
          onMouseOver={onFetchItem}
          onMouseLeave={onFetchCancel}
          onTouchStart={onFetchItem}
          onTouchCancel={onFetchCancel}
        >
          <ThumbnailImage
            className='duration-200 ease-out transform hover:scale-110'
            image={image || null}
            item={item}
            to={`/wishlist/${profile?.slug}/${slug}`}
          />
        </div>
        {isReservedVisible && (
          <div className='absolute top-1 right-1'>
            <ReservedIcon />
          </div>
        )}
      </div>
      {title && (
        <div
          className='pt-1 m-0 text-sm font-medium truncate md:text-base'
          title={title}
          data-cy={title}
        >
          {title}
        </div>
      )}
      {!hideName && <ProfileLink profile={profile} diameter={24} />}
    </Transition>
  );
}

Thumbnail.fragments = {
  item: gql`
    fragment ItemThumbnail on ListItem {
      id
      title
      slug
      reserved
      quantity
      image {
        ...ItemImage
      }
      profile {
        id
        ...ProfileThumbnail
      }
      url
      affiliate {
        network
      }
      createdAt
      isArchived
      notes
      link
      viewerHasReservedQuantity
    }
    ${ProfileLink.fragments.profile}
    ${ThumbnailImage.fragments.image}
  `,
};

export default Thumbnail;
