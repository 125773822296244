import React, { useState } from 'react';
import Input from './Input';
import { validate } from 'email-validator';
import { Button, PrimaryButton } from './Button';
import { gql } from '@apollo/client';
import { useInviteFriendMutation } from 'src/generated/graphql';
import { Alert } from 'src/types/lib';
import { StatusToast, StatusType } from './Toasts/Status';

interface InviteInputeProps {
  className?: string;
}

export const InviteInput = ({ className }: InviteInputeProps) => {
  const [email, setEmail] = useState('');
  const [alert, setAlert] = useState<Alert>({
    open: false,
    type: StatusType.SUCCESS,
  });
  const [sendInvite, { loading }] = useInviteFriendMutation({
    onCompleted: () => {
      setEmail('');
      setAlert({
        type: StatusType.SUCCESS,
        open: true,
        message: 'Email successfully sent',
      });
    },
    onError: () => {
      setAlert({
        type: StatusType.DANGER,
        open: true,
        message: 'There was an error sending the email',
      });
    },
  });

  const onSubmit = e => {
    e.preventDefault();
    sendInvite({
      variables: {
        input: { email },
      },
    });
  };

  return (
    <>
      <form className='flex' onSubmit={onSubmit}>
        <Input
          placeholder='Enter email'
          className='h-10 w-60'
          value={email}
          onChange={e => setEmail(e.target.value)}
          error={email && !validate(email) ? 'Not a valid email address' : ''}
        />
        <PrimaryButton
          type='submit'
          className='h-10 ml-2 w-28'
          disabled={!email || !validate(email)}
          loading={loading}
        >
          Send Invite
        </PrimaryButton>
      </form>
      <StatusToast
        open={alert.open}
        status={alert.type}
        onClose={() => setAlert({ open: false, type: StatusType.SUCCESS })}
      >
        {alert.message}
      </StatusToast>
    </>
  );
};

gql`
  mutation inviteFriend($input: InviteFriendInput!) {
    inviteFriend(input: $input) {
      error {
        code
        message
      }
    }
  }
`;
