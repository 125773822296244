import React from 'react';

export default function Rounded({ children }) {
  return (
    <div className='bg-primary md:bg-transparent h-full'>
      <div className='bg-white rounded-t-2xl h-full pt-2 md:pt-0'>
        {children}
      </div>
    </div>
  );
}
