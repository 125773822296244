import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { Loading } from '../Loading';
import { gql } from '@apollo/client';
import { Tab } from '../Tab';
import { getSortObj, useItemSorting } from '../SortButton';
import { useItemLayout, usePreference } from '../../lib/hooks';
import Collections from './Collections';
import ItemLayout from '../Items';
import Options from '../Items/Options';
import isEmpty from 'lodash/isEmpty';
import { AddItemSlideover } from '../AddItemSlidover';
import { useGetProfileItemsLazyQuery } from '../../generated/graphql';
import { EmptyState } from '../EmptyState';
import PlusIcon from '@heroicons/react/solid/PlusIcon';
import { ReactComponent as PresentIcon } from '../../icons/EmptyCase/openPresent.svg';

gql`
  query getProfileItems(
    $input: ProfileSearchInput!
    $first: Int! = 0
    $after: String! = ""
    $last: Int! = 0
    $before: String! = ""
    $orderBy: [ListItemsOrderBy]! = TITLE
  ) {
    viewer {
      isAuthenticated
      profile {
        id
        slug
      }
    }
    profile(input: $input) @connection(key: "profile") {
      id
      slug
      name
      viewerCanEditList
      ...ProfileImage
      ... on Profile {
        ...ItemPagination
      }
      user {
        id
      }
      collections {
        edges {
          node {
            id
            slug
            title
          }
        }
      }
    }
  }
  ${ItemLayout.fragments.items}
  ${AddItemSlideover.fragments.profile}
`;

const Header = ({ page, setPage, ...other }) => {
  return (
    <div className='flex items-center justify-between pb-6 flex-nowrap'>
      <div className='flex pl-0'>
        <Tab isActive={page === 'collections'}>
          <button
            type='button'
            onClick={() => setPage('collections')}
            className={`text-inherit font-inherit text-sm md:text-base`}
          >
            Collections
          </button>
        </Tab>
        <Tab isActive={page === 'items'}>
          <button
            type='button'
            onClick={() => setPage('items')}
            className={`text-inherit font-inherit text-sm md:text-base`}
          >
            All Items
          </button>
        </Tab>
      </div>
      {page === 'items' && <Options {...other} />}
    </div>
  );
};

const Items = () => {
  const { slug } = useParams();
  const [layout] = useItemLayout();
  const [sort] = useItemSorting();
  const [open, setOpen] = useState(false);
  const [
    getItems,
    { data, loading, error, fetchMore },
  ] = useGetProfileItemsLazyQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    returnPartialData: true,
  });

  useEffect(() => {
    const obj = getSortObj(sort);

    getItems({ variables: { input: { slug: slug }, ...obj } });
  }, [sort, slug, getItems]);

  if (error) {
    console.log('Error', error);
  }

  if (isEmpty(data) || (!data?.profile?.listItems?.edges?.length && loading)) {
    return <Loading />;
  }

  let elem;
  const profile = data?.profile;
  const filteredItems =
    // @ts-ignore
    profile.listItems?.edges.filter(({ node: item }) => !item._deleted) ?? [];
  const hasItems = !!filteredItems.length;
  const isChildProfile =
    profile?.viewerCanEditList && data?.viewer.profile?.id !== profile?.id;
  const isProxyProfile = profile?.user === null;

  if (hasItems) {
    const props = {
      fetchMore: fetchMore,
      layout: layout,
      sort: sort,
      items: profile?.listItems,
      hideName: true,
      breakpointCols: 'w-1/2 sm:w-1/3 md:w-1/3 lg:w-1/4 xl:w-1/5',
      pageKey: 'profile',
      showReserved:
        (data?.viewer.isAuthenticated &&
          (!profile?.viewerCanEditList || isChildProfile)) ||
        isProxyProfile,
    };

    elem = <ItemLayout {...props} />;
  } else {
    if (!profile?.viewerCanEditList) {
      elem = <p>{profile?.name} has not added any items.</p>;
    } else {
      elem = (
        <EmptyState
          icon={PresentIcon}
          title='Start your wishlist!'
          description='Get started on building the perfect wishlist by adding your first item!'
        >
          <button
            className='flex items-center px-6 py-3 text-sm text-white rounded-md bg-primary'
            onClick={() => setOpen(true)}
            type='button'
          >
            <PlusIcon className='w-5 h-5 stroke-current stroke-0' />
            <span className='ml-2'>Add to List</span>
          </button>
          {/* @ts-ignore */}
          <AddItemSlideover open={open} setOpen={setOpen} profile={profile} />
        </EmptyState>
      );
    }
  }

  return elem;
};

const PROFILE_TAB = 'profile_tab';

export default function ItemContainer() {
  const [page, setPage] = usePreference(PROFILE_TAB, 'items');

  const pages = {
    items: <Items />,
    collections: <Collections />,
  };

  return (
    <div className='pb-3 lg:pb-0 lg:mb-0'>
      <Header page={page} setPage={setPage} />
      {pages[page]}
    </div>
  );
}
