import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Following from './Following';
import { ProfileImage } from './Profile/Link';
import { useAuthentication } from '../lib/hooks';
import { dateFormatter } from '../lib/utils';
import { gql } from '@apollo/client';
import { useMediaQuery } from 'react-responsive';
import { ProfileDataFragment } from '../generated/graphql';

interface ProfileProps {
  profile: ProfileDataFragment;
  onClick: (p: ProfileDataFragment) => void;
}

const Profile = ({ profile, onClick, ...props }: ProfileProps) => {
  const isAuthenticated = useAuthentication();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const { name, slug, listItems, listUpdatedAt, viewerIsFollowing } = profile;
  const [date, setDate] = useState(null);

  dateFormatter(listUpdatedAt, res => setDate(res.date));

  return (
    <div
      className={`flex items-center px-2 py-4 rounded-sm hover:bg-gray-100 focus:bg-gray-100 focus:outline-none`}
      {...props}
    >
      <Link
        to={`/wishlist/${slug}`}
        className='flex items-center flex-1 space-x-1 overflow-auto text-black no-underline'
        onClick={() => onClick(profile)}
      >
        <ProfileImage profile={profile} diameter={isMobile ? 32 : 40} />
        <div className='pl-3 overflow-auto'>
          <h4 className='mb-0 text-sm font-medium text-gray-900 truncate md:text-base hover:underline'>
            {name}
          </h4>
          <div className='flex space-x-1 text-sm text-gray md:text-base whitespace-nowrap'>
            <div>{listItems?.total ?? 0} Items</div>
            <div>|</div>
            <div>{date}</div>
          </div>
        </div>
      </Link>
      {isAuthenticated && (
        <Following
          profile={profile}
          targetProfile={profile}
          following={viewerIsFollowing}
        />
      )}
    </div>
  );
};

Profile.fragments = {
  profile: gql`
    fragment ProfileData on Profile {
      id
      name
      slug
      ...ProfileImage
      listUpdatedAt
      viewerIsFollowing
      listItems {
        total
      }
    }
    ${ProfileImage.fragments.profile}
  `,
};

type ProfileList = Array<{ node: ProfileDataFragment }>;

interface ProfilesProps {
  profiles: ProfileList;
  onClick?: (a: any) => void;
}

function Profiles({ profiles, onClick = () => {} }: ProfilesProps) {
  return (
    <div className='max-w-2xl divide-y divide-gray-200'>
      {profiles.map(({ node: profile }: { node: ProfileDataFragment }) => {
        return <Profile key={profile.id} profile={profile} onClick={onClick} />;
      })}
    </div>
  );
}

Profiles.fragments = {
  profile: Profile.fragments.profile,
};

export default Profiles;
