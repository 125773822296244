import React from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as Arrow } from '../icons/Arrow.svg';

interface Props {
  className?: String;
  onClick?: () => {};
}

export default ({ className, onClick }: Props) => {
  let history = useHistory();

  return (
    <button
      className={`border-2 rounded-full p-0 md:p-2 border-primary ${className}`}
      onClick={() => {
        onClick ? onClick() : history.goBack();
      }}
    >
      <Arrow className='fill-current text-white md:text-primary' />
    </button>
  );
};
