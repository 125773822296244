import React from 'react';
import whiteLogo from '../Logo.png';
import logo from '../icons//blacklogo.png';
import { Link, Redirect } from 'react-router-dom';
import { useAuthentication, usePageView } from '../lib/hooks';
import {
  CursorClickIcon,
  UserGroupIcon,
  SparklesIcon,
  GiftIcon,
  CheckIcon,
  PencilIcon,
  CollectionIcon,
  UsersIcon,
  LockClosedIcon,
} from '@heroicons/react/solid';
import itemGrid from '../icons/sales/Grid.png';
import Collection from '../icons/sales/Collection.png';
import Reservations from '../icons/sales/Reservations.png';
import Items from '../icons/sales/items.png';
import Hero from '../icons/sales/hero.png';

// const FiveStars = () => (
//   <div className='space-x-2'>
//     <StarIcon className='h-5' />
//     <StarIcon className='h-5' />
//     <StarIcon className='h-5' />
//     <StarIcon className='h-5' />
//     <StarIcon className='h-5' />
//   </div>
// );

const Icon = ({ name: Name, className }) => (
  <Name className={`h-12 p-4 text-gray-900 rounded-md ${className}`} />
);

const Img = ({ src, className }) => (
  <>
    <div
      className={`absolute transform -translate-x-1/2 -translate-y-1/2 rounded-full w-80 h-80 md:h-96 md:w-96 bg-primary top-1/2 left-1/2 ${className}`}
    ></div>
    <img src={src} className='relative z-20' alt='' />
  </>
);

const info = [
  {
    icon: <Icon name={CursorClickIcon} className='bg-cyan-100' />,
    title: 'Add items',
    description: 'Easily add any item to your list',
  },
  {
    icon: <Icon name={UserGroupIcon} className='bg-yellow-100' />,
    title: 'Follow others',
    description: 'Share your profile and follow each other on Giftory',
  },
  {
    icon: <Icon name={SparklesIcon} className='bg-light-blue-100' />,
    title: 'For free',
    description: 'Unlimited items and collections, completely free',
  },
];

const features = [
  {
    icon: <Icon name={PencilIcon} className='bg-red-100' />,
    img: <Img src={Collection} className='bg-cyan-100' />,
    title: 'Create collections for any occassion',
    linkText: 'Create your list for free',
    review:
      'This is review text. It can be really long with details about things that we care about.',
    description: `Whether it is a hobby, a personal list, a baby registry, or a family event, you can easily create and share collections for any reason. Giftory makes it easy to share any list you want to the right people!`,
  },
  {
    icon: <Icon name={GiftIcon} className='bg-red-100' />,
    img: <Img src={Items} className='bg-yellow-100' />,

    title: 'Get the exact gifts you want',
    linkText: 'Find your friends',
    review:
      'This is review text. It can be really long with details about things that we care about.',
    description: `Follow your friends and family to see their wishlist and interests. When
    you follow each other, everyone will know exactly the perfect gift for
    you, your friends, and your family!`,
  },
  {
    icon: <Icon name={CheckIcon} className='bg-red-100' />,
    img: <Img src={Reservations} className='bg-light-blue-100' />,
    title: 'Mark gifts as reserved',
    linkText: 'Start reserving items',
    review:
      'This is review text. It can be really long with details about things that we care about.',
    description: `What’s worse than getting someone their most wanted gift just to learn someone else already got it for them?? With Giftory, anyone can mark someone’s gift as “reserved” so you know it is already claimed. Don’t worry, only visitors will know which items are reserved, the gift will remain a surprise to the list owner.`,
  },
];

const moreFeatures = [
  {
    icon: CollectionIcon,
    title: 'Collections',
    description:
      'Create collections of items as a subsection of your main list. Perfect for hobbies, events,, and your next project.',
  },
  {
    icon: UsersIcon,
    title: 'Groups',
    description:
      'Create groups to keep your favorite people in one place. Use groups for holidays or upcoming events.',
    comingSoon: true,
  },
  {
    icon: LockClosedIcon,
    title: 'Reservations',
    description:
      'Prevent duplicated purchases with reservations. Thankfully, list owners will not see what is marked as reserved.',
  },
];

const Info = ({ icon, title, description }) => (
  <div className='p-6 space-y-2 text-center md:w-72'>
    {icon}
    <div className='text-2xl font-extrabold text-gray-800'>{title}</div>
    <div className='text-base text-gray-500'>{description}</div>
  </div>
);

const Feature = ({ title, description, icon, img, review, linkText }) => (
  <div className='flex flex-col justify-center max-w-5xl py-4 mx-auto md:py-0 md:p md:odd:flex-row-reverse md:flex-row'>
    <div className='relative flex justify-center flex-1 '>{img}</div>
    <div className='self-center flex-1'>
      {icon}
      <div className='mt-6 text-3xl font-extrabold'>{title}</div>
      <div className='mt-4 text-lg font-normal text-gray-500'>
        {description}
      </div>
      <Link
        to='/signup'
        className='inline-block px-4 py-3 mt-3 text-white rounded-md bg-primary'
      >
        {linkText}
      </Link>
      {/* <div className='py-10'>
        <div className='w-full h-px border-t'></div>
      </div>
      <div className=''>
        <FiveStars />
        <div className='mt-3'>
          <div className='text-gray-500'>"{review}"</div>
          <div className='mt-3'>
            <span>Photo</span>
            <span className='ml-2 font-medium'>Name</span>
          </div>
        </div>
      </div> */}
    </div>
  </div>
);

const Feature2 = ({ icon: Icon, title, description, comingSoon }) => (
  <div className='relative flex-1 h-56 p-6 text-center rounded-md bg-gray-50'>
    <div className='absolute top-0 flex items-center justify-center w-12 h-12 text-white transform -translate-x-1/2 -translate-y-1/2 rounded-md left-1/2 bg-primary'>
      <Icon className='w-5 h-5' />
    </div>
    <div className='mt-6 font-medium text-gray-900'>{title}</div>
    <div className='mt-5 text-base text-gray-500'>{description}</div>
    {comingSoon && (
      <div className='mt-5 text-sm text-gray-400'>Coming Soon!</div>
    )}
  </div>
);

export default function Index() {
  usePageView('Sales');
  const isAuthenticated = useAuthentication();

  if (isAuthenticated) {
    return <Redirect to='/feed' />;
  }

  return (
    <div className='bg-gray-100 md:-ml-4'>
      <div className='pt-2 text-center'>
        <img className='h-8 md:h-12' src={logo} alt='logo' />
      </div>
      <div className='max-w-5xl px-3 pt-2 mx-auto pb-11'>
        <div className='flex max-w-5xl py-10'>
          <div className='hidden w-2/5 md:block'>
            <img
              className='float-right'
              src={Hero}
              alt='logo'
              height={568}
              width={300}
            />
          </div>
          <div className='self-center flex-1 text-center md:text-left md:w-2/5 md:ml-6'>
            <div className='mt-4 text-4xl font-extrabold'>
              <span className='mr-2 md:block'>Build your perfect</span>
              <span className='text-primary'>wishlist</span>
            </div>
            <div className='max-w-lg mx-auto mt-5 text-xl text-gray-500 font-400 md:mx-0'>
              Add items from any website to your wishlist and share with your
              friends. With Giftory, you know you’ll get the gift the actually
              want!
            </div>
            <div className='flex flex-col mt-8 md:flex-row'>
              <Link
                to='/signup'
                className='block w-full py-3.5 text-center text-white rounded-md md:w-48 bg-primary md:mr-4'
              >
                Sign up for free
              </Link>
              <Link
                to='/login'
                className='block w-full py-3.5 mt-2.5 md:mt-0 md:w-28 text-center rounded-md shadow-2xl md:shadow-none text-primary bg-white'
              >
                Log in
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className='relative'>
        <div className='relative z-20 p-3'>
          <div className='max-w-5xl mx-auto bg-white divide-y rounded-md shadow-2xl shadow-3xl md:flex md:justify-around md:divide-y-0'>
            {info.map(({ icon, title, description }) => (
              <Info icon={icon} title={title} description={description} />
            ))}
          </div>
        </div>
        <div className='absolute inset-x-0 bottom-0 z-10 bg-white h-1/2'></div>
      </div>

      <div className='px-3 py-10 bg-white'>
        {features.map(feature => (
          <Feature {...feature} />
        ))}
      </div>

      <div className='relative flex justify-center px-3 py-20 overflow-hidden text-white bg-primary'>
        <div className='hidden md:block'>
          <img
            src={itemGrid}
            className='absolute left-0 transform -translate-x-1/2 -rotate-12 -top-16'
            alt=''
          />
          <img
            src={itemGrid}
            className='absolute right-0 transform translate-x-1/2 -rotate-12 -top-32'
            alt=''
          />
        </div>
        <div className='text-center md:max-w-xl'>
          <div className='text-3xl font-extrabold'>
            <div>Create. Collect. Share.</div>
            <div>All for free!</div>
          </div>
          <div className='mt-5'>
            Giftory is free! Add as many items as you want to your list,
            collections, or groups. Free for you and your friends.
          </div>
          <Link
            to='/signup'
            className='inline-block w-full px-5 py-2 mt-4 bg-white rounded-md md:w-40 text-primary'
          >
            Sign up for free
          </Link>
        </div>
      </div>

      <div className='py-20 bg-white'>
        <div className='container max-w-3xl text-center'>
          <div className='text-base font-semibold text-gray-400'>
            MY GIFTORY
          </div>
          <div className='text-4xl font-extrabold text-gray-900'>
            Everything you need for the perfect gift
          </div>
          <div className='mt-5 text-xl text-gray-500'>
            Giftory has powerful tools that makes any gift-giving as easy as
            possible. Simply add items to your list, collection, registry, or
            event, and share with the right people.
          </div>
        </div>
        <div className='px-3 mx-auto mt-20 space-y-10 max-w-7xl md:space-y-0 md:space-x-5 md:flex md:justify-around'>
          {moreFeatures.map(feature => (
            <Feature2 {...feature} />
          ))}
        </div>
      </div>

      <div className='py-10 text-white bg-gray-800 md:py-20'>
        <div className='container flex flex-wrap items-center justify-between max-w-7xl'>
          <div className='order-2 mt-10 md:mt-0'>
            <img src={whiteLogo} alt='' height={30} width={140} />
            <div className='flex mt-4 text-sm divide-x whitespace-nowrap md:text-base'>
              <Link className='pr-1.5'>Privacy Policy</Link>
              <Link className='px-1.5'>Terms of Service</Link>
              <div className='pl-1.5'>&copy; 2021 Giftory</div>
            </div>
          </div>
          <div className='order-1 md:order-last'>
            <Link
              to='/signup'
              className='inline-block px-8 py-3 text-lg rounded-md bg-primary'
            >
              Sign up for free
            </Link>
            <Link
              to='/login'
              className='inline-block px-8 py-3 ml-3 text-lg bg-white rounded-md text-primary'
            >
              Log in
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
