import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import ReactGA from 'react-ga';
import mixpanel from 'mixpanel-browser';

const gaId = process.env.NODE_ENV === 'production' ? 'UA-162803451-1' : '';
ReactGA.initialize(gaId);

Sentry.init({
  dsn:
    'https://4da0252efcc641a79adb8ac5228d2515@o423520.ingest.sentry.io/5541834',
  environment: process.env.NODE_ENV,
  integrations: [new Integrations.BrowserTracing()],
});

const mixpanelId =
  process.env.NODE_ENV === 'production'
    ? '87ebe0685f90c772e87381a8c226900f'
    : '50d5acc99530949bcbb2ce9ea44ac82f';

mixpanel.init(mixpanelId, {
  debug: process.env.NODE_ENV !== 'production',
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
