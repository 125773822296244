import React from 'react';
import { useState } from 'react';
import Input from '../../components/Input';
import { ISettingsProps } from './index';
import { StatusType } from '../../components/Toasts/Status';
import { useUpdateProfileMutation } from '../../generated/graphql';
import { Button } from 'src/components/Button';

export default ({ setPage, setAlert, profile, setProfile }: ISettingsProps) => {
  const [birthday, setBirthday] = useState(profile.birthday);

  const [updateProfile, { loading }] = useUpdateProfileMutation({
    onCompleted: data => {
      setAlert({
        open: true,
        type: StatusType.SUCCESS,
        message: 'Birthday successfully updated',
      });
      setPage('settings');

      setProfile(data.updateProfile?.profile);
    },
    onError: () => {
      setAlert({
        open: true,
        type: StatusType.DANGER,
        message: 'There was an unexpected error setting your birthday.',
      });
    },
  });

  const onSubmit = e => {
    e.preventDefault();

    updateProfile({
      variables: {
        input: {
          profileId: profile.id,
          birthday: birthday,
        },
      },
    });
  };

  const isSaveDisabled = () => !birthday || loading;

  return (
    <form onSubmit={onSubmit} className='max-w-md space-y-2'>
      <h3 className='font-bold'>Change Birthday</h3>
      <Input
        type='date'
        value={birthday}
        onChange={e => setBirthday(e.target.value)}
      />
      <div className='space-x-2'>
        <Button
          className='w-20 py-2 text-white rounded-md bg-primary'
          disabled={isSaveDisabled()}
          loading={loading}
          type='submit'
        >
          Save
        </Button>
        <button
          type='button'
          className='text-gray-700'
          onClick={() => setPage('settings')}
        >
          Cancel
        </button>
      </div>
    </form>
  );
};
