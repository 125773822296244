import React from 'react';
import ReactGa from 'react-ga';
import { Button } from './Button';
import {
  FollowingProfileFragment,
  FollowMutationFn,
  UnfollowMutationFn,
  useFollowMutation,
  useUnfollowMutation,
} from '../generated/graphql';
import gql from 'graphql-tag';
import mixpanel from 'mixpanel-browser';

interface FollowingProps {
  profile: FollowingProfileFragment;
  targetProfile?: FollowingProfileFragment;
  following: boolean;
  className?: string;
}

const Following = ({
  profile,
  targetProfile = profile,
  following,
  className,
}: FollowingProps) => {
  const { id } = profile;
  // const { slug } = useParams();
  const [follow] = useFollowMutation({
    optimisticResponse: {
      followProfile: {
        profile: {
          id: targetProfile.id,
          slug: targetProfile.slug,
          viewerIsFollowing: true,
          __typename: 'Profile',
        },
        __typename: 'FollowProfilePayload',
      },
    },
  });
  const [unfollow] = useUnfollowMutation({
    optimisticResponse: {
      unfollowProfile: {
        profile: {
          id: targetProfile.id,
          slug: targetProfile.slug,
          viewerIsFollowing: false,
          __typename: 'Profile',
        },
        __typename: 'UnfollowProfilePayload',
      },
    },
  });
  // const { viewer } = client.readQuery({ query: INITIAL_INFO });

  let text = 'Follow';
  let cls = 'bg-primary text-white';
  let iconCls = 'fas fa-plus transition-transform duration-300';
  let fn: FollowMutationFn | UnfollowMutationFn = follow;

  if (following) {
    text = 'Unfollow';
    cls = 'bg-white text-black border';
    iconCls += ' transform rotate-45';
    fn = unfollow;
  }

  const toggleFollow = e => {
    e.stopPropagation();
    fn({
      variables: {
        input: {
          profileId: id,
        },
      },
      // update: () => {
      //   // Only manually update if the user is on their own profile and clicking a profile that is not their own
      //   if (
      //     slug === viewer.profile.slug &&
      //     profile.slug !== viewer.profile.slug
      //   ) {
      //     const data = client.readQuery({
      //       query: EVERYTHING,
      //       variables: { input: { slug } },
      //     });

      //     const newData = JSON.parse(JSON.stringify(data));
      //     const count = newData.profile.user.following.total;
      //     newData.profile.user.following.total = following
      //       ? count - 1
      //       : count + 1;

      //     client.writeQuery({ query: EVERYTHING, data: newData });
      //   }
      // },
    });

    ReactGa.event({
      category: 'Social',
      action: following ? 'Unfollow User' : 'Follow User',
    });

    mixpanel.track(following ? 'Unfollow User' : 'Follow User');
  };

  return (
    <Button
      className={`transition-colors duration-300 w-28 ${cls} ${className}`}
      onClick={toggleFollow}
      tabIndex={-1}
    >
      <span className='mr-1'>
        <i className={iconCls}></i>
      </span>{' '}
      {text}
    </Button>
  );
};

Following.fragments = {
  followingProfile: gql`
    fragment FollowingProfile on Profile {
      id
      slug
      viewerIsFollowing
    }
  `,
};

gql`
  mutation follow($input: FollowProfileInput!) {
    followProfile(input: $input) {
      profile {
        ...FollowingProfile
      }
    }
  }
  ${Following.fragments.followingProfile}
`;

gql`
  mutation unfollow($input: UnfollowProfileInput!) {
    unfollowProfile(input: $input) {
      profile {
        ...FollowingProfile
      }
    }
  }
  ${Following.fragments.followingProfile}
`;

export default Following;
