import React from 'react';
import { useLocation } from 'react-router-dom';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default () => {
  const query = useQuery();
  const referrer = query.get('r');

  return (
    <a
      href={
        !referrer
          ? '/login/google'
          : `/login/google?r=${encodeURIComponent(referrer)}`
      }
      alt=''
      className='inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50'
    >
      <svg
        width='20'
        height='21'
        viewBox='0 0 20 21'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g clipPath='url(#clip0)'>
          <path
            d='M19.9896 10.4248C19.9896 9.58627 19.9215 8.97438 19.7743 8.33984H10.1993V12.1244H15.8196C15.7064 13.065 15.0945 14.4814 13.7347 15.4331L13.7156 15.5598L16.743 17.9051L16.9528 17.9261C18.8791 16.147 19.9896 13.5295 19.9896 10.4248Z'
            fill='#6B7280'
          />
          <path
            d='M10.1993 20.3964C12.9528 20.3964 15.2644 19.4899 16.9528 17.9262L13.7347 15.4332C12.8735 16.0338 11.7177 16.453 10.1993 16.453C7.50247 16.453 5.21355 14.6741 4.39761 12.2151L4.27801 12.2253L1.13004 14.6616L1.08887 14.776C2.76589 18.1074 6.21064 20.3964 10.1993 20.3964Z'
            fill='#6B7280'
          />
          <path
            d='M4.39742 12.2152C4.18213 11.5806 4.05753 10.9007 4.05753 10.1982C4.05753 9.49559 4.18213 8.81574 4.38609 8.18119L4.38039 8.04605L1.19298 5.57068L1.08869 5.62028C0.397515 7.00272 0.000915527 8.55514 0.000915527 10.1982C0.000915527 11.8412 0.397515 13.3935 1.08869 14.776L4.39742 12.2152Z'
            fill='#6B7280'
          />
          <path
            d='M10.1993 3.94325C12.1142 3.94325 13.406 4.77043 14.1425 5.46168L17.0207 2.65151C15.253 1.00849 12.9527 0 10.1993 0C6.2106 0 2.76588 2.2889 1.08887 5.62026L4.38626 8.18116C5.21352 5.72228 7.50242 3.94325 10.1993 3.94325Z'
            fill='#6B7280'
          />
        </g>
        <defs>
          <clipPath id='clip0'>
            <rect width='20' height='20.4666' fill='white' />
          </clipPath>
        </defs>
      </svg>
    </a>
  );
};
