import React, { useState } from 'react';
import { arrayBufferToBase64 } from '../../lib/utils';
import { useImageUpload } from '../../lib/hooks';
import { Spinner } from '../../components/Loading';
import {
  useGetProfileCollectionSettingsQuery,
  useUpdateCollectionMutation,
} from '../../generated/graphql';
import gql from 'graphql-tag';
import { useParams } from 'react-router-dom';
import { Image } from '../Item/Image';

export default ({ setPage }) => {
  const [imageData, setImageData] = useState<any>({
    data: '',
    buffer: null,
  });
  const imageUploadPromise = useImageUpload(imageData.buffer);
  const { slug, collectionSlug } = useParams();
  const { data } = useGetProfileCollectionSettingsQuery({
    fetchPolicy: 'cache-only',
    variables: {
      input: {
        profileSlug: slug,
        collectionSlug,
      },
    },
  });

  const [updateCollection, { loading }] = useUpdateCollectionMutation({
    onCompleted: () => {
      // setAlert({
      //   open: true,
      //   type: StatusType.SUCCESS,
      //   message: 'Profile image successfully updated',
      // });
      setPage('settings');
    },
    onError: () => {
      // setAlert({
      //   open: true,
      //   type: StatusType.DANGER,
      //   message: 'There was an unexpected error changing your profile image.',
      // });
    },
  });

  const readImage = blob => {
    const reader = new FileReader();

    reader.readAsArrayBuffer(blob);
    // reader.readAsDataURL(blob);
    reader.onloadend = function() {
      var res = reader.result;
      console.log('update data');

      setImageData({
        data: `data:image/png;base64,${arrayBufferToBase64(res)}`,
        buffer: res,
      });
    };
  };

  const onUpload = e => {
    const file = e.target.files[0];
    readImage(file);
  };

  const onSubmit = e => {
    e.preventDefault();

    imageUploadPromise.then(imageToken => {
      updateCollection({
        variables: {
          input: {
            collectionId: data?.collection?.id ?? '',
            title: data?.collection?.title ?? '',
            imageToken,
          },
        },
      });
    });
  };

  const isSaveDisabled = () => loading;

  return (
    <form onSubmit={onSubmit} className='space-y-2'>
      <h3 className='font-bold'>Change Profile Image</h3>
      <div className='flex items-center'>
        <div className='h-48 mt-2 overflow-hidden w-96 rounded-2xl'>
          {imageData.data ? (
            <img
              className={`h-full w-full object-cover`}
              src={imageData.data}
              alt=''
            />
          ) : (
            <Image
              className={`h-full w-full object-cover`}
              image={data?.collection?.image ?? null}
              item={data?.collection ?? { id: '' }}
            />
          )}
        </div>
        <label className='px-4 font-bold cursor-pointer text-primary'>
          <input type='file' accept='image/*' onChange={onUpload} />
          <i className='fas fa-upload mr-1'></i> Upload Image
        </label>
      </div>
      <div className='space-x-2'>
        <button
          className='px-8 py-2 text-white rounded-md bg-primary'
          disabled={isSaveDisabled()}
        >
          {loading ? <Spinner /> : 'Save'}
        </button>
        <button
          type='button'
          className='text-gray-700'
          onClick={() => setPage('settings')}
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

gql`
  mutation updateCollection($input: UpdateListItemCollectionInput!) {
    updateListItemCollection(input: $input) {
      collection {
        id
        title
        slug
        image {
          ...ItemImage
        }
      }
      error {
        code
        message
      }
    }
  }
  ${Image.fragments.image}
`;
