import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A special custom Scalar type for Dates that converts to a ISO formatted string  */
  Date: any;
  GraphQLDate: any;
};

export type ChangePasswordInput = {
  current: Scalars['String'];
  target: Scalars['String'];
};

export type ChangePasswordPayload = {
  __typename?: 'ChangePasswordPayload';
  user?: Maybe<User>;
  error?: Maybe<MutationError>;
};

export type CollectionSearchInput = {
  profileSlug: Scalars['String'];
  collectionSlug: Scalars['String'];
};

export type CopyListItemInput = {
  profileId: Scalars['ID'];
  itemId: Scalars['ID'];
};

export type CopyListItemPayload = {
  __typename?: 'CopyListItemPayload';
  listItem: ListItem;
};

export type CreateAccountError = {
  __typename?: 'CreateAccountError';
  error: CreateAccountErrorCode;
};

export enum CreateAccountErrorCode {
  DuplicateEmail = 'DuplicateEmail'
}

export type CreateAccountInput = {
  name: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
};

export type CreateAccountPayload = {
  __typename?: 'CreateAccountPayload';
  profile: Profile;
};

export type CreateAccountResult = CreateAccountError | CreateAccountPayload;

export type CreateListItemCollectionInput = {
  profileId: Scalars['ID'];
  title: Scalars['String'];
  imageToken?: Maybe<Scalars['String']>;
};

export type CreateListItemCollectionPayload = {
  __typename?: 'CreateListItemCollectionPayload';
  error?: Maybe<MutationError>;
  collection?: Maybe<ListItemCollection>;
};

export type CreateListItemCommentInput = {
  itemId: Scalars['ID'];
  anonymous: Scalars['Boolean'];
  content: Scalars['String'];
};

export type CreateListItemCommentPayload = {
  __typename?: 'CreateListItemCommentPayload';
  comment: ListItemComment;
};

export type CreateListItemInput = {
  profileId: Scalars['ID'];
  raw?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  imageUrl?: Maybe<Scalars['String']>;
  imageToken?: Maybe<Scalars['String']>;
  collections?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type CreateListItemPayload = {
  __typename?: 'CreateListItemPayload';
  profile: Profile;
  listItem: ListItem;
};

export type CreateProfileInput = {
  name: Scalars['String'];
};

export type CreateProfilePayload = {
  __typename?: 'CreateProfilePayload';
  profile: Profile;
};


export type DestroyListItemCollectionInput = {
  collectionId: Scalars['ID'];
};

export type DestroyListItemCollectionPayload = {
  __typename?: 'DestroyListItemCollectionPayload';
  error?: Maybe<MutationError>;
};

export type DestroyListItemCommentInput = {
  commentId: Scalars['ID'];
};

export type DestroyListItemCommentPayload = {
  __typename?: 'DestroyListItemCommentPayload';
  comment: ListItemComment;
};

export type DestroyListItemInput = {
  itemId: Scalars['ID'];
};

export type DestroyListItemPayload = {
  __typename?: 'DestroyListItemPayload';
  profile: Profile;
};

export type Feed = {
  __typename?: 'Feed';
  explore?: Maybe<ListItemFeedTypeConnection>;
  viewer?: Maybe<ListItemFeedTypeConnection>;
  generic?: Maybe<GenericFeed>;
  tailored?: Maybe<TailoredFeed>;
};


export type FeedExploreArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<ListItemFeedOrderBy>>>;
};


export type FeedViewerArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<ListItemFeedOrderBy>>>;
};

export type FollowProfileInput = {
  profileId: Scalars['ID'];
};

export type FollowProfilePayload = {
  __typename?: 'FollowProfilePayload';
  profile: Profile;
};

export type ForgotPasswordInput = {
  email: Scalars['String'];
};

export type ForgotPasswordPayload = {
  __typename?: 'ForgotPasswordPayload';
  error?: Maybe<MutationError>;
};

export type GenericFeed = HasListItems & {
  __typename?: 'GenericFeed';
  listItems?: Maybe<ListItemsConnection>;
};


export type GenericFeedListItemsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<ListItemsOrderBy>>>;
  image?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Array<Maybe<ListItemsConnectionFilter>>>;
};


export type HasListItems = {
  listItems?: Maybe<ListItemsConnection>;
};


export type HasListItemsListItemsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<ListItemsOrderBy>>>;
  image?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Array<Maybe<ListItemsConnectionFilter>>>;
};

export type Image = {
  __typename?: 'Image';
  /** The ID of an object */
  id: Scalars['ID'];
  format: Scalars['String'];
  width: Scalars['Int'];
  height: Scalars['Int'];
  source?: Maybe<Scalars['String']>;
  pixelated?: Maybe<Scalars['String']>;
  min?: Maybe<Scalars['String']>;
  max?: Maybe<Scalars['String']>;
  fixed?: Maybe<Scalars['String']>;
};


export type ImageMinArgs = {
  input: ImageMinInput;
};


export type ImageMaxArgs = {
  input: ImageMaxInput;
};


export type ImageFixedArgs = {
  input: ImageFixedInput;
};

export type ImageFixedInput = {
  ratio: Scalars['Float'];
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
};

export type ImageMaxInput = {
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
};

export type ImageMinInput = {
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
};

export type InviteFriendInput = {
  email: Scalars['String'];
};

export type InviteFriendPayload = {
  __typename?: 'InviteFriendPayload';
  error?: Maybe<MutationError>;
};

export type ItemSearchInput = {
  item: Scalars['String'];
};

export type LinkAffiliate = {
  __typename?: 'LinkAffiliate';
  network: LinkAffiliateNetwork;
};

export enum LinkAffiliateNetwork {
  Amazon = 'AMAZON'
}

export type ListItem = {
  __typename?: 'ListItem';
  /** The ID of an object */
  id: Scalars['ID'];
  url?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  quantity: Scalars['Int'];
  slug?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['Date'];
  profile: Profile;
  link?: Maybe<Scalars['String']>;
  affiliate?: Maybe<LinkAffiliate>;
  image?: Maybe<Image>;
  viewerHasReservedQuantity: Scalars['Int'];
  reserved: Scalars['Int'];
  comments?: Maybe<ListItemCommentsTypeConnection>;
  collections?: Maybe<ListItemsCollectionsConnection>;
};


export type ListItemCommentsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
};


export type ListItemCollectionsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
};

export type ListItemCollection = HasListItems & {
  __typename?: 'ListItemCollection';
  /** The ID of an object */
  id: Scalars['ID'];
  title: Scalars['String'];
  slug: Scalars['String'];
  profile: Profile;
  listItems?: Maybe<ListItemsConnection>;
  image?: Maybe<Image>;
};


export type ListItemCollectionListItemsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<ListItemsOrderBy>>>;
  image?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Array<Maybe<ListItemsConnectionFilter>>>;
};

export type ListItemComment = {
  __typename?: 'ListItemComment';
  /** The ID of an object */
  id: Scalars['ID'];
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
  user?: Maybe<User>;
  content?: Maybe<Scalars['String']>;
  viewerCanEditComment: Scalars['Boolean'];
  viewerCanDeleteComment: Scalars['Boolean'];
  isEdited: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
};

/** A connection to a list of items. */
export type ListItemCommentsTypeConnection = {
  __typename?: 'ListItemCommentsTypeConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ListItemCommentsTypeEdge>>>;
  total: Scalars['Int'];
};

/** An edge in a connection. */
export type ListItemCommentsTypeEdge = {
  __typename?: 'ListItemCommentsTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<ListItemComment>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export enum ListItemFeedOrderBy {
  Age = 'AGE'
}

/** A connection to a list of items. */
export type ListItemFeedTypeConnection = {
  __typename?: 'ListItemFeedTypeConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ListItemFeedTypeEdge>>>;
};

/** An edge in a connection. */
export type ListItemFeedTypeEdge = {
  __typename?: 'ListItemFeedTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<ListItem>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** A connection to a list of items. */
export type ListItemsCollectionsConnection = {
  __typename?: 'ListItemsCollectionsConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ListItemsCollectionsEdge>>>;
  total: Scalars['Int'];
};

/** An edge in a connection. */
export type ListItemsCollectionsEdge = {
  __typename?: 'ListItemsCollectionsEdge';
  /** The item at the end of the edge */
  node?: Maybe<ListItemCollection>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** A connection to a list of items. */
export type ListItemsConnection = {
  __typename?: 'ListItemsConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ListItemsEdge>>>;
  total: Scalars['Int'];
};

export enum ListItemsConnectionFilter {
  Image = 'IMAGE'
}

/** An edge in a connection. */
export type ListItemsEdge = {
  __typename?: 'ListItemsEdge';
  /** The item at the end of the edge */
  node?: Maybe<ListItem>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export enum ListItemsOrderBy {
  Age = 'AGE',
  Updated = 'UPDATED',
  Title = 'TITLE'
}

export type Mutation = {
  __typename?: 'Mutation';
  createAccount?: Maybe<CreateAccountResult>;
  changePassword?: Maybe<ChangePasswordPayload>;
  updateProfile?: Maybe<UpdateProfilePayload>;
  forgotPassword?: Maybe<ForgotPasswordPayload>;
  generateSignedUpload?: Maybe<SignedUploadPayload>;
  scrapeUrl?: Maybe<ScrapeUrlPayload>;
  createListItem?: Maybe<CreateListItemPayload>;
  updateListItem?: Maybe<UpdateListItemPayload>;
  destroyListItem?: Maybe<DestroyListItemPayload>;
  copyListItem?: Maybe<CopyListItemPayload>;
  createListItemComment?: Maybe<CreateListItemCommentPayload>;
  updateListItemComment?: Maybe<UpdateListItemCommentPayload>;
  destroyListItemComment?: Maybe<DestroyListItemCommentPayload>;
  createListItemCollection?: Maybe<CreateListItemCollectionPayload>;
  updateListItemCollection?: Maybe<UpdateListItemCollectionPayload>;
  destroyListItemCollection?: Maybe<DestroyListItemCollectionPayload>;
  followProfile?: Maybe<FollowProfilePayload>;
  unfollowProfile?: Maybe<UnfollowProfilePayload>;
  reserveListItem?: Maybe<ReserveListItemPayload>;
  createProfile?: Maybe<CreateProfilePayload>;
  inviteFriend?: Maybe<InviteFriendPayload>;
};


export type MutationCreateAccountArgs = {
  input: CreateAccountInput;
};


export type MutationChangePasswordArgs = {
  input: ChangePasswordInput;
};


export type MutationUpdateProfileArgs = {
  input: UpdateProfileInput;
};


export type MutationForgotPasswordArgs = {
  input: ForgotPasswordInput;
};


export type MutationGenerateSignedUploadArgs = {
  input: SignedUploadInput;
};


export type MutationScrapeUrlArgs = {
  input: ScrapeUrlInput;
};


export type MutationCreateListItemArgs = {
  input: CreateListItemInput;
};


export type MutationUpdateListItemArgs = {
  input: UpdateListItemInput;
};


export type MutationDestroyListItemArgs = {
  input: DestroyListItemInput;
};


export type MutationCopyListItemArgs = {
  input: CopyListItemInput;
};


export type MutationCreateListItemCommentArgs = {
  input: CreateListItemCommentInput;
};


export type MutationUpdateListItemCommentArgs = {
  input: UpdateListItemCommentInput;
};


export type MutationDestroyListItemCommentArgs = {
  input: DestroyListItemCommentInput;
};


export type MutationCreateListItemCollectionArgs = {
  input: CreateListItemCollectionInput;
};


export type MutationUpdateListItemCollectionArgs = {
  input: UpdateListItemCollectionInput;
};


export type MutationDestroyListItemCollectionArgs = {
  input: DestroyListItemCollectionInput;
};


export type MutationFollowProfileArgs = {
  input: FollowProfileInput;
};


export type MutationUnfollowProfileArgs = {
  input: UnfollowProfileInput;
};


export type MutationReserveListItemArgs = {
  input: ReserveListItemInput;
};


export type MutationCreateProfileArgs = {
  input: CreateProfileInput;
};


export type MutationInviteFriendArgs = {
  input: InviteFriendInput;
};

export type MutationError = {
  __typename?: 'MutationError';
  code: Scalars['String'];
  message?: Maybe<Scalars['String']>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
};

export type Profile = HasListItems & {
  __typename?: 'Profile';
  /** The ID of an object */
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  birthday?: Maybe<Scalars['GraphQLDate']>;
  bio?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
  user?: Maybe<User>;
  listUpdatedAt: Scalars['Date'];
  url: Scalars['String'];
  viewerCanEditList: Scalars['Boolean'];
  viewerIsFollowing: Scalars['Boolean'];
  listItems?: Maybe<ListItemsConnection>;
  archived?: Maybe<ProfileArchived>;
  collections?: Maybe<ProfileListItemCollectionsConnection>;
  followers?: Maybe<ProfileFollowerTypeConnection>;
  image?: Maybe<Image>;
  uncollectedListItems?: Maybe<ListItemsConnection>;
  uncollected?: Maybe<ProfileUncollection>;
};


export type ProfileListItemsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<ListItemsOrderBy>>>;
  image?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Array<Maybe<ListItemsConnectionFilter>>>;
};


export type ProfileCollectionsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<ProfileListItemCollectionsOrderBy>>>;
};


export type ProfileFollowersArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
};


export type ProfileUncollectedListItemsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<ListItemsOrderBy>>>;
  image?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Array<Maybe<ListItemsConnectionFilter>>>;
};

export type ProfileArchived = HasListItems & {
  __typename?: 'ProfileArchived';
  listItems?: Maybe<ListItemsConnection>;
};


export type ProfileArchivedListItemsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<ListItemsOrderBy>>>;
  image?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Array<Maybe<ListItemsConnectionFilter>>>;
};

/** A connection to a list of items. */
export type ProfileFollowerTypeConnection = {
  __typename?: 'ProfileFollowerTypeConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ProfileFollowerTypeEdge>>>;
  total: Scalars['Int'];
};

/** An edge in a connection. */
export type ProfileFollowerTypeEdge = {
  __typename?: 'ProfileFollowerTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<User>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** A connection to a list of items. */
export type ProfileListItemCollectionsConnection = {
  __typename?: 'ProfileListItemCollectionsConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ProfileListItemCollectionsEdge>>>;
  total: Scalars['Int'];
};

/** An edge in a connection. */
export type ProfileListItemCollectionsEdge = {
  __typename?: 'ProfileListItemCollectionsEdge';
  /** The item at the end of the edge */
  node?: Maybe<ListItemCollection>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export enum ProfileListItemCollectionsOrderBy {
  Title = 'TITLE',
  Age = 'AGE'
}

export type ProfileSearchInput = {
  slug: Scalars['String'];
};

export type ProfileUncollection = HasListItems & {
  __typename?: 'ProfileUncollection';
  listItems?: Maybe<ListItemsConnection>;
};


export type ProfileUncollectionListItemsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<ListItemsOrderBy>>>;
  image?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Array<Maybe<ListItemsConnectionFilter>>>;
};

/** A connection to a list of items. */
export type ProxyProfilesConnection = {
  __typename?: 'ProxyProfilesConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ProxyProfilesEdge>>>;
  total: Scalars['Int'];
};

/** An edge in a connection. */
export type ProxyProfilesEdge = {
  __typename?: 'ProxyProfilesEdge';
  /** The item at the end of the edge */
  node?: Maybe<Profile>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** A connection to a list of items. */
export type PublicProfilesConnection = {
  __typename?: 'PublicProfilesConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PublicProfilesEdge>>>;
};

/** An edge in a connection. */
export type PublicProfilesEdge = {
  __typename?: 'PublicProfilesEdge';
  /** The item at the end of the edge */
  node?: Maybe<Profile>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export enum PublicProfilesOrderBy {
  Age = 'AGE',
  Name = 'NAME',
  Updated = 'UPDATED'
}

export type Query = {
  __typename?: 'Query';
  collection?: Maybe<ListItemCollection>;
  feed: Feed;
  item?: Maybe<ListItem>;
  itemLayout: Scalars['String'];
  itemSort: Scalars['String'];
  profile?: Maybe<Profile>;
  profiles?: Maybe<PublicProfilesConnection>;
  viewer: Viewer;
};


export type QueryCollectionArgs = {
  input: CollectionSearchInput;
};


export type QueryItemArgs = {
  input: ItemSearchInput;
};


export type QueryProfileArgs = {
  input: ProfileSearchInput;
};


export type QueryProfilesArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<PublicProfilesOrderBy>>>;
  search?: Maybe<Scalars['String']>;
};

export type ReserveListItemInput = {
  listItemId: Scalars['ID'];
  quantity: Scalars['Int'];
};

export type ReserveListItemPayload = {
  __typename?: 'ReserveListItemPayload';
  listItem: ListItem;
};

export type ScrapeUrlInput = {
  url?: Maybe<Scalars['String']>;
  raw?: Maybe<Scalars['String']>;
  timeout?: Maybe<Scalars['Int']>;
};

export type ScrapeUrlPayload = {
  __typename?: 'ScrapeUrlPayload';
  url: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  image?: Maybe<Image>;
};

export type SignedUploadInput = {
  kind: SignedUploadKindEnum;
};

export enum SignedUploadKindEnum {
  Image = 'IMAGE'
}

export type SignedUploadPayload = {
  __typename?: 'SignedUploadPayload';
  error?: Maybe<MutationError>;
  signedUrl: Scalars['String'];
  completionToken: Scalars['String'];
};

export type TailoredFeed = HasListItems & {
  __typename?: 'TailoredFeed';
  listItems?: Maybe<ListItemsConnection>;
};


export type TailoredFeedListItemsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<ListItemsOrderBy>>>;
  image?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Array<Maybe<ListItemsConnectionFilter>>>;
};

export type UnfollowProfileInput = {
  profileId: Scalars['ID'];
};

export type UnfollowProfilePayload = {
  __typename?: 'UnfollowProfilePayload';
  profile: Profile;
};

export type UpdateListItemCollectionInput = {
  collectionId: Scalars['ID'];
  title: Scalars['String'];
  imageToken?: Maybe<Scalars['String']>;
};

export type UpdateListItemCollectionPayload = {
  __typename?: 'UpdateListItemCollectionPayload';
  error?: Maybe<MutationError>;
  collection: ListItemCollection;
};

export type UpdateListItemCommentInput = {
  commentId: Scalars['ID'];
  anonymous: Scalars['Boolean'];
  content: Scalars['String'];
};

export type UpdateListItemCommentPayload = {
  __typename?: 'UpdateListItemCommentPayload';
  comment: ListItemComment;
};

export type UpdateListItemInput = {
  itemId: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  imageToken?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  collections?: Maybe<Array<Maybe<Scalars['ID']>>>;
  isArchived?: Maybe<Scalars['Boolean']>;
  notes?: Maybe<Scalars['String']>;
};

export type UpdateListItemPayload = {
  __typename?: 'UpdateListItemPayload';
  profile: Profile;
  listItem: ListItem;
};

export type UpdateProfileInput = {
  profileId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['GraphQLDate']>;
  imageToken?: Maybe<Scalars['String']>;
};

export type UpdateProfilePayload = {
  __typename?: 'UpdateProfilePayload';
  error?: Maybe<MutationError>;
  profile?: Maybe<Profile>;
};

export type User = {
  __typename?: 'User';
  /** The ID of an object */
  id: Scalars['ID'];
  profile: Profile;
  following?: Maybe<UserFollowingTypeConnection>;
  proxy_profiles?: Maybe<ProxyProfilesConnection>;
};


export type UserFollowingArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<UserFollowingOrderBy>>>;
};


export type UserProxy_ProfilesArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
};

export enum UserFollowingOrderBy {
  Age = 'AGE',
  Name = 'NAME',
  Updated = 'UPDATED'
}

/** A connection to a list of items. */
export type UserFollowingTypeConnection = {
  __typename?: 'UserFollowingTypeConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UserFollowingTypeEdge>>>;
  total: Scalars['Int'];
};

/** An edge in a connection. */
export type UserFollowingTypeEdge = {
  __typename?: 'UserFollowingTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<Profile>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type Viewer = {
  __typename?: 'Viewer';
  isAuthenticated: Scalars['Boolean'];
  user?: Maybe<User>;
  profile?: Maybe<Profile>;
};

export type ScrapeUrlMutationVariables = Exact<{
  input: ScrapeUrlInput;
}>;


export type ScrapeUrlMutation = (
  { __typename?: 'Mutation' }
  & { scrapeUrl?: Maybe<(
    { __typename?: 'ScrapeUrlPayload' }
    & Pick<ScrapeUrlPayload, 'url' | 'title'>
    & { image?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'height' | 'width'>
      & { tiny: Image['pixelated'], source: Image['max'] }
    )> }
  )> }
);

export type GetUserProfilesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserProfilesQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { user?: Maybe<(
      { __typename?: 'User' }
      & { profile: (
        { __typename?: 'Profile' }
        & Pick<Profile, 'id' | 'name' | 'slug'>
        & { collections?: Maybe<(
          { __typename?: 'ProfileListItemCollectionsConnection' }
          & { edges?: Maybe<Array<Maybe<(
            { __typename?: 'ProfileListItemCollectionsEdge' }
            & { node?: Maybe<(
              { __typename?: 'ListItemCollection' }
              & Pick<ListItemCollection, 'id' | 'slug' | 'title'>
            )> }
          )>>> }
        )> }
        & ProfileImageFragment
      ), proxy_profiles?: Maybe<(
        { __typename?: 'ProxyProfilesConnection' }
        & { edges?: Maybe<Array<Maybe<(
          { __typename?: 'ProxyProfilesEdge' }
          & { node?: Maybe<(
            { __typename?: 'Profile' }
            & Pick<Profile, 'id' | 'name' | 'slug'>
            & { collections?: Maybe<(
              { __typename?: 'ProfileListItemCollectionsConnection' }
              & { edges?: Maybe<Array<Maybe<(
                { __typename?: 'ProfileListItemCollectionsEdge' }
                & { node?: Maybe<(
                  { __typename?: 'ListItemCollection' }
                  & Pick<ListItemCollection, 'id' | 'slug' | 'title'>
                )> }
              )>>> }
            )> }
            & ProfileImageFragment
          )> }
        )>>> }
      )> }
    )> }
  ) }
);

export type AddItemMutationVariables = Exact<{
  input: CreateListItemInput;
}>;


export type AddItemMutation = (
  { __typename?: 'Mutation' }
  & { createListItem?: Maybe<(
    { __typename?: 'CreateListItemPayload' }
    & { profile: (
      { __typename?: 'Profile' }
      & Pick<Profile, 'id' | 'slug'>
    ), listItem: (
      { __typename?: 'ListItem' }
      & ItemThumbnailFragment
    ) }
  )> }
);

export type CreateCollectionMutationVariables = Exact<{
  input: CreateListItemCollectionInput;
}>;


export type CreateCollectionMutation = (
  { __typename?: 'Mutation' }
  & { createListItemCollection?: Maybe<(
    { __typename?: 'CreateListItemCollectionPayload' }
    & { collection?: Maybe<(
      { __typename?: 'ListItemCollection' }
      & Pick<ListItemCollection, 'id' | 'slug' | 'title'>
    )>, error?: Maybe<(
      { __typename?: 'MutationError' }
      & Pick<MutationError, 'code' | 'message'>
    )> }
  )> }
);

export type EditCollectionNameMutationVariables = Exact<{
  input: UpdateListItemCollectionInput;
}>;


export type EditCollectionNameMutation = (
  { __typename?: 'Mutation' }
  & { updateListItemCollection?: Maybe<(
    { __typename?: 'UpdateListItemCollectionPayload' }
    & { collection: (
      { __typename?: 'ListItemCollection' }
      & Pick<ListItemCollection, 'id' | 'title' | 'slug'>
    ), error?: Maybe<(
      { __typename?: 'MutationError' }
      & Pick<MutationError, 'code' | 'message'>
    )> }
  )> }
);

export type UpdateCollectionMutationVariables = Exact<{
  input: UpdateListItemCollectionInput;
}>;


export type UpdateCollectionMutation = (
  { __typename?: 'Mutation' }
  & { updateListItemCollection?: Maybe<(
    { __typename?: 'UpdateListItemCollectionPayload' }
    & { collection: (
      { __typename?: 'ListItemCollection' }
      & Pick<ListItemCollection, 'id' | 'title' | 'slug'>
      & { image?: Maybe<(
        { __typename?: 'Image' }
        & ItemImageFragment
      )> }
    ), error?: Maybe<(
      { __typename?: 'MutationError' }
      & Pick<MutationError, 'code' | 'message'>
    )> }
  )> }
);

export type GetProfileCollectionSettingsQueryVariables = Exact<{
  input: CollectionSearchInput;
}>;


export type GetProfileCollectionSettingsQuery = (
  { __typename?: 'Query' }
  & { collection?: Maybe<(
    { __typename?: 'ListItemCollection' }
    & Pick<ListItemCollection, 'id' | 'title' | 'slug'>
    & { image?: Maybe<(
      { __typename?: 'Image' }
      & ItemImageFragment
    )> }
  )> }
);

export type DeleteCollectionMutationVariables = Exact<{
  input: DestroyListItemCollectionInput;
}>;


export type DeleteCollectionMutation = (
  { __typename?: 'Mutation' }
  & { destroyListItemCollection?: Maybe<(
    { __typename?: 'DestroyListItemCollectionPayload' }
    & { error?: Maybe<(
      { __typename?: 'MutationError' }
      & Pick<MutationError, 'code' | 'message'>
    )> }
  )> }
);

export type FollowingProfileFragment = (
  { __typename?: 'Profile' }
  & Pick<Profile, 'id' | 'slug' | 'viewerIsFollowing'>
);

export type FollowMutationVariables = Exact<{
  input: FollowProfileInput;
}>;


export type FollowMutation = (
  { __typename?: 'Mutation' }
  & { followProfile?: Maybe<(
    { __typename?: 'FollowProfilePayload' }
    & { profile: (
      { __typename?: 'Profile' }
      & FollowingProfileFragment
    ) }
  )> }
);

export type UnfollowMutationVariables = Exact<{
  input: UnfollowProfileInput;
}>;


export type UnfollowMutation = (
  { __typename?: 'Mutation' }
  & { unfollowProfile?: Maybe<(
    { __typename?: 'UnfollowProfilePayload' }
    & { profile: (
      { __typename?: 'Profile' }
      & FollowingProfileFragment
    ) }
  )> }
);

export type CollectionTitleFragment = (
  { __typename?: 'Profile' }
  & { collections?: Maybe<(
    { __typename?: 'ProfileListItemCollectionsConnection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'ProfileListItemCollectionsEdge' }
      & { node?: Maybe<(
        { __typename?: 'ListItemCollection' }
        & Pick<ListItemCollection, 'id' | 'title'>
      )> }
    )>>> }
  )> }
);

export type ForgotPasswordMutationVariables = Exact<{
  input: ForgotPasswordInput;
}>;


export type ForgotPasswordMutation = (
  { __typename?: 'Mutation' }
  & { forgotPassword?: Maybe<(
    { __typename?: 'ForgotPasswordPayload' }
    & { error?: Maybe<(
      { __typename?: 'MutationError' }
      & Pick<MutationError, 'code' | 'message'>
    )> }
  )> }
);

export type InviteFriendMutationVariables = Exact<{
  input: InviteFriendInput;
}>;


export type InviteFriendMutation = (
  { __typename?: 'Mutation' }
  & { inviteFriend?: Maybe<(
    { __typename?: 'InviteFriendPayload' }
    & { error?: Maybe<(
      { __typename?: 'MutationError' }
      & Pick<MutationError, 'code' | 'message'>
    )> }
  )> }
);

export type ItemCommentFragment = (
  { __typename: 'ListItemComment' }
  & Pick<ListItemComment, 'content' | 'createdAt'>
  & { user?: Maybe<(
    { __typename?: 'User' }
    & { profile: (
      { __typename?: 'Profile' }
      & Pick<Profile, 'id' | 'name' | 'slug'>
      & ProfileThumbnailFragment
    ) }
  )> }
);

export type ItemCommentsFragment = (
  { __typename?: 'ListItem' }
  & { comments?: Maybe<(
    { __typename?: 'ListItemCommentsTypeConnection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'ListItemCommentsTypeEdge' }
      & { node?: Maybe<(
        { __typename?: 'ListItemComment' }
        & ItemCommentFragment
      )> }
    )>>> }
  )> }
);

export type CreateCommentMutationVariables = Exact<{
  input: CreateListItemCommentInput;
}>;


export type CreateCommentMutation = (
  { __typename?: 'Mutation' }
  & { createListItemComment?: Maybe<(
    { __typename?: 'CreateListItemCommentPayload' }
    & { comment: (
      { __typename?: 'ListItemComment' }
      & ItemCommentFragment
    ) }
  )> }
);

export type RemoveItemMutationVariables = Exact<{
  input: DestroyListItemInput;
}>;


export type RemoveItemMutation = (
  { __typename?: 'Mutation' }
  & { destroyListItem?: Maybe<(
    { __typename?: 'DestroyListItemPayload' }
    & { profile: (
      { __typename?: 'Profile' }
      & Pick<Profile, 'id' | 'slug'>
      & { listItems?: Maybe<(
        { __typename?: 'ListItemsConnection' }
        & Pick<ListItemsConnection, 'total'>
      )> }
    ) }
  )> }
);

export type ItemImageFragment = (
  { __typename?: 'Image' }
  & Pick<Image, 'fixed' | 'height' | 'width'>
  & { tiny: Image['pixelated'], small: Image['max'], large: Image['max'] }
);

export type ItemSourceImageFragment = (
  { __typename?: 'Image' }
  & Pick<Image, 'height' | 'width'>
  & { tiny: Image['pixelated'], source: Image['max'] }
);

export type ItemReservedFragment = (
  { __typename?: 'ListItem' }
  & Pick<ListItem, 'id' | 'quantity' | 'viewerHasReservedQuantity' | 'reserved'>
);

export type ViewerReservedFragment = (
  { __typename?: 'Viewer' }
  & { profile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id'>
  )> }
);

export type ItemThumbnailFragment = (
  { __typename?: 'ListItem' }
  & Pick<ListItem, 'id' | 'title' | 'slug' | 'reserved' | 'quantity' | 'url' | 'createdAt' | 'isArchived' | 'notes' | 'link' | 'viewerHasReservedQuantity'>
  & { image?: Maybe<(
    { __typename?: 'Image' }
    & ItemImageFragment
  )>, profile: (
    { __typename?: 'Profile' }
    & Pick<Profile, 'id'>
    & ProfileThumbnailFragment
  ), affiliate?: Maybe<(
    { __typename?: 'LinkAffiliate' }
    & Pick<LinkAffiliate, 'network'>
  )> }
);

export type ItemCondensedFragment = (
  { __typename?: 'ListItem' }
  & Pick<ListItem, 'id' | 'title' | 'slug' | 'reserved' | 'quantity'>
  & { image?: Maybe<(
    { __typename?: 'Image' }
    & ItemImageFragment
  )>, profile: (
    { __typename?: 'Profile' }
    & Pick<Profile, 'id'>
    & ProfileThumbnailFragment
  ) }
);

type ItemPagination_ListItemCollection_Fragment = (
  { __typename?: 'ListItemCollection' }
  & { listItems?: Maybe<(
    { __typename?: 'ListItemsConnection' }
    & Pick<ListItemsConnection, 'total'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
    ), edges?: Maybe<Array<Maybe<(
      { __typename?: 'ListItemsEdge' }
      & { node?: Maybe<(
        { __typename?: 'ListItem' }
        & ItemThumbnailFragment
      )> }
    )>>> }
  )> }
);

type ItemPagination_Profile_Fragment = (
  { __typename?: 'Profile' }
  & { listItems?: Maybe<(
    { __typename?: 'ListItemsConnection' }
    & Pick<ListItemsConnection, 'total'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
    ), edges?: Maybe<Array<Maybe<(
      { __typename?: 'ListItemsEdge' }
      & { node?: Maybe<(
        { __typename?: 'ListItem' }
        & ItemThumbnailFragment
      )> }
    )>>> }
  )> }
);

type ItemPagination_ProfileArchived_Fragment = (
  { __typename?: 'ProfileArchived' }
  & { listItems?: Maybe<(
    { __typename?: 'ListItemsConnection' }
    & Pick<ListItemsConnection, 'total'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
    ), edges?: Maybe<Array<Maybe<(
      { __typename?: 'ListItemsEdge' }
      & { node?: Maybe<(
        { __typename?: 'ListItem' }
        & ItemThumbnailFragment
      )> }
    )>>> }
  )> }
);

type ItemPagination_ProfileUncollection_Fragment = (
  { __typename?: 'ProfileUncollection' }
  & { listItems?: Maybe<(
    { __typename?: 'ListItemsConnection' }
    & Pick<ListItemsConnection, 'total'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
    ), edges?: Maybe<Array<Maybe<(
      { __typename?: 'ListItemsEdge' }
      & { node?: Maybe<(
        { __typename?: 'ListItem' }
        & ItemThumbnailFragment
      )> }
    )>>> }
  )> }
);

type ItemPagination_GenericFeed_Fragment = (
  { __typename?: 'GenericFeed' }
  & { listItems?: Maybe<(
    { __typename?: 'ListItemsConnection' }
    & Pick<ListItemsConnection, 'total'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
    ), edges?: Maybe<Array<Maybe<(
      { __typename?: 'ListItemsEdge' }
      & { node?: Maybe<(
        { __typename?: 'ListItem' }
        & ItemThumbnailFragment
      )> }
    )>>> }
  )> }
);

type ItemPagination_TailoredFeed_Fragment = (
  { __typename?: 'TailoredFeed' }
  & { listItems?: Maybe<(
    { __typename?: 'ListItemsConnection' }
    & Pick<ListItemsConnection, 'total'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
    ), edges?: Maybe<Array<Maybe<(
      { __typename?: 'ListItemsEdge' }
      & { node?: Maybe<(
        { __typename?: 'ListItem' }
        & ItemThumbnailFragment
      )> }
    )>>> }
  )> }
);

export type ItemPaginationFragment = ItemPagination_ListItemCollection_Fragment | ItemPagination_Profile_Fragment | ItemPagination_ProfileArchived_Fragment | ItemPagination_ProfileUncollection_Fragment | ItemPagination_GenericFeed_Fragment | ItemPagination_TailoredFeed_Fragment;

export type CreateProfileMutationVariables = Exact<{
  input: CreateProfileInput;
}>;


export type CreateProfileMutation = (
  { __typename?: 'Mutation' }
  & { createProfile?: Maybe<(
    { __typename?: 'CreateProfilePayload' }
    & { profile: (
      { __typename?: 'Profile' }
      & ProfileDataFragment
    ) }
  )> }
);

export type GetProxyProfilesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProxyProfilesQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { user?: Maybe<(
      { __typename?: 'User' }
      & { proxy_profiles?: Maybe<(
        { __typename?: 'ProxyProfilesConnection' }
        & { edges?: Maybe<Array<Maybe<(
          { __typename?: 'ProxyProfilesEdge' }
          & { node?: Maybe<(
            { __typename?: 'Profile' }
            & ProfileDataFragment
          )> }
        )>>> }
      )> }
    )> }
  ) }
);

export type CollectionItemsFragment = (
  { __typename?: 'ListItemsConnection' }
  & Pick<ListItemsConnection, 'total'>
  & { edges?: Maybe<Array<Maybe<(
    { __typename?: 'ListItemsEdge' }
    & { node?: Maybe<(
      { __typename?: 'ListItem' }
      & Pick<ListItem, 'id' | 'slug'>
      & { image?: Maybe<(
        { __typename?: 'Image' }
        & ItemImageFragment
      )> }
    )> }
  )>>> }
);

export type GetProfileCollectionsQueryVariables = Exact<{
  input: ProfileSearchInput;
}>;


export type GetProfileCollectionsQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & Pick<Viewer, 'isAuthenticated'>
    & { profile?: Maybe<(
      { __typename?: 'Profile' }
      & Pick<Profile, 'id' | 'slug'>
    )> }
  ), profile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id' | 'slug' | 'name' | 'viewerCanEditList'>
    & { uncollected?: Maybe<(
      { __typename?: 'ProfileUncollection' }
      & { listItems?: Maybe<(
        { __typename?: 'ListItemsConnection' }
        & CollectionItemsFragment
      )> }
    )>, collections?: Maybe<(
      { __typename?: 'ProfileListItemCollectionsConnection' }
      & { edges?: Maybe<Array<Maybe<(
        { __typename?: 'ProfileListItemCollectionsEdge' }
        & { node?: Maybe<(
          { __typename?: 'ListItemCollection' }
          & Pick<ListItemCollection, 'id' | 'title' | 'slug'>
          & { image?: Maybe<(
            { __typename?: 'Image' }
            & ItemImageFragment
          )>, listItems?: Maybe<(
            { __typename?: 'ListItemsConnection' }
            & CollectionItemsFragment
          )> }
        )> }
      )>>> }
    )> }
  )> }
);

export type GetProfileFollowersQueryVariables = Exact<{
  input: ProfileSearchInput;
}>;


export type GetProfileFollowersQuery = (
  { __typename?: 'Query' }
  & { profile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id' | 'slug'>
    & { followers?: Maybe<(
      { __typename?: 'ProfileFollowerTypeConnection' }
      & { edges?: Maybe<Array<Maybe<(
        { __typename?: 'ProfileFollowerTypeEdge' }
        & { node?: Maybe<(
          { __typename?: 'User' }
          & { profile: (
            { __typename?: 'Profile' }
            & ProfileDataFragment
          ) }
        )> }
      )>>> }
    )> }
  )> }
);

export type GetProfileFollowingQueryVariables = Exact<{
  input: ProfileSearchInput;
}>;


export type GetProfileFollowingQuery = (
  { __typename?: 'Query' }
  & { profile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id' | 'slug'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
      & { following?: Maybe<(
        { __typename?: 'UserFollowingTypeConnection' }
        & { edges?: Maybe<Array<Maybe<(
          { __typename?: 'UserFollowingTypeEdge' }
          & { node?: Maybe<(
            { __typename?: 'Profile' }
            & ProfileDataFragment
          )> }
        )>>> }
      )> }
    )> }
  )> }
);

export type GetProfileItemsQueryVariables = Exact<{
  input: ProfileSearchInput;
  first?: Scalars['Int'];
  after?: Scalars['String'];
  last?: Scalars['Int'];
  before?: Scalars['String'];
  orderBy?: Array<Maybe<ListItemsOrderBy>> | Maybe<ListItemsOrderBy>;
}>;


export type GetProfileItemsQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & Pick<Viewer, 'isAuthenticated'>
    & { profile?: Maybe<(
      { __typename?: 'Profile' }
      & Pick<Profile, 'id' | 'slug'>
    )> }
  ), profile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id' | 'slug' | 'name' | 'viewerCanEditList'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )>, collections?: Maybe<(
      { __typename?: 'ProfileListItemCollectionsConnection' }
      & { edges?: Maybe<Array<Maybe<(
        { __typename?: 'ProfileListItemCollectionsEdge' }
        & { node?: Maybe<(
          { __typename?: 'ListItemCollection' }
          & Pick<ListItemCollection, 'id' | 'slug' | 'title'>
        )> }
      )>>> }
    )> }
    & ItemPagination_Profile_Fragment
    & ProfileImageFragment
  )> }
);

export type ProfileImageFragment = (
  { __typename?: 'Profile' }
  & Pick<Profile, 'name'>
  & { image?: Maybe<(
    { __typename?: 'Image' }
    & Pick<Image, 'fixed'>
  )> }
);

export type ProfileThumbnailFragment = (
  { __typename?: 'Profile' }
  & Pick<Profile, 'name' | 'slug'>
  & ProfileImageFragment
);

export type ProfileOptionFragment = (
  { __typename?: 'Profile' }
  & Pick<Profile, 'id' | 'name' | 'slug'>
  & ProfileImageFragment
);

export type GetUserProfilesInputQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserProfilesInputQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { user?: Maybe<(
      { __typename?: 'User' }
      & { profile: (
        { __typename?: 'Profile' }
        & Pick<Profile, 'id' | 'name' | 'slug'>
        & ProfileImageFragment
      ), proxy_profiles?: Maybe<(
        { __typename?: 'ProxyProfilesConnection' }
        & { edges?: Maybe<Array<Maybe<(
          { __typename?: 'ProxyProfilesEdge' }
          & { node?: Maybe<(
            { __typename?: 'Profile' }
            & Pick<Profile, 'id' | 'name' | 'slug'>
            & ProfileImageFragment
          )> }
        )>>> }
      )> }
    )> }
  ) }
);

export type ProfileDataFragment = (
  { __typename?: 'Profile' }
  & Pick<Profile, 'id' | 'name' | 'slug' | 'listUpdatedAt' | 'viewerIsFollowing'>
  & { listItems?: Maybe<(
    { __typename?: 'ListItemsConnection' }
    & Pick<ListItemsConnection, 'total'>
  )> }
  & ProfileImageFragment
);

export type GetItemSortQueryVariables = Exact<{ [key: string]: never; }>;


export type GetItemSortQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'itemSort'>
);

export type GenerateSignedUploadMutationVariables = Exact<{ [key: string]: never; }>;


export type GenerateSignedUploadMutation = (
  { __typename?: 'Mutation' }
  & { generateSignedUpload?: Maybe<(
    { __typename?: 'SignedUploadPayload' }
    & Pick<SignedUploadPayload, 'signedUrl' | 'completionToken'>
    & { error?: Maybe<(
      { __typename?: 'MutationError' }
      & Pick<MutationError, 'code'>
    )> }
  )> }
);

export type GetItemLayoutQueryVariables = Exact<{ [key: string]: never; }>;


export type GetItemLayoutQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'itemLayout'>
);

export type GetInitialInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type GetInitialInfoQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & Pick<Viewer, 'isAuthenticated'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )>, profile?: Maybe<(
      { __typename?: 'Profile' }
      & Pick<Profile, 'id' | 'slug' | 'name'>
      & { image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'fixed'>
      )> }
    )> }
  ) }
);

export type GetProfileCollectionQueryVariables = Exact<{
  input: CollectionSearchInput;
  orderBy?: Array<Maybe<ListItemsOrderBy>> | Maybe<ListItemsOrderBy>;
  first?: Scalars['Int'];
  last?: Scalars['Int'];
  after?: Scalars['String'];
  before?: Scalars['String'];
}>;


export type GetProfileCollectionQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & Pick<Viewer, 'isAuthenticated'>
    & { profile?: Maybe<(
      { __typename?: 'Profile' }
      & Pick<Profile, 'id' | 'slug'>
    )> }
  ), collection?: Maybe<(
    { __typename?: 'ListItemCollection' }
    & Pick<ListItemCollection, 'id' | 'title' | 'slug'>
    & { image?: Maybe<(
      { __typename?: 'Image' }
      & ItemImageFragment
    )>, profile: (
      { __typename?: 'Profile' }
      & Pick<Profile, 'id' | 'slug' | 'viewerCanEditList'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id'>
      )> }
    ) }
    & ItemPagination_ListItemCollection_Fragment
  )> }
);

export type GetUncollectedItemsQueryVariables = Exact<{
  input: ProfileSearchInput;
  orderBy?: Array<Maybe<ListItemsOrderBy>> | Maybe<ListItemsOrderBy>;
  first?: Scalars['Int'];
  last?: Scalars['Int'];
  after?: Scalars['String'];
  before?: Scalars['String'];
}>;


export type GetUncollectedItemsQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & Pick<Viewer, 'isAuthenticated'>
    & { profile?: Maybe<(
      { __typename?: 'Profile' }
      & Pick<Profile, 'id' | 'slug'>
    )> }
  ), profile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id' | 'viewerCanEditList'>
    & { uncollected?: Maybe<(
      { __typename?: 'ProfileUncollection' }
      & ItemPagination_ProfileUncollection_Fragment
    )>, user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )> }
    & ProfileThumbnailFragment
  )> }
);

export type GetExploreFeedQueryVariables = Exact<{
  first?: Scalars['Int'];
  after?: Scalars['String'];
  last?: Scalars['Int'];
  before?: Scalars['String'];
  orderBy?: Array<Maybe<ListItemsOrderBy>> | Maybe<ListItemsOrderBy>;
}>;


export type GetExploreFeedQuery = (
  { __typename?: 'Query' }
  & { feed: (
    { __typename?: 'Feed' }
    & { generic?: Maybe<(
      { __typename?: 'GenericFeed' }
      & ItemPagination_GenericFeed_Fragment
    )> }
  ) }
);

export type RecentlyUpdatedProfileFragment = (
  { __typename?: 'Profile' }
  & Pick<Profile, 'id'>
  & ProfileThumbnailFragment
);

export type RecentlyUpdatedProfilesFragment = (
  { __typename?: 'UserFollowingTypeConnection' }
  & { edges?: Maybe<Array<Maybe<(
    { __typename?: 'UserFollowingTypeEdge' }
    & { node?: Maybe<(
      { __typename?: 'Profile' }
      & RecentlyUpdatedProfileFragment
    )> }
  )>>> }
);

export type GetFollowingFeedQueryVariables = Exact<{
  first?: Scalars['Int'];
  after?: Scalars['String'];
  last?: Scalars['Int'];
  before?: Scalars['String'];
  orderBy?: Array<Maybe<ListItemsOrderBy>> | Maybe<ListItemsOrderBy>;
}>;


export type GetFollowingFeedQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & Pick<Viewer, 'isAuthenticated'>
    & { profile?: Maybe<(
      { __typename?: 'Profile' }
      & Pick<Profile, 'id' | 'slug'>
    )>, user?: Maybe<(
      { __typename?: 'User' }
      & { following?: Maybe<(
        { __typename?: 'UserFollowingTypeConnection' }
        & Pick<UserFollowingTypeConnection, 'total'>
        & RecentlyUpdatedProfilesFragment
      )> }
    )> }
  ), feed: (
    { __typename?: 'Feed' }
    & { tailored?: Maybe<(
      { __typename?: 'TailoredFeed' }
      & ItemPagination_TailoredFeed_Fragment
    )> }
  ) }
);

export type CollectionListFragment = (
  { __typename?: 'ListItemsCollectionsConnection' }
  & { edges?: Maybe<Array<Maybe<(
    { __typename?: 'ListItemsCollectionsEdge' }
    & { node?: Maybe<(
      { __typename?: 'ListItemCollection' }
      & Pick<ListItemCollection, 'id' | 'title' | 'slug'>
    )> }
  )>>> }
);

export type ItemDataFragment = (
  { __typename?: 'ListItem' }
  & Pick<ListItem, 'id' | 'title' | 'link' | 'url' | 'quantity' | 'slug' | 'isArchived' | 'createdAt' | 'notes'>
  & { affiliate?: Maybe<(
    { __typename?: 'LinkAffiliate' }
    & Pick<LinkAffiliate, 'network'>
  )>, collections?: Maybe<(
    { __typename?: 'ListItemsCollectionsConnection' }
    & CollectionListFragment
  )>, image?: Maybe<(
    { __typename?: 'Image' }
    & ItemSourceImageFragment
  )>, profile: (
    { __typename?: 'Profile' }
    & Pick<Profile, 'id' | 'slug' | 'name' | 'viewerCanEditList'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )>, image?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'fixed'>
    )>, collections?: Maybe<(
      { __typename?: 'ProfileListItemCollectionsConnection' }
      & { edges?: Maybe<Array<Maybe<(
        { __typename?: 'ProfileListItemCollectionsEdge' }
        & { node?: Maybe<(
          { __typename?: 'ListItemCollection' }
          & Pick<ListItemCollection, 'id' | 'title' | 'slug'>
        )> }
      )>>> }
    )> }
  ) }
  & ItemReservedFragment
  & ItemCommentsFragment
);

export type ViewerInfoFragment = (
  { __typename?: 'Viewer' }
  & Pick<Viewer, 'isAuthenticated'>
  & { profile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id' | 'slug'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )> }
  )> }
);

export type GetItemQueryVariables = Exact<{
  input: ItemSearchInput;
}>;


export type GetItemQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & Pick<Viewer, 'isAuthenticated'>
    & { profile?: Maybe<(
      { __typename?: 'Profile' }
      & Pick<Profile, 'id' | 'slug'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id'>
      )> }
    )> }
    & ViewerReservedFragment
  ), item?: Maybe<(
    { __typename?: 'ListItem' }
    & { profile: (
      { __typename?: 'Profile' }
      & Pick<Profile, 'id' | 'slug'>
      & { collections?: Maybe<(
        { __typename?: 'ProfileListItemCollectionsConnection' }
        & { edges?: Maybe<Array<Maybe<(
          { __typename?: 'ProfileListItemCollectionsEdge' }
          & { node?: Maybe<(
            { __typename?: 'ListItemCollection' }
            & Pick<ListItemCollection, 'id' | 'slug' | 'title'>
          )> }
        )>>> }
      )> }
    ) }
    & ItemDataFragment
  )> }
);

export type UpdateItemMutationVariables = Exact<{
  input: UpdateListItemInput;
}>;


export type UpdateItemMutation = (
  { __typename?: 'Mutation' }
  & { updateListItem?: Maybe<(
    { __typename?: 'UpdateListItemPayload' }
    & { listItem: (
      { __typename?: 'ListItem' }
      & ItemDataFragment
    ) }
  )> }
);

export type ReserveItemMutationVariables = Exact<{
  input: ReserveListItemInput;
}>;


export type ReserveItemMutation = (
  { __typename?: 'Mutation' }
  & { reserveListItem?: Maybe<(
    { __typename?: 'ReserveListItemPayload' }
    & { listItem: (
      { __typename?: 'ListItem' }
      & ItemDataFragment
    ) }
  )> }
);

export type CopyItemMutationVariables = Exact<{
  input: CopyListItemInput;
}>;


export type CopyItemMutation = (
  { __typename?: 'Mutation' }
  & { copyListItem?: Maybe<(
    { __typename?: 'CopyListItemPayload' }
    & { listItem: (
      { __typename?: 'ListItem' }
      & ItemDataFragment
    ) }
  )> }
);

export type GetPurchasedItemsQueryVariables = Exact<{
  input: ProfileSearchInput;
  first?: Scalars['Int'];
  after?: Scalars['String'];
  last?: Scalars['Int'];
  before?: Scalars['String'];
  orderBy?: Array<Maybe<ListItemsOrderBy>> | Maybe<ListItemsOrderBy>;
}>;


export type GetPurchasedItemsQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & Pick<Viewer, 'isAuthenticated'>
    & { profile?: Maybe<(
      { __typename?: 'Profile' }
      & Pick<Profile, 'id' | 'slug'>
    )> }
  ), profile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id' | 'slug' | 'name' | 'viewerCanEditList'>
    & { archived?: Maybe<(
      { __typename?: 'ProfileArchived' }
      & ItemPagination_ProfileArchived_Fragment
    )>, user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )> }
    & ProfileThumbnailFragment
  )> }
);

export type GetProfilesQueryVariables = Exact<{
  search: Scalars['String'];
  count?: Scalars['Int'];
}>;


export type GetProfilesQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { profile?: Maybe<(
      { __typename?: 'Profile' }
      & Pick<Profile, 'id' | 'slug'>
    )> }
  ), profiles?: Maybe<(
    { __typename?: 'PublicProfilesConnection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'PublicProfilesEdge' }
      & { node?: Maybe<(
        { __typename?: 'Profile' }
        & ProfileDataFragment
      )> }
    )>>> }
  )> }
);

export type ProfileSettingsFragment = (
  { __typename?: 'Profile' }
  & Pick<Profile, 'id' | 'slug' | 'name' | 'birthday'>
  & ProfileImageFragment
);

export type GetSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSettingsQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & Pick<Viewer, 'isAuthenticated'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & { proxy_profiles?: Maybe<(
        { __typename?: 'ProxyProfilesConnection' }
        & { edges?: Maybe<Array<Maybe<(
          { __typename?: 'ProxyProfilesEdge' }
          & { node?: Maybe<(
            { __typename?: 'Profile' }
            & Pick<Profile, 'birthday'>
            & ProfileOptionFragment
          )> }
        )>>> }
      )>, profile: (
        { __typename?: 'Profile' }
        & Pick<Profile, 'birthday'>
        & ProfileOptionFragment
      ) }
    )> }
  ) }
);

export type UpdateProfileMutationVariables = Exact<{
  input: UpdateProfileInput;
}>;


export type UpdateProfileMutation = (
  { __typename?: 'Mutation' }
  & { updateProfile?: Maybe<(
    { __typename?: 'UpdateProfilePayload' }
    & { profile?: Maybe<(
      { __typename?: 'Profile' }
      & Pick<Profile, 'id' | 'slug' | 'name' | 'birthday'>
      & { image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'fixed'>
      )> }
    )>, error?: Maybe<(
      { __typename?: 'MutationError' }
      & Pick<MutationError, 'code' | 'message'>
    )> }
  )> }
);

export type ChangePasswordMutationVariables = Exact<{
  input: ChangePasswordInput;
}>;


export type ChangePasswordMutation = (
  { __typename?: 'Mutation' }
  & { changePassword?: Maybe<(
    { __typename?: 'ChangePasswordPayload' }
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )>, error?: Maybe<(
      { __typename?: 'MutationError' }
      & Pick<MutationError, 'code' | 'message'>
    )> }
  )> }
);

export type NewAccountMutationVariables = Exact<{
  data: CreateAccountInput;
}>;


export type NewAccountMutation = (
  { __typename?: 'Mutation' }
  & { createAccount?: Maybe<(
    { __typename?: 'CreateAccountError' }
    & Pick<CreateAccountError, 'error'>
  ) | (
    { __typename?: 'CreateAccountPayload' }
    & { profile: (
      { __typename?: 'Profile' }
      & Pick<Profile, 'id' | 'slug' | 'name' | 'url'>
    ) }
  )> }
);

export type TabsSummaryFragment = (
  { __typename?: 'Viewer' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & { proxy_profiles?: Maybe<(
      { __typename?: 'ProxyProfilesConnection' }
      & Pick<ProxyProfilesConnection, 'total'>
    )>, following?: Maybe<(
      { __typename?: 'UserFollowingTypeConnection' }
      & Pick<UserFollowingTypeConnection, 'total'>
    )> }
  )> }
);

export type GetProfileQueryVariables = Exact<{
  input: ProfileSearchInput;
}>;


export type GetProfileQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & Pick<Viewer, 'isAuthenticated'>
    & { profile?: Maybe<(
      { __typename?: 'Profile' }
      & Pick<Profile, 'id' | 'slug'>
    )>, user?: Maybe<(
      { __typename?: 'User' }
      & { proxy_profiles?: Maybe<(
        { __typename?: 'ProxyProfilesConnection' }
        & Pick<ProxyProfilesConnection, 'total'>
      )> }
    )> }
  ), profile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id' | 'slug' | 'url' | 'name' | 'bio' | 'viewerIsFollowing' | 'viewerCanEditList'>
    & { image?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'fixed'>
    )>, listItems?: Maybe<(
      { __typename?: 'ListItemsConnection' }
      & Pick<ListItemsConnection, 'total'>
    )>, followers?: Maybe<(
      { __typename?: 'ProfileFollowerTypeConnection' }
      & Pick<ProfileFollowerTypeConnection, 'total'>
    )>, user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
      & { following?: Maybe<(
        { __typename?: 'UserFollowingTypeConnection' }
        & Pick<UserFollowingTypeConnection, 'total'>
      )> }
    )> }
  )> }
);

export const FollowingProfileFragmentDoc = gql`
    fragment FollowingProfile on Profile {
  id
  slug
  viewerIsFollowing
}
    `;
export const CollectionTitleFragmentDoc = gql`
    fragment CollectionTitle on Profile {
  collections {
    edges {
      node {
        id
        title
      }
    }
  }
}
    `;
export const ViewerReservedFragmentDoc = gql`
    fragment ViewerReserved on Viewer {
  profile {
    id
  }
}
    `;
export const ItemImageFragmentDoc = gql`
    fragment ItemImage on Image {
  tiny: pixelated
  small: max(input: {width: 150})
  large: max(input: {width: 250})
  fixed(input: {ratio: 1, width: 40})
  height
  width
}
    `;
export const ProfileImageFragmentDoc = gql`
    fragment ProfileImage on Profile {
  ... on Profile {
    name
    image {
      fixed(input: {ratio: 1, width: 125})
    }
  }
}
    `;
export const ProfileThumbnailFragmentDoc = gql`
    fragment ProfileThumbnail on Profile {
  name
  slug
  ...ProfileImage
}
    ${ProfileImageFragmentDoc}`;
export const ItemCondensedFragmentDoc = gql`
    fragment ItemCondensed on ListItem {
  id
  title
  slug
  reserved
  quantity
  image {
    ...ItemImage
  }
  profile {
    id
    ...ProfileThumbnail
  }
}
    ${ItemImageFragmentDoc}
${ProfileThumbnailFragmentDoc}`;
export const ItemThumbnailFragmentDoc = gql`
    fragment ItemThumbnail on ListItem {
  id
  title
  slug
  reserved
  quantity
  image {
    ...ItemImage
  }
  profile {
    id
    ...ProfileThumbnail
  }
  url
  affiliate {
    network
  }
  createdAt
  isArchived
  notes
  link
  viewerHasReservedQuantity
}
    ${ItemImageFragmentDoc}
${ProfileThumbnailFragmentDoc}`;
export const ItemPaginationFragmentDoc = gql`
    fragment ItemPagination on HasListItems {
  listItems(first: $first, last: $last, before: $before, after: $after, orderBy: $orderBy) {
    total
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      node {
        ...ItemThumbnail
      }
    }
  }
}
    ${ItemThumbnailFragmentDoc}`;
export const CollectionItemsFragmentDoc = gql`
    fragment CollectionItems on ListItemsConnection {
  total
  edges {
    node {
      id
      slug
      image {
        ...ItemImage
      }
    }
  }
}
    ${ItemImageFragmentDoc}`;
export const ProfileOptionFragmentDoc = gql`
    fragment ProfileOption on Profile {
  id
  name
  slug
  ...ProfileImage
}
    ${ProfileImageFragmentDoc}`;
export const ProfileDataFragmentDoc = gql`
    fragment ProfileData on Profile {
  id
  name
  slug
  ...ProfileImage
  listUpdatedAt
  viewerIsFollowing
  listItems {
    total
  }
}
    ${ProfileImageFragmentDoc}`;
export const RecentlyUpdatedProfileFragmentDoc = gql`
    fragment RecentlyUpdatedProfile on Profile {
  id
  ...ProfileThumbnail
}
    ${ProfileThumbnailFragmentDoc}`;
export const RecentlyUpdatedProfilesFragmentDoc = gql`
    fragment RecentlyUpdatedProfiles on UserFollowingTypeConnection {
  edges {
    node {
      ...RecentlyUpdatedProfile
    }
  }
}
    ${RecentlyUpdatedProfileFragmentDoc}`;
export const ItemReservedFragmentDoc = gql`
    fragment ItemReserved on ListItem {
  id
  quantity
  viewerHasReservedQuantity
  reserved
}
    `;
export const CollectionListFragmentDoc = gql`
    fragment CollectionList on ListItemsCollectionsConnection {
  edges {
    node {
      id
      title
      slug
    }
  }
}
    `;
export const ItemSourceImageFragmentDoc = gql`
    fragment ItemSourceImage on Image {
  tiny: pixelated
  source: max(input: {width: 700})
  height
  width
}
    `;
export const ItemCommentFragmentDoc = gql`
    fragment ItemComment on ListItemComment {
  content
  createdAt
  user {
    profile {
      id
      name
      slug
      ...ProfileThumbnail
    }
  }
  __typename
}
    ${ProfileThumbnailFragmentDoc}`;
export const ItemCommentsFragmentDoc = gql`
    fragment ItemComments on ListItem {
  comments {
    edges {
      node {
        ...ItemComment
      }
    }
  }
}
    ${ItemCommentFragmentDoc}`;
export const ItemDataFragmentDoc = gql`
    fragment ItemData on ListItem {
  id
  title
  link
  url
  quantity
  slug
  isArchived
  createdAt
  notes
  affiliate {
    network
  }
  ...ItemReserved
  collections {
    ...CollectionList
  }
  image {
    ...ItemSourceImage
  }
  profile {
    id
    slug
    name
    viewerCanEditList
    user {
      id
    }
    image {
      fixed(input: {ratio: 1, width: 125})
    }
    collections {
      edges {
        node {
          id
          title
          slug
        }
      }
    }
  }
  ...ItemComments
}
    ${ItemReservedFragmentDoc}
${CollectionListFragmentDoc}
${ItemSourceImageFragmentDoc}
${ItemCommentsFragmentDoc}`;
export const ViewerInfoFragmentDoc = gql`
    fragment ViewerInfo on Viewer {
  isAuthenticated
  profile {
    id
    slug
    user {
      id
    }
  }
}
    `;
export const ProfileSettingsFragmentDoc = gql`
    fragment ProfileSettings on Profile {
  id
  slug
  name
  birthday
  ...ProfileImage
}
    ${ProfileImageFragmentDoc}`;
export const TabsSummaryFragmentDoc = gql`
    fragment TabsSummary on Viewer {
  user {
    proxy_profiles {
      total
    }
    following {
      total
    }
  }
}
    `;
export const ScrapeUrlDocument = gql`
    mutation scrapeUrl($input: ScrapeUrlInput!) {
  scrapeUrl(input: $input) {
    url
    title
    image {
      tiny: pixelated
      source: max(input: {width: 700})
      height
      width
    }
  }
}
    `;
export type ScrapeUrlMutationFn = Apollo.MutationFunction<ScrapeUrlMutation, ScrapeUrlMutationVariables>;

/**
 * __useScrapeUrlMutation__
 *
 * To run a mutation, you first call `useScrapeUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useScrapeUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [scrapeUrlMutation, { data, loading, error }] = useScrapeUrlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useScrapeUrlMutation(baseOptions?: Apollo.MutationHookOptions<ScrapeUrlMutation, ScrapeUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ScrapeUrlMutation, ScrapeUrlMutationVariables>(ScrapeUrlDocument, options);
      }
export type ScrapeUrlMutationHookResult = ReturnType<typeof useScrapeUrlMutation>;
export type ScrapeUrlMutationResult = Apollo.MutationResult<ScrapeUrlMutation>;
export type ScrapeUrlMutationOptions = Apollo.BaseMutationOptions<ScrapeUrlMutation, ScrapeUrlMutationVariables>;
export const GetUserProfilesDocument = gql`
    query getUserProfiles {
  viewer {
    user {
      profile {
        id
        name
        slug
        ...ProfileImage
        collections {
          edges {
            node {
              id
              slug
              title
            }
          }
        }
      }
      proxy_profiles {
        edges {
          node {
            id
            name
            slug
            collections {
              edges {
                node {
                  id
                  slug
                  title
                }
              }
            }
            ...ProfileImage
          }
        }
      }
    }
  }
}
    ${ProfileImageFragmentDoc}`;

/**
 * __useGetUserProfilesQuery__
 *
 * To run a query within a React component, call `useGetUserProfilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserProfilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserProfilesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserProfilesQuery(baseOptions?: Apollo.QueryHookOptions<GetUserProfilesQuery, GetUserProfilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserProfilesQuery, GetUserProfilesQueryVariables>(GetUserProfilesDocument, options);
      }
export function useGetUserProfilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserProfilesQuery, GetUserProfilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserProfilesQuery, GetUserProfilesQueryVariables>(GetUserProfilesDocument, options);
        }
export type GetUserProfilesQueryHookResult = ReturnType<typeof useGetUserProfilesQuery>;
export type GetUserProfilesLazyQueryHookResult = ReturnType<typeof useGetUserProfilesLazyQuery>;
export type GetUserProfilesQueryResult = Apollo.QueryResult<GetUserProfilesQuery, GetUserProfilesQueryVariables>;
export const AddItemDocument = gql`
    mutation addItem($input: CreateListItemInput!) {
  createListItem(input: $input) {
    profile {
      id
      slug
    }
    listItem {
      ...ItemThumbnail
    }
  }
}
    ${ItemThumbnailFragmentDoc}`;
export type AddItemMutationFn = Apollo.MutationFunction<AddItemMutation, AddItemMutationVariables>;

/**
 * __useAddItemMutation__
 *
 * To run a mutation, you first call `useAddItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addItemMutation, { data, loading, error }] = useAddItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddItemMutation(baseOptions?: Apollo.MutationHookOptions<AddItemMutation, AddItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddItemMutation, AddItemMutationVariables>(AddItemDocument, options);
      }
export type AddItemMutationHookResult = ReturnType<typeof useAddItemMutation>;
export type AddItemMutationResult = Apollo.MutationResult<AddItemMutation>;
export type AddItemMutationOptions = Apollo.BaseMutationOptions<AddItemMutation, AddItemMutationVariables>;
export const CreateCollectionDocument = gql`
    mutation createCollection($input: CreateListItemCollectionInput!) {
  createListItemCollection(input: $input) {
    collection {
      id
      slug
      title
    }
    error {
      code
      message
    }
  }
}
    `;
export type CreateCollectionMutationFn = Apollo.MutationFunction<CreateCollectionMutation, CreateCollectionMutationVariables>;

/**
 * __useCreateCollectionMutation__
 *
 * To run a mutation, you first call `useCreateCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCollectionMutation, { data, loading, error }] = useCreateCollectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCollectionMutation(baseOptions?: Apollo.MutationHookOptions<CreateCollectionMutation, CreateCollectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCollectionMutation, CreateCollectionMutationVariables>(CreateCollectionDocument, options);
      }
export type CreateCollectionMutationHookResult = ReturnType<typeof useCreateCollectionMutation>;
export type CreateCollectionMutationResult = Apollo.MutationResult<CreateCollectionMutation>;
export type CreateCollectionMutationOptions = Apollo.BaseMutationOptions<CreateCollectionMutation, CreateCollectionMutationVariables>;
export const EditCollectionNameDocument = gql`
    mutation editCollectionName($input: UpdateListItemCollectionInput!) {
  updateListItemCollection(input: $input) {
    collection {
      id
      title
      slug
    }
    error {
      code
      message
    }
  }
}
    `;
export type EditCollectionNameMutationFn = Apollo.MutationFunction<EditCollectionNameMutation, EditCollectionNameMutationVariables>;

/**
 * __useEditCollectionNameMutation__
 *
 * To run a mutation, you first call `useEditCollectionNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCollectionNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCollectionNameMutation, { data, loading, error }] = useEditCollectionNameMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditCollectionNameMutation(baseOptions?: Apollo.MutationHookOptions<EditCollectionNameMutation, EditCollectionNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditCollectionNameMutation, EditCollectionNameMutationVariables>(EditCollectionNameDocument, options);
      }
export type EditCollectionNameMutationHookResult = ReturnType<typeof useEditCollectionNameMutation>;
export type EditCollectionNameMutationResult = Apollo.MutationResult<EditCollectionNameMutation>;
export type EditCollectionNameMutationOptions = Apollo.BaseMutationOptions<EditCollectionNameMutation, EditCollectionNameMutationVariables>;
export const UpdateCollectionDocument = gql`
    mutation updateCollection($input: UpdateListItemCollectionInput!) {
  updateListItemCollection(input: $input) {
    collection {
      id
      title
      slug
      image {
        ...ItemImage
      }
    }
    error {
      code
      message
    }
  }
}
    ${ItemImageFragmentDoc}`;
export type UpdateCollectionMutationFn = Apollo.MutationFunction<UpdateCollectionMutation, UpdateCollectionMutationVariables>;

/**
 * __useUpdateCollectionMutation__
 *
 * To run a mutation, you first call `useUpdateCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCollectionMutation, { data, loading, error }] = useUpdateCollectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCollectionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCollectionMutation, UpdateCollectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCollectionMutation, UpdateCollectionMutationVariables>(UpdateCollectionDocument, options);
      }
export type UpdateCollectionMutationHookResult = ReturnType<typeof useUpdateCollectionMutation>;
export type UpdateCollectionMutationResult = Apollo.MutationResult<UpdateCollectionMutation>;
export type UpdateCollectionMutationOptions = Apollo.BaseMutationOptions<UpdateCollectionMutation, UpdateCollectionMutationVariables>;
export const GetProfileCollectionSettingsDocument = gql`
    query getProfileCollectionSettings($input: CollectionSearchInput!) {
  collection(input: $input) @connection(key: "collection") {
    id
    title
    slug
    image {
      ...ItemImage
    }
  }
}
    ${ItemImageFragmentDoc}`;

/**
 * __useGetProfileCollectionSettingsQuery__
 *
 * To run a query within a React component, call `useGetProfileCollectionSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileCollectionSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileCollectionSettingsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetProfileCollectionSettingsQuery(baseOptions: Apollo.QueryHookOptions<GetProfileCollectionSettingsQuery, GetProfileCollectionSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProfileCollectionSettingsQuery, GetProfileCollectionSettingsQueryVariables>(GetProfileCollectionSettingsDocument, options);
      }
export function useGetProfileCollectionSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProfileCollectionSettingsQuery, GetProfileCollectionSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProfileCollectionSettingsQuery, GetProfileCollectionSettingsQueryVariables>(GetProfileCollectionSettingsDocument, options);
        }
export type GetProfileCollectionSettingsQueryHookResult = ReturnType<typeof useGetProfileCollectionSettingsQuery>;
export type GetProfileCollectionSettingsLazyQueryHookResult = ReturnType<typeof useGetProfileCollectionSettingsLazyQuery>;
export type GetProfileCollectionSettingsQueryResult = Apollo.QueryResult<GetProfileCollectionSettingsQuery, GetProfileCollectionSettingsQueryVariables>;
export const DeleteCollectionDocument = gql`
    mutation deleteCollection($input: DestroyListItemCollectionInput!) {
  destroyListItemCollection(input: $input) {
    error {
      code
      message
    }
  }
}
    `;
export type DeleteCollectionMutationFn = Apollo.MutationFunction<DeleteCollectionMutation, DeleteCollectionMutationVariables>;

/**
 * __useDeleteCollectionMutation__
 *
 * To run a mutation, you first call `useDeleteCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCollectionMutation, { data, loading, error }] = useDeleteCollectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCollectionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCollectionMutation, DeleteCollectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCollectionMutation, DeleteCollectionMutationVariables>(DeleteCollectionDocument, options);
      }
export type DeleteCollectionMutationHookResult = ReturnType<typeof useDeleteCollectionMutation>;
export type DeleteCollectionMutationResult = Apollo.MutationResult<DeleteCollectionMutation>;
export type DeleteCollectionMutationOptions = Apollo.BaseMutationOptions<DeleteCollectionMutation, DeleteCollectionMutationVariables>;
export const FollowDocument = gql`
    mutation follow($input: FollowProfileInput!) {
  followProfile(input: $input) {
    profile {
      ...FollowingProfile
    }
  }
}
    ${FollowingProfileFragmentDoc}`;
export type FollowMutationFn = Apollo.MutationFunction<FollowMutation, FollowMutationVariables>;

/**
 * __useFollowMutation__
 *
 * To run a mutation, you first call `useFollowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFollowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [followMutation, { data, loading, error }] = useFollowMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFollowMutation(baseOptions?: Apollo.MutationHookOptions<FollowMutation, FollowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FollowMutation, FollowMutationVariables>(FollowDocument, options);
      }
export type FollowMutationHookResult = ReturnType<typeof useFollowMutation>;
export type FollowMutationResult = Apollo.MutationResult<FollowMutation>;
export type FollowMutationOptions = Apollo.BaseMutationOptions<FollowMutation, FollowMutationVariables>;
export const UnfollowDocument = gql`
    mutation unfollow($input: UnfollowProfileInput!) {
  unfollowProfile(input: $input) {
    profile {
      ...FollowingProfile
    }
  }
}
    ${FollowingProfileFragmentDoc}`;
export type UnfollowMutationFn = Apollo.MutationFunction<UnfollowMutation, UnfollowMutationVariables>;

/**
 * __useUnfollowMutation__
 *
 * To run a mutation, you first call `useUnfollowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnfollowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unfollowMutation, { data, loading, error }] = useUnfollowMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnfollowMutation(baseOptions?: Apollo.MutationHookOptions<UnfollowMutation, UnfollowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnfollowMutation, UnfollowMutationVariables>(UnfollowDocument, options);
      }
export type UnfollowMutationHookResult = ReturnType<typeof useUnfollowMutation>;
export type UnfollowMutationResult = Apollo.MutationResult<UnfollowMutation>;
export type UnfollowMutationOptions = Apollo.BaseMutationOptions<UnfollowMutation, UnfollowMutationVariables>;
export const ForgotPasswordDocument = gql`
    mutation forgotPassword($input: ForgotPasswordInput!) {
  forgotPassword(input: $input) {
    error {
      code
      message
    }
  }
}
    `;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, options);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const InviteFriendDocument = gql`
    mutation inviteFriend($input: InviteFriendInput!) {
  inviteFriend(input: $input) {
    error {
      code
      message
    }
  }
}
    `;
export type InviteFriendMutationFn = Apollo.MutationFunction<InviteFriendMutation, InviteFriendMutationVariables>;

/**
 * __useInviteFriendMutation__
 *
 * To run a mutation, you first call `useInviteFriendMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteFriendMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteFriendMutation, { data, loading, error }] = useInviteFriendMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInviteFriendMutation(baseOptions?: Apollo.MutationHookOptions<InviteFriendMutation, InviteFriendMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InviteFriendMutation, InviteFriendMutationVariables>(InviteFriendDocument, options);
      }
export type InviteFriendMutationHookResult = ReturnType<typeof useInviteFriendMutation>;
export type InviteFriendMutationResult = Apollo.MutationResult<InviteFriendMutation>;
export type InviteFriendMutationOptions = Apollo.BaseMutationOptions<InviteFriendMutation, InviteFriendMutationVariables>;
export const CreateCommentDocument = gql`
    mutation createComment($input: CreateListItemCommentInput!) {
  createListItemComment(input: $input) {
    comment {
      ...ItemComment
    }
  }
}
    ${ItemCommentFragmentDoc}`;
export type CreateCommentMutationFn = Apollo.MutationFunction<CreateCommentMutation, CreateCommentMutationVariables>;

/**
 * __useCreateCommentMutation__
 *
 * To run a mutation, you first call `useCreateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommentMutation, { data, loading, error }] = useCreateCommentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCommentMutation(baseOptions?: Apollo.MutationHookOptions<CreateCommentMutation, CreateCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCommentMutation, CreateCommentMutationVariables>(CreateCommentDocument, options);
      }
export type CreateCommentMutationHookResult = ReturnType<typeof useCreateCommentMutation>;
export type CreateCommentMutationResult = Apollo.MutationResult<CreateCommentMutation>;
export type CreateCommentMutationOptions = Apollo.BaseMutationOptions<CreateCommentMutation, CreateCommentMutationVariables>;
export const RemoveItemDocument = gql`
    mutation removeItem($input: DestroyListItemInput!) {
  destroyListItem(input: $input) {
    profile {
      id
      slug
      listItems {
        total
      }
    }
  }
}
    `;
export type RemoveItemMutationFn = Apollo.MutationFunction<RemoveItemMutation, RemoveItemMutationVariables>;

/**
 * __useRemoveItemMutation__
 *
 * To run a mutation, you first call `useRemoveItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeItemMutation, { data, loading, error }] = useRemoveItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveItemMutation(baseOptions?: Apollo.MutationHookOptions<RemoveItemMutation, RemoveItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveItemMutation, RemoveItemMutationVariables>(RemoveItemDocument, options);
      }
export type RemoveItemMutationHookResult = ReturnType<typeof useRemoveItemMutation>;
export type RemoveItemMutationResult = Apollo.MutationResult<RemoveItemMutation>;
export type RemoveItemMutationOptions = Apollo.BaseMutationOptions<RemoveItemMutation, RemoveItemMutationVariables>;
export const CreateProfileDocument = gql`
    mutation createProfile($input: CreateProfileInput!) {
  createProfile(input: $input) {
    profile {
      ...ProfileData
    }
  }
}
    ${ProfileDataFragmentDoc}`;
export type CreateProfileMutationFn = Apollo.MutationFunction<CreateProfileMutation, CreateProfileMutationVariables>;

/**
 * __useCreateProfileMutation__
 *
 * To run a mutation, you first call `useCreateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProfileMutation, { data, loading, error }] = useCreateProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProfileMutation(baseOptions?: Apollo.MutationHookOptions<CreateProfileMutation, CreateProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProfileMutation, CreateProfileMutationVariables>(CreateProfileDocument, options);
      }
export type CreateProfileMutationHookResult = ReturnType<typeof useCreateProfileMutation>;
export type CreateProfileMutationResult = Apollo.MutationResult<CreateProfileMutation>;
export type CreateProfileMutationOptions = Apollo.BaseMutationOptions<CreateProfileMutation, CreateProfileMutationVariables>;
export const GetProxyProfilesDocument = gql`
    query getProxyProfiles {
  viewer {
    user {
      proxy_profiles {
        edges {
          node {
            ...ProfileData
          }
        }
      }
    }
  }
}
    ${ProfileDataFragmentDoc}`;

/**
 * __useGetProxyProfilesQuery__
 *
 * To run a query within a React component, call `useGetProxyProfilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProxyProfilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProxyProfilesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProxyProfilesQuery(baseOptions?: Apollo.QueryHookOptions<GetProxyProfilesQuery, GetProxyProfilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProxyProfilesQuery, GetProxyProfilesQueryVariables>(GetProxyProfilesDocument, options);
      }
export function useGetProxyProfilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProxyProfilesQuery, GetProxyProfilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProxyProfilesQuery, GetProxyProfilesQueryVariables>(GetProxyProfilesDocument, options);
        }
export type GetProxyProfilesQueryHookResult = ReturnType<typeof useGetProxyProfilesQuery>;
export type GetProxyProfilesLazyQueryHookResult = ReturnType<typeof useGetProxyProfilesLazyQuery>;
export type GetProxyProfilesQueryResult = Apollo.QueryResult<GetProxyProfilesQuery, GetProxyProfilesQueryVariables>;
export const GetProfileCollectionsDocument = gql`
    query getProfileCollections($input: ProfileSearchInput!) {
  viewer {
    isAuthenticated
    profile {
      id
      slug
    }
  }
  profile(input: $input) @connection(key: "profile") {
    id
    slug
    name
    viewerCanEditList
    uncollected {
      listItems(first: 4, image: true) {
        ...CollectionItems
      }
    }
    collections(orderBy: TITLE) {
      edges {
        node {
          id
          title
          slug
          image {
            ...ItemImage
          }
          listItems(first: 4, image: true) {
            ...CollectionItems
          }
        }
      }
    }
  }
}
    ${CollectionItemsFragmentDoc}
${ItemImageFragmentDoc}`;

/**
 * __useGetProfileCollectionsQuery__
 *
 * To run a query within a React component, call `useGetProfileCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileCollectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileCollectionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetProfileCollectionsQuery(baseOptions: Apollo.QueryHookOptions<GetProfileCollectionsQuery, GetProfileCollectionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProfileCollectionsQuery, GetProfileCollectionsQueryVariables>(GetProfileCollectionsDocument, options);
      }
export function useGetProfileCollectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProfileCollectionsQuery, GetProfileCollectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProfileCollectionsQuery, GetProfileCollectionsQueryVariables>(GetProfileCollectionsDocument, options);
        }
export type GetProfileCollectionsQueryHookResult = ReturnType<typeof useGetProfileCollectionsQuery>;
export type GetProfileCollectionsLazyQueryHookResult = ReturnType<typeof useGetProfileCollectionsLazyQuery>;
export type GetProfileCollectionsQueryResult = Apollo.QueryResult<GetProfileCollectionsQuery, GetProfileCollectionsQueryVariables>;
export const GetProfileFollowersDocument = gql`
    query getProfileFollowers($input: ProfileSearchInput!) {
  profile(input: $input) @connection(key: "profile") {
    id
    slug
    followers {
      edges {
        node {
          profile {
            ...ProfileData
          }
        }
      }
    }
  }
}
    ${ProfileDataFragmentDoc}`;

/**
 * __useGetProfileFollowersQuery__
 *
 * To run a query within a React component, call `useGetProfileFollowersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileFollowersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileFollowersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetProfileFollowersQuery(baseOptions: Apollo.QueryHookOptions<GetProfileFollowersQuery, GetProfileFollowersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProfileFollowersQuery, GetProfileFollowersQueryVariables>(GetProfileFollowersDocument, options);
      }
export function useGetProfileFollowersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProfileFollowersQuery, GetProfileFollowersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProfileFollowersQuery, GetProfileFollowersQueryVariables>(GetProfileFollowersDocument, options);
        }
export type GetProfileFollowersQueryHookResult = ReturnType<typeof useGetProfileFollowersQuery>;
export type GetProfileFollowersLazyQueryHookResult = ReturnType<typeof useGetProfileFollowersLazyQuery>;
export type GetProfileFollowersQueryResult = Apollo.QueryResult<GetProfileFollowersQuery, GetProfileFollowersQueryVariables>;
export const GetProfileFollowingDocument = gql`
    query getProfileFollowing($input: ProfileSearchInput!) {
  profile(input: $input) @connection(key: "profile") {
    id
    slug
    user {
      id
      following {
        edges {
          node {
            ...ProfileData
          }
        }
      }
    }
  }
}
    ${ProfileDataFragmentDoc}`;

/**
 * __useGetProfileFollowingQuery__
 *
 * To run a query within a React component, call `useGetProfileFollowingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileFollowingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileFollowingQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetProfileFollowingQuery(baseOptions: Apollo.QueryHookOptions<GetProfileFollowingQuery, GetProfileFollowingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProfileFollowingQuery, GetProfileFollowingQueryVariables>(GetProfileFollowingDocument, options);
      }
export function useGetProfileFollowingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProfileFollowingQuery, GetProfileFollowingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProfileFollowingQuery, GetProfileFollowingQueryVariables>(GetProfileFollowingDocument, options);
        }
export type GetProfileFollowingQueryHookResult = ReturnType<typeof useGetProfileFollowingQuery>;
export type GetProfileFollowingLazyQueryHookResult = ReturnType<typeof useGetProfileFollowingLazyQuery>;
export type GetProfileFollowingQueryResult = Apollo.QueryResult<GetProfileFollowingQuery, GetProfileFollowingQueryVariables>;
export const GetProfileItemsDocument = gql`
    query getProfileItems($input: ProfileSearchInput!, $first: Int! = 0, $after: String! = "", $last: Int! = 0, $before: String! = "", $orderBy: [ListItemsOrderBy]! = TITLE) {
  viewer {
    isAuthenticated
    profile {
      id
      slug
    }
  }
  profile(input: $input) @connection(key: "profile") {
    id
    slug
    name
    viewerCanEditList
    ...ProfileImage
    ... on Profile {
      ...ItemPagination
    }
    user {
      id
    }
    collections {
      edges {
        node {
          id
          slug
          title
        }
      }
    }
  }
}
    ${ProfileImageFragmentDoc}
${ItemPaginationFragmentDoc}`;

/**
 * __useGetProfileItemsQuery__
 *
 * To run a query within a React component, call `useGetProfileItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileItemsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetProfileItemsQuery(baseOptions: Apollo.QueryHookOptions<GetProfileItemsQuery, GetProfileItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProfileItemsQuery, GetProfileItemsQueryVariables>(GetProfileItemsDocument, options);
      }
export function useGetProfileItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProfileItemsQuery, GetProfileItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProfileItemsQuery, GetProfileItemsQueryVariables>(GetProfileItemsDocument, options);
        }
export type GetProfileItemsQueryHookResult = ReturnType<typeof useGetProfileItemsQuery>;
export type GetProfileItemsLazyQueryHookResult = ReturnType<typeof useGetProfileItemsLazyQuery>;
export type GetProfileItemsQueryResult = Apollo.QueryResult<GetProfileItemsQuery, GetProfileItemsQueryVariables>;
export const GetUserProfilesInputDocument = gql`
    query getUserProfilesInput {
  viewer {
    user {
      profile {
        id
        name
        slug
        ...ProfileImage
      }
      proxy_profiles {
        edges {
          node {
            id
            name
            slug
            ...ProfileImage
          }
        }
      }
    }
  }
}
    ${ProfileImageFragmentDoc}`;

/**
 * __useGetUserProfilesInputQuery__
 *
 * To run a query within a React component, call `useGetUserProfilesInputQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserProfilesInputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserProfilesInputQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserProfilesInputQuery(baseOptions?: Apollo.QueryHookOptions<GetUserProfilesInputQuery, GetUserProfilesInputQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserProfilesInputQuery, GetUserProfilesInputQueryVariables>(GetUserProfilesInputDocument, options);
      }
export function useGetUserProfilesInputLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserProfilesInputQuery, GetUserProfilesInputQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserProfilesInputQuery, GetUserProfilesInputQueryVariables>(GetUserProfilesInputDocument, options);
        }
export type GetUserProfilesInputQueryHookResult = ReturnType<typeof useGetUserProfilesInputQuery>;
export type GetUserProfilesInputLazyQueryHookResult = ReturnType<typeof useGetUserProfilesInputLazyQuery>;
export type GetUserProfilesInputQueryResult = Apollo.QueryResult<GetUserProfilesInputQuery, GetUserProfilesInputQueryVariables>;
export const GetItemSortDocument = gql`
    query getItemSort {
  itemSort @client
}
    `;

/**
 * __useGetItemSortQuery__
 *
 * To run a query within a React component, call `useGetItemSortQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItemSortQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetItemSortQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetItemSortQuery(baseOptions?: Apollo.QueryHookOptions<GetItemSortQuery, GetItemSortQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetItemSortQuery, GetItemSortQueryVariables>(GetItemSortDocument, options);
      }
export function useGetItemSortLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetItemSortQuery, GetItemSortQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetItemSortQuery, GetItemSortQueryVariables>(GetItemSortDocument, options);
        }
export type GetItemSortQueryHookResult = ReturnType<typeof useGetItemSortQuery>;
export type GetItemSortLazyQueryHookResult = ReturnType<typeof useGetItemSortLazyQuery>;
export type GetItemSortQueryResult = Apollo.QueryResult<GetItemSortQuery, GetItemSortQueryVariables>;
export const GenerateSignedUploadDocument = gql`
    mutation generateSignedUpload {
  generateSignedUpload(input: {kind: IMAGE}) {
    signedUrl
    completionToken
    error {
      code
    }
  }
}
    `;
export type GenerateSignedUploadMutationFn = Apollo.MutationFunction<GenerateSignedUploadMutation, GenerateSignedUploadMutationVariables>;

/**
 * __useGenerateSignedUploadMutation__
 *
 * To run a mutation, you first call `useGenerateSignedUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateSignedUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateSignedUploadMutation, { data, loading, error }] = useGenerateSignedUploadMutation({
 *   variables: {
 *   },
 * });
 */
export function useGenerateSignedUploadMutation(baseOptions?: Apollo.MutationHookOptions<GenerateSignedUploadMutation, GenerateSignedUploadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateSignedUploadMutation, GenerateSignedUploadMutationVariables>(GenerateSignedUploadDocument, options);
      }
export type GenerateSignedUploadMutationHookResult = ReturnType<typeof useGenerateSignedUploadMutation>;
export type GenerateSignedUploadMutationResult = Apollo.MutationResult<GenerateSignedUploadMutation>;
export type GenerateSignedUploadMutationOptions = Apollo.BaseMutationOptions<GenerateSignedUploadMutation, GenerateSignedUploadMutationVariables>;
export const GetItemLayoutDocument = gql`
    query getItemLayout {
  itemLayout @client
}
    `;

/**
 * __useGetItemLayoutQuery__
 *
 * To run a query within a React component, call `useGetItemLayoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItemLayoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetItemLayoutQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetItemLayoutQuery(baseOptions?: Apollo.QueryHookOptions<GetItemLayoutQuery, GetItemLayoutQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetItemLayoutQuery, GetItemLayoutQueryVariables>(GetItemLayoutDocument, options);
      }
export function useGetItemLayoutLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetItemLayoutQuery, GetItemLayoutQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetItemLayoutQuery, GetItemLayoutQueryVariables>(GetItemLayoutDocument, options);
        }
export type GetItemLayoutQueryHookResult = ReturnType<typeof useGetItemLayoutQuery>;
export type GetItemLayoutLazyQueryHookResult = ReturnType<typeof useGetItemLayoutLazyQuery>;
export type GetItemLayoutQueryResult = Apollo.QueryResult<GetItemLayoutQuery, GetItemLayoutQueryVariables>;
export const GetInitialInfoDocument = gql`
    query getInitialInfo {
  viewer {
    isAuthenticated
    user {
      id
    }
    profile {
      id
      slug
      name
      image {
        fixed(input: {ratio: 1, width: 125})
      }
    }
  }
}
    `;

/**
 * __useGetInitialInfoQuery__
 *
 * To run a query within a React component, call `useGetInitialInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInitialInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInitialInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInitialInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetInitialInfoQuery, GetInitialInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInitialInfoQuery, GetInitialInfoQueryVariables>(GetInitialInfoDocument, options);
      }
export function useGetInitialInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInitialInfoQuery, GetInitialInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInitialInfoQuery, GetInitialInfoQueryVariables>(GetInitialInfoDocument, options);
        }
export type GetInitialInfoQueryHookResult = ReturnType<typeof useGetInitialInfoQuery>;
export type GetInitialInfoLazyQueryHookResult = ReturnType<typeof useGetInitialInfoLazyQuery>;
export type GetInitialInfoQueryResult = Apollo.QueryResult<GetInitialInfoQuery, GetInitialInfoQueryVariables>;
export const GetProfileCollectionDocument = gql`
    query getProfileCollection($input: CollectionSearchInput!, $orderBy: [ListItemsOrderBy]! = TITLE, $first: Int! = 0, $last: Int! = 0, $after: String! = "", $before: String! = "") {
  viewer {
    isAuthenticated
    profile {
      id
      slug
    }
  }
  collection(input: $input) @connection(key: "collection") {
    id
    title
    slug
    image {
      ...ItemImage
    }
    profile {
      id
      slug
      user {
        id
      }
      viewerCanEditList
    }
    ...ItemPagination
  }
}
    ${ItemImageFragmentDoc}
${ItemPaginationFragmentDoc}`;

/**
 * __useGetProfileCollectionQuery__
 *
 * To run a query within a React component, call `useGetProfileCollectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileCollectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileCollectionQuery({
 *   variables: {
 *      input: // value for 'input'
 *      orderBy: // value for 'orderBy'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useGetProfileCollectionQuery(baseOptions: Apollo.QueryHookOptions<GetProfileCollectionQuery, GetProfileCollectionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProfileCollectionQuery, GetProfileCollectionQueryVariables>(GetProfileCollectionDocument, options);
      }
export function useGetProfileCollectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProfileCollectionQuery, GetProfileCollectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProfileCollectionQuery, GetProfileCollectionQueryVariables>(GetProfileCollectionDocument, options);
        }
export type GetProfileCollectionQueryHookResult = ReturnType<typeof useGetProfileCollectionQuery>;
export type GetProfileCollectionLazyQueryHookResult = ReturnType<typeof useGetProfileCollectionLazyQuery>;
export type GetProfileCollectionQueryResult = Apollo.QueryResult<GetProfileCollectionQuery, GetProfileCollectionQueryVariables>;
export const GetUncollectedItemsDocument = gql`
    query getUncollectedItems($input: ProfileSearchInput!, $orderBy: [ListItemsOrderBy]! = TITLE, $first: Int! = 0, $last: Int! = 0, $after: String! = "", $before: String! = "") {
  viewer {
    isAuthenticated
    profile {
      id
      slug
    }
  }
  profile(input: $input) @connection(key: "profile") {
    id
    viewerCanEditList
    ...ProfileThumbnail
    uncollected {
      ...ItemPagination
    }
    user {
      id
    }
  }
}
    ${ProfileThumbnailFragmentDoc}
${ItemPaginationFragmentDoc}`;

/**
 * __useGetUncollectedItemsQuery__
 *
 * To run a query within a React component, call `useGetUncollectedItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUncollectedItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUncollectedItemsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      orderBy: // value for 'orderBy'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useGetUncollectedItemsQuery(baseOptions: Apollo.QueryHookOptions<GetUncollectedItemsQuery, GetUncollectedItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUncollectedItemsQuery, GetUncollectedItemsQueryVariables>(GetUncollectedItemsDocument, options);
      }
export function useGetUncollectedItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUncollectedItemsQuery, GetUncollectedItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUncollectedItemsQuery, GetUncollectedItemsQueryVariables>(GetUncollectedItemsDocument, options);
        }
export type GetUncollectedItemsQueryHookResult = ReturnType<typeof useGetUncollectedItemsQuery>;
export type GetUncollectedItemsLazyQueryHookResult = ReturnType<typeof useGetUncollectedItemsLazyQuery>;
export type GetUncollectedItemsQueryResult = Apollo.QueryResult<GetUncollectedItemsQuery, GetUncollectedItemsQueryVariables>;
export const GetExploreFeedDocument = gql`
    query getExploreFeed($first: Int! = 15, $after: String! = "", $last: Int! = 0, $before: String! = "", $orderBy: [ListItemsOrderBy]! = UPDATED) {
  feed {
    generic {
      ... on GenericFeed {
        ...ItemPagination
      }
    }
  }
}
    ${ItemPaginationFragmentDoc}`;

/**
 * __useGetExploreFeedQuery__
 *
 * To run a query within a React component, call `useGetExploreFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExploreFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExploreFeedQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetExploreFeedQuery(baseOptions?: Apollo.QueryHookOptions<GetExploreFeedQuery, GetExploreFeedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetExploreFeedQuery, GetExploreFeedQueryVariables>(GetExploreFeedDocument, options);
      }
export function useGetExploreFeedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExploreFeedQuery, GetExploreFeedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetExploreFeedQuery, GetExploreFeedQueryVariables>(GetExploreFeedDocument, options);
        }
export type GetExploreFeedQueryHookResult = ReturnType<typeof useGetExploreFeedQuery>;
export type GetExploreFeedLazyQueryHookResult = ReturnType<typeof useGetExploreFeedLazyQuery>;
export type GetExploreFeedQueryResult = Apollo.QueryResult<GetExploreFeedQuery, GetExploreFeedQueryVariables>;
export const GetFollowingFeedDocument = gql`
    query getFollowingFeed($first: Int! = 15, $after: String! = "", $last: Int! = 0, $before: String! = "", $orderBy: [ListItemsOrderBy]! = UPDATED) {
  viewer {
    isAuthenticated
    profile {
      id
      slug
    }
    user {
      following(orderBy: UPDATED, first: 10) {
        total
        ...RecentlyUpdatedProfiles
      }
    }
  }
  feed {
    tailored {
      ...ItemPagination
    }
  }
}
    ${RecentlyUpdatedProfilesFragmentDoc}
${ItemPaginationFragmentDoc}`;

/**
 * __useGetFollowingFeedQuery__
 *
 * To run a query within a React component, call `useGetFollowingFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFollowingFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFollowingFeedQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetFollowingFeedQuery(baseOptions?: Apollo.QueryHookOptions<GetFollowingFeedQuery, GetFollowingFeedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFollowingFeedQuery, GetFollowingFeedQueryVariables>(GetFollowingFeedDocument, options);
      }
export function useGetFollowingFeedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFollowingFeedQuery, GetFollowingFeedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFollowingFeedQuery, GetFollowingFeedQueryVariables>(GetFollowingFeedDocument, options);
        }
export type GetFollowingFeedQueryHookResult = ReturnType<typeof useGetFollowingFeedQuery>;
export type GetFollowingFeedLazyQueryHookResult = ReturnType<typeof useGetFollowingFeedLazyQuery>;
export type GetFollowingFeedQueryResult = Apollo.QueryResult<GetFollowingFeedQuery, GetFollowingFeedQueryVariables>;
export const GetItemDocument = gql`
    query getItem($input: ItemSearchInput!) {
  viewer {
    ...ViewerReserved
    isAuthenticated
    profile {
      id
      slug
      user {
        id
      }
    }
  }
  item(input: $input) {
    ...ItemData
    profile {
      id
      slug
      collections {
        edges {
          node {
            id
            slug
            title
          }
        }
      }
    }
  }
}
    ${ViewerReservedFragmentDoc}
${ItemDataFragmentDoc}`;

/**
 * __useGetItemQuery__
 *
 * To run a query within a React component, call `useGetItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetItemQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetItemQuery(baseOptions: Apollo.QueryHookOptions<GetItemQuery, GetItemQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetItemQuery, GetItemQueryVariables>(GetItemDocument, options);
      }
export function useGetItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetItemQuery, GetItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetItemQuery, GetItemQueryVariables>(GetItemDocument, options);
        }
export type GetItemQueryHookResult = ReturnType<typeof useGetItemQuery>;
export type GetItemLazyQueryHookResult = ReturnType<typeof useGetItemLazyQuery>;
export type GetItemQueryResult = Apollo.QueryResult<GetItemQuery, GetItemQueryVariables>;
export const UpdateItemDocument = gql`
    mutation updateItem($input: UpdateListItemInput!) {
  updateListItem(input: $input) {
    listItem {
      ...ItemData
    }
  }
}
    ${ItemDataFragmentDoc}`;
export type UpdateItemMutationFn = Apollo.MutationFunction<UpdateItemMutation, UpdateItemMutationVariables>;

/**
 * __useUpdateItemMutation__
 *
 * To run a mutation, you first call `useUpdateItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateItemMutation, { data, loading, error }] = useUpdateItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateItemMutation, UpdateItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateItemMutation, UpdateItemMutationVariables>(UpdateItemDocument, options);
      }
export type UpdateItemMutationHookResult = ReturnType<typeof useUpdateItemMutation>;
export type UpdateItemMutationResult = Apollo.MutationResult<UpdateItemMutation>;
export type UpdateItemMutationOptions = Apollo.BaseMutationOptions<UpdateItemMutation, UpdateItemMutationVariables>;
export const ReserveItemDocument = gql`
    mutation reserveItem($input: ReserveListItemInput!) {
  reserveListItem(input: $input) {
    listItem {
      ...ItemData
    }
  }
}
    ${ItemDataFragmentDoc}`;
export type ReserveItemMutationFn = Apollo.MutationFunction<ReserveItemMutation, ReserveItemMutationVariables>;

/**
 * __useReserveItemMutation__
 *
 * To run a mutation, you first call `useReserveItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReserveItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reserveItemMutation, { data, loading, error }] = useReserveItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReserveItemMutation(baseOptions?: Apollo.MutationHookOptions<ReserveItemMutation, ReserveItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReserveItemMutation, ReserveItemMutationVariables>(ReserveItemDocument, options);
      }
export type ReserveItemMutationHookResult = ReturnType<typeof useReserveItemMutation>;
export type ReserveItemMutationResult = Apollo.MutationResult<ReserveItemMutation>;
export type ReserveItemMutationOptions = Apollo.BaseMutationOptions<ReserveItemMutation, ReserveItemMutationVariables>;
export const CopyItemDocument = gql`
    mutation copyItem($input: CopyListItemInput!) {
  copyListItem(input: $input) {
    listItem {
      ...ItemData
    }
  }
}
    ${ItemDataFragmentDoc}`;
export type CopyItemMutationFn = Apollo.MutationFunction<CopyItemMutation, CopyItemMutationVariables>;

/**
 * __useCopyItemMutation__
 *
 * To run a mutation, you first call `useCopyItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyItemMutation, { data, loading, error }] = useCopyItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCopyItemMutation(baseOptions?: Apollo.MutationHookOptions<CopyItemMutation, CopyItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CopyItemMutation, CopyItemMutationVariables>(CopyItemDocument, options);
      }
export type CopyItemMutationHookResult = ReturnType<typeof useCopyItemMutation>;
export type CopyItemMutationResult = Apollo.MutationResult<CopyItemMutation>;
export type CopyItemMutationOptions = Apollo.BaseMutationOptions<CopyItemMutation, CopyItemMutationVariables>;
export const GetPurchasedItemsDocument = gql`
    query getPurchasedItems($input: ProfileSearchInput!, $first: Int! = 0, $after: String! = "", $last: Int! = 0, $before: String! = "", $orderBy: [ListItemsOrderBy]! = TITLE) {
  viewer {
    isAuthenticated
    profile {
      id
      slug
    }
  }
  profile(input: $input) @connection(key: "profile") {
    id
    slug
    name
    viewerCanEditList
    ...ProfileThumbnail
    archived {
      ... on ProfileArchived {
        ...ItemPagination
      }
    }
    user {
      id
    }
  }
}
    ${ProfileThumbnailFragmentDoc}
${ItemPaginationFragmentDoc}`;

/**
 * __useGetPurchasedItemsQuery__
 *
 * To run a query within a React component, call `useGetPurchasedItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPurchasedItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPurchasedItemsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetPurchasedItemsQuery(baseOptions: Apollo.QueryHookOptions<GetPurchasedItemsQuery, GetPurchasedItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPurchasedItemsQuery, GetPurchasedItemsQueryVariables>(GetPurchasedItemsDocument, options);
      }
export function useGetPurchasedItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPurchasedItemsQuery, GetPurchasedItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPurchasedItemsQuery, GetPurchasedItemsQueryVariables>(GetPurchasedItemsDocument, options);
        }
export type GetPurchasedItemsQueryHookResult = ReturnType<typeof useGetPurchasedItemsQuery>;
export type GetPurchasedItemsLazyQueryHookResult = ReturnType<typeof useGetPurchasedItemsLazyQuery>;
export type GetPurchasedItemsQueryResult = Apollo.QueryResult<GetPurchasedItemsQuery, GetPurchasedItemsQueryVariables>;
export const GetProfilesDocument = gql`
    query getProfiles($search: String!, $count: Int! = 10000) {
  viewer {
    profile {
      id
      slug
    }
  }
  profiles(orderBy: UPDATED, first: $count, search: $search) {
    edges {
      node {
        ...ProfileData
      }
    }
  }
}
    ${ProfileDataFragmentDoc}`;

/**
 * __useGetProfilesQuery__
 *
 * To run a query within a React component, call `useGetProfilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfilesQuery({
 *   variables: {
 *      search: // value for 'search'
 *      count: // value for 'count'
 *   },
 * });
 */
export function useGetProfilesQuery(baseOptions: Apollo.QueryHookOptions<GetProfilesQuery, GetProfilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProfilesQuery, GetProfilesQueryVariables>(GetProfilesDocument, options);
      }
export function useGetProfilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProfilesQuery, GetProfilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProfilesQuery, GetProfilesQueryVariables>(GetProfilesDocument, options);
        }
export type GetProfilesQueryHookResult = ReturnType<typeof useGetProfilesQuery>;
export type GetProfilesLazyQueryHookResult = ReturnType<typeof useGetProfilesLazyQuery>;
export type GetProfilesQueryResult = Apollo.QueryResult<GetProfilesQuery, GetProfilesQueryVariables>;
export const GetSettingsDocument = gql`
    query getSettings {
  viewer {
    isAuthenticated
    user {
      proxy_profiles {
        edges {
          node {
            ...ProfileOption
            birthday
          }
        }
      }
      profile {
        ...ProfileOption
        birthday
      }
    }
  }
}
    ${ProfileOptionFragmentDoc}`;

/**
 * __useGetSettingsQuery__
 *
 * To run a query within a React component, call `useGetSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSettingsQuery(baseOptions?: Apollo.QueryHookOptions<GetSettingsQuery, GetSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSettingsQuery, GetSettingsQueryVariables>(GetSettingsDocument, options);
      }
export function useGetSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSettingsQuery, GetSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSettingsQuery, GetSettingsQueryVariables>(GetSettingsDocument, options);
        }
export type GetSettingsQueryHookResult = ReturnType<typeof useGetSettingsQuery>;
export type GetSettingsLazyQueryHookResult = ReturnType<typeof useGetSettingsLazyQuery>;
export type GetSettingsQueryResult = Apollo.QueryResult<GetSettingsQuery, GetSettingsQueryVariables>;
export const UpdateProfileDocument = gql`
    mutation updateProfile($input: UpdateProfileInput!) {
  updateProfile(input: $input) {
    profile {
      id
      slug
      name
      birthday
      image {
        fixed(input: {ratio: 1, width: 125})
      }
    }
    error {
      code
      message
    }
  }
}
    `;
export type UpdateProfileMutationFn = Apollo.MutationFunction<UpdateProfileMutation, UpdateProfileMutationVariables>;

/**
 * __useUpdateProfileMutation__
 *
 * To run a mutation, you first call `useUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileMutation, { data, loading, error }] = useUpdateProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProfileMutation, UpdateProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProfileMutation, UpdateProfileMutationVariables>(UpdateProfileDocument, options);
      }
export type UpdateProfileMutationHookResult = ReturnType<typeof useUpdateProfileMutation>;
export type UpdateProfileMutationResult = Apollo.MutationResult<UpdateProfileMutation>;
export type UpdateProfileMutationOptions = Apollo.BaseMutationOptions<UpdateProfileMutation, UpdateProfileMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation changePassword($input: ChangePasswordInput!) {
  changePassword(input: $input) {
    user {
      id
    }
    error {
      code
      message
    }
  }
}
    `;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const NewAccountDocument = gql`
    mutation newAccount($data: CreateAccountInput!) {
  createAccount(input: $data) {
    ... on CreateAccountPayload {
      profile {
        id
        slug
        name
        url
      }
    }
    ... on CreateAccountError {
      error
    }
  }
}
    `;
export type NewAccountMutationFn = Apollo.MutationFunction<NewAccountMutation, NewAccountMutationVariables>;

/**
 * __useNewAccountMutation__
 *
 * To run a mutation, you first call `useNewAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newAccountMutation, { data, loading, error }] = useNewAccountMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useNewAccountMutation(baseOptions?: Apollo.MutationHookOptions<NewAccountMutation, NewAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NewAccountMutation, NewAccountMutationVariables>(NewAccountDocument, options);
      }
export type NewAccountMutationHookResult = ReturnType<typeof useNewAccountMutation>;
export type NewAccountMutationResult = Apollo.MutationResult<NewAccountMutation>;
export type NewAccountMutationOptions = Apollo.BaseMutationOptions<NewAccountMutation, NewAccountMutationVariables>;
export const GetProfileDocument = gql`
    query getProfile($input: ProfileSearchInput!) {
  viewer {
    isAuthenticated
    profile {
      id
      slug
    }
    user {
      proxy_profiles {
        total
      }
    }
  }
  profile(input: $input) @connection(key: "profile") {
    id
    slug
    url
    name
    bio
    image {
      fixed(input: {ratio: 1, width: 125})
    }
    viewerIsFollowing
    viewerCanEditList
    listItems {
      total
    }
    followers {
      total
    }
    user {
      id
      following {
        total
      }
    }
  }
}
    `;

/**
 * __useGetProfileQuery__
 *
 * To run a query within a React component, call `useGetProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetProfileQuery(baseOptions: Apollo.QueryHookOptions<GetProfileQuery, GetProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProfileQuery, GetProfileQueryVariables>(GetProfileDocument, options);
      }
export function useGetProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProfileQuery, GetProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProfileQuery, GetProfileQueryVariables>(GetProfileDocument, options);
        }
export type GetProfileQueryHookResult = ReturnType<typeof useGetProfileQuery>;
export type GetProfileLazyQueryHookResult = ReturnType<typeof useGetProfileLazyQuery>;
export type GetProfileQueryResult = Apollo.QueryResult<GetProfileQuery, GetProfileQueryVariables>;