import React, { useState } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import { gql } from '@apollo/client';
import { dateFormatter } from '../../lib/utils';
import { ProfileLink } from '../Profile/Link';
import cloneDeep from 'lodash/cloneDeep';
import Input from '../Input';
import { useAuthentication } from '../../lib/hooks';
import { Switch } from '@headlessui/react';
import {
  ItemCommentFragment,
  useCreateCommentMutation,
} from '../../generated/graphql';
import mixpanel from 'mixpanel-browser';

interface IFormProps {
  itemId: string;
}

const Form = ({ itemId }: IFormProps) => {
  const { itemSlug } = useParams();

  const CHARACTER_LIMIT = 500;
  const [text, setText] = useState('');
  const [isAnon, setAnon] = useState(false);
  const [addComment] = useCreateCommentMutation({
    onCompleted: () => {
      mixpanel.track('Add Comment');
    },
  });
  const remainingChars = CHARACTER_LIMIT - text.length;

  const submitComment = e => {
    e.preventDefault();

    addComment({
      variables: {
        input: {
          itemId: itemId,
          anonymous: isAnon,
          content: text,
        },
      },
      update: (store, { data }) => {
        if (data?.createListItemComment?.comment) {
          store.modify({
            id: store.identify({
              id: itemId,
              slug: itemSlug,
              __typename: 'ListItem',
            }),
            fields: {
              comments(existingComments) {
                const copy = cloneDeep(existingComments);
                copy.edges.push({
                  node: data?.createListItemComment?.comment,
                });
                return copy;
              },
            },
          });
        }
      },
    });

    setText('');
  };

  return (
    <form onSubmit={submitComment} className='mt-3 mb-5'>
      {remainingChars < 0 && (
        <small className='text-primary'>
          Too many characters {remainingChars}
        </small>
      )}
      <Input
        className='w-full'
        value={text}
        placeholder='Add Comment'
        onChange={e => {
          e.preventDefault();
          setText(e.target.value);
        }}
      />
      <div className='flex items-center justify-between py-1'>
        <Switch.Group>
          <div className='flex items-center'>
            <Switch
              type='button'
              checked={isAnon}
              onChange={() => setAnon(!isAnon)}
              className={`${
                isAnon ? 'bg-primary' : 'bg-gray-200'
              } relative inline-flex items-center h-6 rounded-full w-11`}
              data-cy='anonymousBtn'
            >
              <span className='sr-only'>Post Anonymously</span>
              <span
                className={`${
                  isAnon ? 'translate-x-6' : 'translate-x-1'
                } inline-block w-4 h-4 transform bg-white rounded-full`}
              />
            </Switch>
            <Switch.Label className='ml-2 text-sm text-gray-700'>
              Anonymous
            </Switch.Label>
          </div>
        </Switch.Group>

        <button
          className='px-4 py-2 text-sm text-white rounded-md bg-primary'
          disabled={!text.length || remainingChars < 0}
        >
          Post
        </button>
      </div>

      {/* <button type="button" onClick={onCancel} className="btn btn-link text-dark">Cancel</button> */}
    </form>
  );
};

interface ICommentProps {
  comment: ItemCommentFragment;
}

const Comment = ({ comment }: ICommentProps) => {
  const { user, createdAt, content } = comment;
  const [date, setDate] = useState(null);

  dateFormatter(createdAt, res => setDate(res.date));

  return (
    <div className='py-3'>
      <div className='flex items-center justify-between'>
        <div className='mr-1'>
          <div className='text-sm font-medium text-gray'>
            {user ? (
              <ProfileLink
                className='pl-1 text-sm !font-medium !text-gray-900'
                profile={user.profile}
                diameter={24}
              />
            ) : (
              <div className='flex items-center'>
                <div className='w-6 h-6 bg-blue-600 rounded-full'></div>
                <div className='pl-2 font-medium text-gray-900'>Anonymous</div>
              </div>
            )}
          </div>
        </div>
        <div className='text-sm text-gray'>{date}</div>
      </div>
      <div className='pl-8 text-sm break-all text-gray'>{content}</div>
    </div>
  );
};

Comment.fragments = {
  comment: gql`
    fragment ItemComment on ListItemComment {
      content
      createdAt
      user {
        profile {
          id
          name
          slug
          ...ProfileThumbnail
        }
      }
      __typename
    }
    ${ProfileLink.fragments.profile}
  `,
};

const Comments = ({ comments, itemId }) => {
  const isAuthenticated = useAuthentication();
  const location = useLocation();
  const referrer = encodeURIComponent(location.pathname);

  return (
    <div className='py-5 pb-5' data-cy='commentSection'>
      <div className='text-sm font-medium text-gray-800'>Comments</div>
      <div className='divide-y'>
        {comments.map(({ node: comment }, idx) => (
          <Comment key={idx} comment={comment} />
        ))}
      </div>

      {isAuthenticated && <Form itemId={itemId} />}
      {!isAuthenticated && (
        <div className='py-2'>
          <Link
            className='text-blue-500 hover:underline'
            to={`/login?r=${referrer}`}
          >
            Sign In
          </Link>{' '}
          or{' '}
          <Link
            className='text-blue-500 hover:underline'
            to={`/signup?r=${referrer}`}
          >
            Create Account
          </Link>{' '}
          to comment
        </div>
      )}
    </div>
  );
};

Comments.fragments = {
  comments: gql`
    fragment ItemComments on ListItem {
      comments {
        edges {
          node {
            ...ItemComment
          }
        }
      }
    }
    ${Comment.fragments.comment}
  `,
};

gql`
  mutation createComment($input: CreateListItemCommentInput!) {
    createListItemComment(input: $input) {
      comment {
        ...ItemComment
      }
    }
  }
  ${Comment.fragments.comment}
`;

export { Comments };
