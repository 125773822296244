import React from 'react';

interface EmptyStateProps {
  icon: any;
  title: string;
  description: React.ReactNode;
  children: React.ReactNode;
}

export const EmptyState = ({
  title,
  description,
  children,
  icon: Icon,
}: EmptyStateProps) => {
  return (
    <div className='flex flex-col place-items-center'>
      <div>
        <Icon className='w-24 h-24 text-gray-400 stroke-current stroke-0' />
      </div>
      <div className='mt-2 text-2xl md:text-3xl font-bold text-gray-900'>
        {title}
      </div>
      <div className='mt-1 mb-3 text-sm text-gray-500 max-w-md text-center'>
        {description}
      </div>
      {children}
    </div>
  );
};
