import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import Masonry from './Masonry';
import List from './List';
import ItemThumbnail from '../Item/Thumbnail';
import { gql } from '@apollo/client';
import { getSortObj } from '../SortButton';
import { Spinner } from '../Loading';
import { useScrollPosition } from '../../lib/hooks';

const PageLoader = () => (
  <div className='flex justify-center w-full h-10'>
    <Spinner className='text-primary' />
  </div>
);

const DEFAULT_SORTING = {
  type: 'first',
  orderBy: 'UPDATED',
  cursor: 'after',
};

const Items = ({
  items,
  fetchMore,
  sort = DEFAULT_SORTING,
  disablePaging = false,
  layout = 'masonry',
  pageKey,
  ...other
}) => {
  useScrollPosition(pageKey);

  async function getMore() {
    try {
      const obj = getSortObj(
        sort,
        items.pageInfo.endCursor || items.pageInfo.startCursor
      );

      await fetchMore({ variables: { ...obj } });
    } catch (error) {
      console.error(error);
    }
  }

  const { hasNextPage, hasPreviousPage } = items?.pageInfo ?? {};
  const Layout = layout === 'masonry' ? Masonry : List;
  let hasMore = sort?.type === 'last' ? hasPreviousPage : hasNextPage;
  if (disablePaging) {
    hasMore = false;
  }

  return (
    <InfiniteScroll
      loadMore={getMore}
      hasMore={hasMore}
      threshold={1000}
      initialLoad={false}
      loader={<PageLoader key='pageLoader' />}
    >
      <Layout items={items?.edges ?? []} {...other} />
    </InfiniteScroll>
  );
};

Items.fragments = {
  items: gql`
    fragment ItemPagination on HasListItems {
      listItems(
        first: $first
        last: $last
        before: $before
        after: $after
        orderBy: $orderBy
      ) {
        total
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            ...ItemThumbnail
          }
        }
      }
    }
    ${ItemThumbnail.fragments.item}
  `,
};

export default Items;
