import React, { Dispatch, SetStateAction, useState } from 'react';
import ReactGa from 'react-ga';
import UploadModal from '../UploadModal';
import { useImageUpload } from '../../lib/hooks';
import { Spinner } from '../Loading';
import { CollectionSelect } from '../Collection/CollectionSelect';
import Input from '../Input';
import Image from '../Item/Image';
import {
  CollectionTitleFragment,
  ItemDataFragment,
  ListItemCollection,
  Profile,
} from '../../generated/graphql';
import gql from 'graphql-tag';
import { Button, PrimaryButton } from '../Button';

export type EditItemType = Pick<
  ItemDataFragment,
  'id' | 'title' | 'url' | 'collections' | 'image' | 'quantity' | 'notes'
> & {
  profile: Pick<Profile, 'id'> & CollectionTitleFragment;
};
interface EditItemProps {
  item: EditItemType;
  onSave: (p: any) => void;
  setOpen: Dispatch<SetStateAction<boolean>>;
  loading: boolean;
}

const isValidUrl = (url: string): boolean => {
  try {
    new URL(url);
  } catch (_) {
    return false;
  }

  return true;
};

const EditItem = ({ item, onSave, setOpen, loading }: EditItemProps) => {
  const initialCollections = (item?.collections?.edges ?? []).map(
    ({ node: collection }: { node: ListItemCollection }) => {
      return { value: collection.id, label: collection.title };
    }
  );

  const { profile } = item;
  const [title, setTitle] = useState(item.title || '');
  const [url, setUrl] = useState(item.url || '');
  const [imageData, setImageData] = useState({ data: null, buffer: null });
  const [image, setImage] = useState(item.image);
  const [quantity, setQuantity] = useState(item.quantity);
  const [notes, setNotes] = useState(item.notes || '');
  const imageUploadPromise = useImageUpload(imageData.buffer);
  const [collections, setCollections] = useState(initialCollections);
  const [createCollectionPromise, setPromise] = useState(Promise.resolve());

  function onSubmit(e) {
    e.preventDefault();

    Promise.all([imageUploadPromise, createCollectionPromise]).then(
      ([imageToken]) => {
        const payload = {
          title,
          url: url ? url : null,
          imageUrl: image?.source ?? null,
          imageToken: imageToken,
          quantity: Number(quantity),
          collections: collections?.map(coll => coll.value) ?? [],
          notes: notes,
        };

        onSave(payload);

        ReactGa.event({
          category: 'Item',
          action: 'Update Item',
        });
      }
    );
  }

  return (
    <form onSubmit={onSubmit}>
      <div className='flex-col px-4 pb-24 space-y-4'>
        {/* @ts-ignore */}
        <Image
          image={
            imageData.data
              ? { source: imageData.data, tiny: imageData.data }
              : image
          }
          item={item}
          isEditable={true}
          setImageData={setImageData}
        />
        <Input
          label='Title'
          value={title}
          onChange={e => setTitle(e.target.value)}
        />
        <Input
          label='Link'
          value={url}
          onChange={e => setUrl(e.target.value)}
          error={url && !isValidUrl(url) ? 'Invalid Url' : ''}
        />
        <div>
          <label className='text-sm font-medium text-gray-700'>
            Collections
          </label>
          <CollectionSelect
            profileId={profile.id}
            setCollections={setCollections}
            setPromise={setPromise}
            value={collections}
            options={(profile.collections?.edges ?? []).map(
              ({ node: collection }: { node: ListItemCollection }) => {
                return { value: collection.id, label: collection.title };
              }
            )}
          />
        </div>

        <Input
          label='Quantity'
          value={quantity}
          onChange={e => setQuantity(Number(e.target.value))}
        />

        <Input
          label='Notes'
          value={notes}
          onChange={e => setNotes(e.target.value)}
          placeholder='Size, Color, Etc.'
        />
      </div>
      <div className='absolute inset-x-0 bottom-0 h-20 bg-transparent border-t-2 backdrop-filter backdrop-blur-sm'>
        <div className='flex items-center justify-end h-full px-2 space-x-4'>
          <button
            type='button'
            className='px-4 py-2 bg-white border rounded-md'
            onClick={() => setOpen(false)}
          >
            Cancel
          </button>
          <PrimaryButton
            className='w-44 py-2 text-white rounded-md bg-primary'
            loading={loading}
            type='submit'
          >
            Save
          </PrimaryButton>
        </div>
      </div>
      <UploadModal setImageUrl={setImage} setImageData={setImageData} />
    </form>
  );
};

EditItem.fragments = {
  collection: gql`
    fragment CollectionTitle on Profile {
      collections {
        edges {
          node {
            id
            title
          }
        }
      }
    }
  `,
};

export { EditItem };
