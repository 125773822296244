import { Transition } from '@headlessui/react';
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import ReactDOM from 'react-dom';
import CheckCircleIcon from '@heroicons/react/solid/CheckCircleIcon';
import ErrorIcon from '@heroicons/react/solid/ExclamationCircleIcon';
import XIcon from '@heroicons/react/solid/XIcon';

const appRoot = document.getElementById('root') as HTMLFormElement;

export enum StatusType {
  SUCCESS = 'success',
  DANGER = 'danger',
}

interface StatusToastProps {
  open: boolean;
  onClose: Dispatch<SetStateAction<boolean>>;
  status: StatusType;
  children: React.ReactNode;
  dismiss?: boolean;
}

const ALERTS = {
  [StatusType.SUCCESS]: {
    icon: <CheckCircleIcon className='w-6 h-6 text-green-400' />,
    cls: 'bg-green-50 text-green-800',
    iconCls:
      'text-green-500 hover:bg-green-100 focus:ring-offset-green-50 focus:ring-green-600',
  },
  [StatusType.DANGER]: {
    icon: <ErrorIcon className='w-6 h-6 text-red-600' />,
    cls: 'bg-red-50 text-red-800',
    iconCls:
      'text-red-500 hover:bg-red-100 focus:ring-offset-red-50 focus:ring-red-600',
  },
};

export const StatusToast = ({
  open,
  onClose,
  status,
  children,
  dismiss = true,
}: StatusToastProps) => {
  useEffect(() => {
    let timeout;
    if (open && dismiss) {
      timeout = setTimeout(() => {
        onClose(false);
      }, 3000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [open, onClose, dismiss]);

  return ReactDOM.createPortal(
    <Transition
      appear
      show={open}
      className='fixed z-50 w-full max-w-3xl transform -translate-x-1/2 top-20 md:top-10 left-1/2'
      enter='transition duration-200 ease-out transform'
      enterFrom='transform opacity-0 -translate-y-10'
      enterTo='transform opacity-100'
    >
      <div className='fixed w-full max-w-3xl transform -translate-x-1/2 left-1/2'>
        <div
          className={`flex p-2 w-full px-3 text-white ${ALERTS[status].cls} rounded-lg shadow-lg place-items-center`}
        >
          <div className='flex-shrink-0'>{ALERTS[status].icon}</div>
          <div className='ml-3 text-sm font-medium'>{children}</div>
          <div className='pl-3 ml-auto'>
            <div className='-mx-1.5 -my-1.5'>
              <button
                type='button'
                className={`inline-flex rounded-md p-1.5 ${ALERTS[status].iconCls} focus:outline-none focus:ring-2 focus:ring-offset-2`}
                onClick={() => onClose(false)}
              >
                <span className='sr-only'>Dismiss</span>
                <XIcon className='w-5 h-5' aria-hidden='true' />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Transition>,
    appRoot
  );
};
