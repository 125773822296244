import React from 'react';
import ArrowLeft from '@heroicons/react/solid/ArrowLeftIcon';
import { useHistory } from 'react-router';

export default function Disclaimer() {
  const history = useHistory();

  return (
    <div className='relative px-2 py-5 text-xs text-center text-gray-500 md:border-b'>
      <button
        className='absolute items-center hidden md:flex group'
        onClick={() => history.goBack()}
      >
        <ArrowLeft className='w-5 h-5 mr-3 duration-500 ease-out transform group-hover:-translate-x-1.5' />
        <span className='text-gray-600'>Back</span>
      </button>

      <div className='max-w-xl mx-auto lg:max-w-2xl'>
        Giftory is reader-supported. When you buy through links on our site, we
        may earn an affiliate commission.
      </div>
      <div></div>
    </div>
  );
}
