import React from 'react';
import { gql } from '@apollo/client';
import { Loading } from '../../components/Loading';
import Items from '../../components/Items';
import isEmpty from 'lodash/isEmpty';
import { useGetExploreFeedQuery } from '../../generated/graphql';
import { usePageView } from 'src/lib/hooks';

function Explore() {
  usePageView('Explore');
  const { data, error, fetchMore } = useGetExploreFeedQuery({
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-first',
  });

  if (isEmpty(data?.feed?.generic?.listItems?.edges)) {
    return <Loading />;
  }

  if (error) {
    console.error(error);
  }

  return (
    <Items
      fetchMore={fetchMore}
      items={data?.feed.generic?.listItems}
      pageKey='explore'
    />
  );
}

gql`
  query getExploreFeed(
    $first: Int! = 15
    $after: String! = ""
    $last: Int! = 0
    $before: String! = ""
    $orderBy: [ListItemsOrderBy]! = UPDATED
  ) {
    feed {
      generic {
        ... on GenericFeed {
          ...ItemPagination
        }
      }
    }
  }
  ${Items.fragments.items}
`;

export default Explore;
