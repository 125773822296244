import React from 'react';
import { gql } from '@apollo/client';
import { Link, useParams } from 'react-router-dom';
import { DefaultImage, Image } from '../Item/Image';
import { Loading } from '../Loading';
import {
  CollectionItemsFragment,
  ItemImageFragment,
  useGetProfileCollectionsQuery,
} from '../../generated/graphql';

const imageWidth = {
  1: 'w-full',
  2: 'w-1/2',
  3: 'w-1/3',
  4: 'w-1/4',
};

interface CollectionProps {
  slug: string;
  title: string;
  listItems: CollectionItemsFragment;
  image?: ItemImageFragment;
}

const Collection = ({
  slug: collSlug,
  title,
  listItems,
  image,
}: CollectionProps) => {
  const { slug } = useParams();
  const items = listItems.edges?.slice(0, 3) || [];

  return (
    <div>
      <Link
        to={`/wishlist/${slug}/collection/${collSlug}`}
        className='relative z-10 flex overflow-hidden h-36 rounded-2xl'
      >
        {image && (
          <Image
            className={`w-full object-cover duration-200 ease-out transform hover:scale-110`}
            image={image}
            item={{ id: '' }}
          />
        )}

        {!image &&
          items.map(({ node: item }: any, idx) => (
            <div
              key={item.id}
              className={`relative ${imageWidth[items.length]}`}
            >
              <Image
                className={`h-full w-full object-cover`}
                image={item.image}
                item={item}
              />
              {idx % 2 === 1 && (
                <div className='absolute top-0 w-full h-full bg-gradient-to-b from-transparent to-gray opacity-20'></div>
              )}
            </div>
          ))}

        {!items.length && !image && (
          <DefaultImage
            className='object-cover w-full duration-200 ease-out transform hover:scale-110'
            str={title}
          />
        )}
      </Link>
      <div className='text-xl font-bold truncate'>{title}</div>
      <div className='text-sm text-gray'>Items: {listItems.total}</div>
    </div>
  );
};

Collection.fragments = {
  listItems: gql`
    fragment CollectionItems on ListItemsConnection {
      total
      edges {
        node {
          id
          slug
          image {
            ...ItemImage
          }
        }
      }
    }
    ${Image.fragments.image}
  `,
};

const Collections = () => {
  const { slug } = useParams();
  const { data, error } = useGetProfileCollectionsQuery({
    variables: { input: { slug: slug } },
    fetchPolicy: 'cache-and-network',
    returnPartialData: true,
  });

  if (
    !data?.profile?.collections?.edges ||
    !data?.profile?.uncollected?.listItems?.edges
  ) {
    return <Loading />;
  }

  if (error) {
    console.log('Error', error);
  }

  return (
    <div className='grid grid-cols-2 gap-8 lg:grid-cols-3 xl:grid-cols-4'>
      {data.profile.collections.edges.map(({ node: collection }: any) => (
        <Collection
          key={collection.id}
          image={collection.image}
          {...collection}
        />
      ))}
      {(!!data?.profile.uncollected.listItems?.total ||
        data.profile.viewerCanEditList) && (
        <Collection
          key='uncollected'
          slug='uncollected'
          title='Uncategorized'
          listItems={data.profile.uncollected.listItems}
        />
      )}
    </div>
  );
};

gql`
  query getProfileCollections($input: ProfileSearchInput!) {
    viewer {
      isAuthenticated
      profile {
        id
        slug
      }
    }
    profile(input: $input) @connection(key: "profile") {
      id
      slug
      name
      viewerCanEditList
      uncollected {
        listItems(first: 4, image: true) {
          ...CollectionItems
        }
      }
      collections(orderBy: TITLE) {
        edges {
          node {
            id
            title
            slug
            image {
              ...ItemImage
            }
            listItems(first: 4, image: true) {
              ...CollectionItems
            }
          }
        }
      }
    }
  }
  ${Collection.fragments.listItems}
  ${Image.fragments.image}
`;

export default Collections;
