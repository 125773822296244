import { gql } from '@apollo/client';
import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import { EditItem, EditItemType } from './Forms/editItem';
import Input from './Input';
import { Slideover } from './Slideover';
import { Spinner } from './Loading';
import { Listbox } from '@headlessui/react';
import { ProfileImageProps, ProfileImage } from './Profile/Link';
import ChevronDownIcon from '@heroicons/react/solid/ChevronDownIcon';
import ArrowRight from '@heroicons/react/solid/ArrowRightIcon';
import Thumbnail from './Item/Thumbnail';

import { StatusToast, StatusType } from './Toasts/Status';
import { IAlert } from '../lib/types';
import { Link } from 'react-router-dom';
import {
  CreateListItemInput,
  Profile,
  ProfileImageFragment,
  useAddItemMutation,
  useGetUserProfilesQuery,
  useScrapeUrlMutation,
} from '../generated/graphql';
import mixpanel from 'mixpanel-browser';
import psl from 'psl';
import { extractHostname } from 'src/lib/utils';
import { Button, PrimaryButton } from './Button';

gql`
  mutation scrapeUrl($input: ScrapeUrlInput!) {
    scrapeUrl(input: $input) {
      url
      title
      image {
        tiny: pixelated
        source: max(input: { width: 700 })
        height
        width
      }
    }
  }
`;

gql`
  query getUserProfiles {
    viewer {
      user {
        profile {
          id
          name
          slug
          ...ProfileImage
          collections {
            edges {
              node {
                id
                slug
                title
              }
            }
          }
        }
        proxy_profiles {
          edges {
            node {
              id
              name
              slug
              collections {
                edges {
                  node {
                    id
                    slug
                    title
                  }
                }
              }
              ...ProfileImage
            }
          }
        }
      }
    }
  }
  ${ProfileImage.fragments.profile}
`;

const ItemInput = ({ onSubmit, onChange, value, setValue, onClose }) => {
  const scrapeItem = e => {
    e.preventDefault();
    setValue(e.target.value);

    if (e.target.value) {
      onChange({
        variables: {
          input: {
            raw: e.target.value,
          },
        },
      });
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <Input value={value} onChange={scrapeItem} label='Item Title or Link' />
      <div className='flex justify-end mt-4'>
        <button type='button' className='p-2 px-4 rounded-md' onClick={onClose}>
          Cancel
        </button>
        <PrimaryButton
          type='submit'
          className='flex-grow p-2 px-6 text-white rounded-md md:flex-grow-0 bg-primary'
          disabled={!value}
        >
          Add Item
        </PrimaryButton>
      </div>
    </form>
  );
};

interface AddItemSlideoverProps extends ProfileImageProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  profile: ProfileImageFragment & Pick<Profile, 'id' | 'slug' | 'collections'>;
}

const AddItemSlideover = ({
  open,
  setOpen,
  profile,
}: AddItemSlideoverProps) => {
  const [isPreviewOpen, togglePreview] = useState(false);
  const [value, setValue] = useState('');
  const [alert, setAlert] = useState<IAlert>({
    open: false,
    type: StatusType.SUCCESS,
  });
  const [selectedProfile, setProfile] = useState<any>(profile);
  const { data: userProfiles } = useGetUserProfilesQuery();
  const [scrapeUrl, { data, loading: scrapeLoading }] = useScrapeUrlMutation();
  const [addListItem, { loading: addLoading }] = useAddItemMutation({
    refetchQueries: ['getProfileItems'],
    onCompleted: data => {
      const { title, url } = data.createListItem!.listItem;

      setAlert({
        type: StatusType.SUCCESS,
        open: true,
        message: (
          <span>
            <span>Item successfully added.</span>
            <Link
              to={`/wishlist/${selectedProfile.slug}/${data?.createListItem?.listItem.slug}`}
              className='ml-4 underline'
            >
              View Item
              <ArrowRight className='w-4 h-4 ml-3' />
            </Link>
          </span>
        ),
      });
      mixpanel.track('Item Added', {
        title: title,
        domain: url ? psl.get(extractHostname(url)) : '',
      });
      reset();
    },
    onError: () => {
      setAlert({
        type: StatusType.DANGER,
        open: true,
        message: 'There was an error adding the item',
      });
    },
  });

  useEffect(() => {
    if (userProfiles && profile.id === userProfiles.viewer.user?.profile.id) {
      setProfile(userProfiles.viewer.user.profile);
    }
  }, [userProfiles, profile]);

  const addItem = (item: Omit<CreateListItemInput, 'profileId'>) => {
    addListItem({
      variables: {
        input: {
          ...item,
          profileId: selectedProfile.id,
        },
      },
    });
  };

  const reset = () => {
    setOpen(false);
    togglePreview(false);
    setValue('');
  };

  let editItemProps: EditItemType = {
    id: 'newItem',
    quantity: 1,
    profile: selectedProfile,
  };
  if (data?.scrapeUrl) {
    editItemProps = {
      ...editItemProps,
      ...data.scrapeUrl,
    };
  } else {
    editItemProps = {
      title: value,
      ...editItemProps,
    };
  }

  let profileList: any[] = [];
  if (userProfiles) {
    const proxy_profiles = (
      userProfiles.viewer.user?.proxy_profiles?.edges ?? []
    ).map(n => n?.node);
    profileList = [userProfiles.viewer.user?.profile, ...proxy_profiles];
  }

  let child = (
    <>
      {!!(
        userProfiles && userProfiles?.viewer.user?.proxy_profiles?.edges?.length
      ) && (
        <div className='relative mb-6'>
          <div className='text-sm font-medium text-gray-700'>
            Select a profile
          </div>
          <Listbox value={selectedProfile} onChange={setProfile}>
            <Listbox.Button className='relative flex items-center w-full py-1 pl-3 pr-10 text-left bg-white border border-gray-200 rounded-lg cursor-pointer focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-primary focus-visible:ring-offset-2 focus-visible:border-primary'>
              <ProfileImage profile={selectedProfile} diameter={30} />
              <span className='ml-3 text-sm'>{selectedProfile.name}</span>
              <span className='absolute inset-y-0 right-0 flex items-center pl-4 pr-4 border-l-2 pointer-events-none'>
                <ChevronDownIcon
                  className='w-6 h-6 text-gray-400'
                  aria-hidden='true'
                />
              </span>
            </Listbox.Button>
            <Listbox.Options className='absolute z-20 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
              {profileList.map(profile => (
                <Listbox.Option
                  key={profile.id}
                  value={profile}
                  className='flex items-center px-4 py-2 text-sm text-gray-900 cursor-pointer hover:bg-primary hover:bg-opacity-20'
                >
                  <ProfileImage profile={profile} diameter={30} />
                  <span className='ml-3'>{profile.name}</span>
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Listbox>
        </div>
      )}
      <ItemInput
        value={value}
        setValue={setValue}
        onClose={reset}
        onChange={params => scrapeUrl(params)}
        onSubmit={e => {
          e.preventDefault();
          togglePreview(true);
        }}
      />
    </>
  );

  if (isPreviewOpen) {
    if (scrapeLoading) {
      child = (
        <div className='flex items-center justify-center h-full'>
          <Spinner className='text-primary' />
        </div>
      );
    } else {
      child = (
        <EditItem
          item={editItemProps}
          onSave={addItem}
          setOpen={reset}
          loading={addLoading}
        />
      );
    }
  }

  return (
    <>
      <Slideover
        title='Add New Item'
        open={open}
        setOpen={val => {
          setOpen(val);
          togglePreview(val);
        }}
        description='Get started by adding a link to your item or simply enter the item name.'
      >
        <div className='h-full px-3'>{child}</div>
      </Slideover>
      <StatusToast
        open={alert.open}
        status={alert.type}
        onClose={() => setAlert({ open: false, type: StatusType.SUCCESS })}
      >
        {alert.message}
      </StatusToast>
    </>
  );
};

gql`
  mutation addItem($input: CreateListItemInput!) {
    createListItem(input: $input) {
      profile {
        id
        slug
      }
      listItem {
        ...ItemThumbnail
      }
    }
  }
  ${Thumbnail.fragments.item}
`;

AddItemSlideover.fragments = {
  profile: ProfileImage.fragments.profile,
};

export { AddItemSlideover };
