// @ts-nocheck

import React, { useEffect, Suspense } from 'react';
import { useLocation } from 'react-router-dom';
import SignUp from './pages/signup';
import NotFound from './pages/404';
import client from './lib/apollo';
import ErrorBoundary from 'react-error-boundary';
import { ApolloProvider } from '@apollo/client';
import { Router, Switch, Route, Link } from 'react-router-dom';
import Helmet from 'react-helmet';
import { PillTab } from './components/Tab';

import Home from './pages/index';
import Login from './pages/login';
import Nav from './components/Nav';
import Search from './pages/search';
import FeedExplore from './pages/home';
import Settings from './pages/settings';
import { ReactComponent as ExploreIcon } from './icons/Explore.svg';
import Plus from '@heroicons/react/solid/PlusIcon';
import { ReactComponent as ProfileIcon } from './icons/Profile.svg';
import { ReactComponent as SearchIcon } from './icons/Search.svg';
import { ReactComponent as NotificationsIcon } from './icons/Notifications.svg';
import ErrBoundary from './components/ErrBoundary';
import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga';
import './App.scss';
import { LoginToast } from './components/Toasts/Login';
import { useAuthentication } from './lib/hooks';
import Rounded from './components/Rounded';
import Profile from './pages/wishlist';
import { AddItemSlideover } from './components/AddItemSlidover';
import { useState } from 'react';
import { useGetInitialInfoQuery } from './generated/graphql';
import { ForgotPassword } from './components/Forms/forgotPassword';

const Privacy = React.lazy(() => import('./pages/privacy'));
const Terms = React.lazy(() => import('./pages/terms'));

const hist = createBrowserHistory();

interface ITab {
  active: boolean;
  icon: any;
  component: React.ElementType<any>;
  props: React.ComponentPropsWithoutRef<any>;
  show?: boolean;
}

interface IMobileNavProps {
  slug: string;
  profile: any;
}

hist.listen(location => {
  ReactGA.pageview(location.pathname);
});

const MobileNav = ({ slug, profile }: IMobileNavProps) => {
  const isAuthenticated = useAuthentication();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const isProfileActive =
    location.pathname === `/wishlist/${slug}` ||
    location.pathname === `/login` ||
    location.pathname === `/signup`;

  const PlusIcon = () => (
    <Plus className='w-8 h-8 text-white stroke-current stroke-0' />
  );

  const tabs: ITab[] = [
    {
      active: location.pathname === `/feed` || location.pathname === `/explore`,
      icon: <ExploreIcon />,
      component: Link,
      props: {
        to: '/feed',
      },
    },
    {
      active: location.pathname === `/search`,
      icon: <SearchIcon />,
      component: Link,
      props: {
        to: '/search',
      },
    },
    {
      active: location.pathname === `/wishlist/add`,
      icon: <PlusIcon />,
      component: 'button',
      show: isAuthenticated,
      props: {
        onClick: () => {
          setOpen(true);
        },
        className: 'bg-primary stroke-current text-white',
      },
    },
    {
      active: location.pathname === `/wishlist/${slug}/purchased`,
      icon: <NotificationsIcon />,
      component: Link,
      props: {
        to: slug ? `/wishlist/${slug}/purchased` : '/login',
      },
    },
    {
      active: isProfileActive,
      icon: <ProfileIcon />,
      component: Link,
      props: {
        to: slug ? `/wishlist/${slug}` : '/login',
      },
    },
  ];

  return (
    <div className='fixed bottom-0 z-30 w-full py-3 bg-white border-t-2 md:hidden'>
      <ul className='flex items-center justify-around flex-nowrap'>
        {tabs.map((tab, idx) => (
          <PillTab
            key={idx}
            props={tab.props}
            as={tab.component}
            isActive={tab.active}
            show={tab.show}
          >
            {React.cloneElement(tab.icon, {
              className: `stroke-current fill-current ${
                tab.active ? 'text-primary' : 'text-black'
              }`,
            })}
          </PillTab>
        ))}
      </ul>
      {isAuthenticated && (
        <AddItemSlideover open={open} setOpen={setOpen} profile={profile} />
      )}
    </div>
  );
};

const Routes = () => {
  const { data, loading } = useGetInitialInfoQuery({
    returnPartialData: true,
  });

  if (loading) {
    return null;
  }

  const slug = data.viewer.profile?.slug ?? '';

  return (
    <React.Fragment>
      <Suspense fallback={<div></div>}>
        <Switch>
          <Route exact path='/' component={Home} />
          <Route
            render={() => (
              <div className='pb-16 md:pb-0'>
                <Nav data={data} />
                <Rounded>
                  <div className='md:container'>
                    <ErrorBoundary FallbackComponent={ErrBoundary}>
                      <Switch>
                        <Route path='/login' component={Login} />
                        <Route path='/signup' component={SignUp} />
                        <Route
                          path='/forgotpassword'
                          component={ForgotPassword}
                        />
                        <Route
                          exact
                          path={['/feed', '/explore']}
                          component={FeedExplore}
                        />
                        <Route exact path='/search' component={Search} />
                        <Route path='/wishlist' component={Profile} />
                        <Route exact path='/settings' component={Settings} />
                        <Route exact path='/privacy' component={Privacy} />
                        <Route exact path='/terms' component={Terms} />
                        <Route component={NotFound} />
                      </Switch>
                    </ErrorBoundary>
                  </div>
                </Rounded>
                <MobileNav slug={slug} profile={data.viewer.profile} />
                <LoginToast />
              </div>
            )}
          />
        </Switch>
      </Suspense>
    </React.Fragment>
  );
};

export default function App() {
  return (
    <Router history={hist}>
      <Helmet defaultTitle='Giftory' titleTemplate='Giftory | %s' />
      <ApolloProvider client={client}>
        <ErrorBoundary FallbackComponent={ErrBoundary}>
          <Routes />
        </ErrorBoundary>
      </ApolloProvider>
    </Router>
  );
}
