import React from 'react';
import { Spinner } from '../../components/Loading';
import { Tooltip } from '../../components/Tooltip';

interface OptionProps extends React.ComponentPropsWithoutRef<'button'> {
  label: string;
  className?: string;
  icon?: React.ElementType<any>;
  loading?: boolean;
}

export const Option = ({
  label,
  className,
  loading,
  icon: Icon = () => <></>,
  ...other
}: OptionProps) => {
  return (
    <Tooltip>
      <div className='hidden md:block'>
        <Tooltip.Content>{label}</Tooltip.Content>
      </div>
      <button
        className={`flex items-center w-full p-1 p-2 py-4 text-sm rounded-md overflow-none hover:bg-gray-100 md:py-3.5 md:border md:block md:border-gray-400 md:rounded-md md:w-28 ${className}`}
        {...other}
        data-cy='option'
      >
        {loading ? (
          <div className='flex justify-center w-full'>
            <Spinner className='text-primary w-5 h-5 !m-0' />
          </div>
        ) : (
          <>
            <Icon className='w-5 h-5 mr-3 ml-2 text-gray-600 md:m-0' />
            <div className='text-gray-700 whitespace-nowrap md:hidden'>
              {label}
            </div>
          </>
        )}
      </button>
    </Tooltip>
  );
};
