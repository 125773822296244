import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Password from './password';
import Name from './name';
import Birthday from './birthday';
import Image from './image';
import { gql } from '@apollo/client';
import { Loading } from '../../components/Loading';
import moment from 'moment';
import { ProfileImage } from '../../components/Profile/Link';
import { Label, EditBtn } from '../../components/Utility';
import { StatusToast, StatusType } from '../../components/Toasts/Status';
import { Redirect, useLocation } from 'react-router-dom';
import {
  useGetSettingsQuery,
  ProfileSettingsFragment,
  Profile,
} from '../../generated/graphql';
import { usePageView } from 'src/lib/hooks';
import { ProfileInput } from '../../components/ProfileInput';

export interface Alert {
  open: boolean;
  type: StatusType;
  message?: string;
}

export interface ISettingsProps {
  profile: ProfileSettingsFragment;
  setPage: Dispatch<SetStateAction<string>>;
  setAlert: Dispatch<SetStateAction<Alert>>;
  alert: Alert;
  setProfile: Dispatch<SetStateAction<any>>;
}

const Settings = ({ profile, onChange, setPage, options }) => {
  const { name, birthday } = profile;
  const bday = birthday ? moment(birthday).format('MMMM D') : null;

  return (
    <div>
      <div className='text-2xl font-bold'>Account Settings</div>
      {options.length > 1 && (
        <div className='py-5 max-w-md'>
          <ProfileInput
            label='Selected profile'
            value={profile}
            onChange={onChange}
            options={options}
          />
        </div>
      )}
      <Label text='Profile Image'>
        <div className='flex items-center'>
          <ProfileImage profile={profile} diameter={70} />
          <EditBtn className='ml-2' onClick={() => setPage('image')} />
        </div>
      </Label>
      <Label text='Owner'>
        {name}
        <EditBtn className='ml-2' onClick={() => setPage('name')} />
      </Label>
      <Label text='Birthday'>
        {bday}
        <EditBtn
          className={`${bday ? 'ml-2' : 'ml-0'}`}
          onClick={() => setPage('birthday')}
          text={bday ? 'Edit' : 'Add'}
        />
        <small className='block text-gray'>
          This will notify your friends of your upcoming birthday!
        </small>
      </Label>
      <Label text='Password'>
        <button
          className='p-0 text-primary hover:underline'
          onClick={() => setPage('password')}
        >
          Change Password
        </button>
      </Label>
    </div>
  );
};

Settings.fragments = {
  profile: gql`
    fragment ProfileSettings on Profile {
      id
      slug
      name
      birthday
      ...ProfileImage
    }
    ${ProfileImage.fragments.profile}
  `,
};

gql`
  query getSettings {
    viewer {
      isAuthenticated
      user {
        proxy_profiles {
          edges {
            node {
              ...ProfileOption
              birthday
            }
          }
        }
        profile {
          ...ProfileOption
          birthday
        }
      }
    }
  }
  ${ProfileInput.fragments.profile}
`;

gql`
  mutation updateProfile($input: UpdateProfileInput!) {
    updateProfile(input: $input) {
      profile {
        id
        slug
        name
        birthday
        image {
          fixed(input: { ratio: 1, width: 125 })
        }
      }
      error {
        code
        message
      }
    }
  }
`;

export default () => {
  usePageView('Settings');
  const location = useLocation();
  const [page, setPage] = useState('settings');
  const [profile, setProfile] = useState<any>(null);
  const [alert, setAlert] = useState<Alert>({
    open: false,
    type: StatusType.SUCCESS,
  });
  const { data, loading } = useGetSettingsQuery();

  useEffect(() => {
    if (data?.viewer.user?.profile && !profile) {
      setProfile(data.viewer.user.profile);
    }
  }, [data, profile]);

  if (loading || !profile) {
    return <Loading />;
  }

  if (!data?.viewer?.user) {
    return (
      <Redirect to={`/login?r=${encodeURIComponent(location.pathname)}`} />
    );
  }

  const proxy_profiles = data.viewer.user.proxy_profiles?.edges ?? [];
  const options = [
    data.viewer.user!.profile,
    ...proxy_profiles.map(({ node: profile }: any) => profile),
  ];

  const props = {
    setPage: setPage,
    setAlert: setAlert,
    alert: alert,
    setProfile: setProfile,
  };

  const pages = {
    settings: (
      <Settings
        {...props}
        profile={profile}
        onChange={setProfile}
        options={options}
      />
    ),
    password: <Password {...props} />,
    name: <Name {...props} profile={profile} />,
    birthday: <Birthday {...props} profile={profile} />,
    image: <Image {...props} profile={profile} />,
  };

  return (
    <div className='max-w-5xl px-3 pt-3 mx-auto md:pt-0 md:px-0'>
      {pages[page]}
      <StatusToast
        open={alert.open}
        onClose={() => setAlert({ open: false, type: StatusType.SUCCESS })}
        status={alert.type}
      >
        {alert.message}
      </StatusToast>
    </div>
  );
};
