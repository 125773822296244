import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import Wishlist from './wishlist';
import Item from '../item';
import Purchased from '../purchased';

export default () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/:slug/purchased`} component={Purchased} />
      <Route exact path={`${path}/:profileSlug/:itemSlug`} component={Item} />
      <Route path={`${path}/:slug`} component={Wishlist} />
    </Switch>
  );
};
