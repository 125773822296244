import React, { useRef } from 'react';
import { gql } from '@apollo/client';
import { Link } from 'react-router-dom';
import { Loading } from '../../components/Loading';
import { ProfileThumbnail } from '../../components/Profile/Link';
import isEmpty from 'lodash/isEmpty';
import Items from '../../components/Items';
import {
  RecentlyUpdatedProfileFragment,
  RecentlyUpdatedProfilesFragment,
  useGetFollowingFeedQuery,
} from '../../generated/graphql';
import { usePageView } from '../../lib/hooks';
import { EmptyState } from 'src/components/EmptyState';
import { ReactComponent as PresentIcon } from '../../icons/EmptyCase/present.svg';
import { InviteInput } from 'src/components/InviteInput';

interface RecentProfilesProps {
  profiles: RecentlyUpdatedProfilesFragment;
  total: number;
  slug: string;
}

const RecentProfiles = ({ profiles, total, slug }: RecentProfilesProps) => {
  if (!profiles.edges?.length) {
    return null;
  }

  return (
    <div className='flex pb-6 space-x-4 overflow-auto flex-nowrap mt-2'>
      {profiles.edges.map(
        ({ node: profile }: { node: RecentlyUpdatedProfileFragment }) => {
          return (
            <div key={profile.id} className='w-14'>
              <ProfileThumbnail profile={profile} diameter={48} />
            </div>
          );
        }
      )}
      {total > 10 && (
        <div className='flex items-center text-center'>
          <Link
            className='text-sm text-blue-600 hover:text-blue-800'
            to={{
              pathname: `/wishlist/${slug}`,
              state: {
                following: true,
              },
            }}
          >
            View All
          </Link>
        </div>
      )}
    </div>
  );
};

RecentProfiles.fragments = {
  profile: gql`
    fragment RecentlyUpdatedProfile on Profile {
      id
      ...ProfileThumbnail
    }
  `,
  profiles: () => {
    return gql`
      fragment RecentlyUpdatedProfiles on UserFollowingTypeConnection {
        edges {
          node {
            ...RecentlyUpdatedProfile
          }
        }
      }
      ${RecentProfiles.fragments.profile}
      ${ProfileThumbnail.fragments.profile}
    `;
  },
};

const NoFollowing = () => (
  <EmptyState
    icon={PresentIcon}
    title='Start following friends!'
    description='Search for users and select follow to customize your feed.'
  >
    <div className='mt-8 text-gray-600 text-sm mb-3'>
      Don't see your friends? Invite them to join Giftory
    </div>
    <InviteInput />
  </EmptyState>
);

function Feed() {
  usePageView('Feed');
  const { data, loading, error, fetchMore } = useGetFollowingFeedQuery({
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-first',
    returnPartialData: true,
  });

  if (error) {
    console.error(error);
    return 'There was a problem fetching the items';
  }

  if (!data?.viewer.isAuthenticated) {
    return (
      <EmptyState
        icon={PresentIcon}
        title='Welcome!'
        description='Create an account, or log in, to personalize your feed, follow your friends, and create your own wishlist!'
      >
        <div className='mt-4'>
          <Link to='/login' className='text-sm text-gray-500'>
            Log in
          </Link>
          <Link
            to='/signup'
            className='ml-6 bg-primary px-4 py-3 rounded-md text-white text-sm'
          >
            Sign up
          </Link>
        </div>
      </EmptyState>
    );
  }

  if (isEmpty(data?.feed?.tailored?.listItems?.edges) && loading) {
    return <Loading />;
  }

  const filteredItems = data.feed?.tailored?.listItems?.edges;

  if (!filteredItems?.length) {
    return <NoFollowing />;
  }

  return (
    <>
      <RecentProfiles
        profiles={data.viewer?.user?.following ?? {}}
        total={data.viewer.user?.following?.total ?? 0}
        slug={data.viewer.profile!.slug}
      />
      <Items
        fetchMore={fetchMore}
        items={data.feed?.tailored?.listItems ?? {}}
        pageKey='feed'
      />
    </>
  );
}

export default Feed;

gql`
  query getFollowingFeed(
    $first: Int! = 15
    $after: String! = ""
    $last: Int! = 0
    $before: String! = ""
    $orderBy: [ListItemsOrderBy]! = UPDATED
  ) {
    viewer {
      isAuthenticated
      profile {
        id
        slug
      }
      user {
        following(orderBy: UPDATED, first: 10) {
          total
          ...RecentlyUpdatedProfiles
        }
      }
    }
    feed {
      tailored {
        ...ItemPagination
      }
    }
  }
  ${RecentProfiles.fragments.profiles()}
  ${Items.fragments.items}
`;
