import React, { useState, useRef, SetStateAction, useEffect } from 'react';
import { gql } from '@apollo/client';
import { Link, useParams } from 'react-router-dom';

import Image from '../../components/Item/Image';
import { Comments } from '../../components/Item';
import Edit from '../../components/Item/Edit';
import Reserved, {
  getReservationStatus,
  ReservedBtn,
} from '../../components/Item/Reserved';
// import { CopyToClipboard } from 'react-copy-to-clipboard';
import { dateFormatter, extractHostname } from '../../lib/utils';
import psl from 'psl';
import { Loading } from '../../components/Loading';
import isEmpty from 'lodash/isEmpty';
// import { Tooltip } from '../../components/Tooltip';
import CalendarIcon from '@heroicons/react/outline/CalendarIcon';
import DesktopIcon from '@heroicons/react/outline/DesktopComputerIcon';
import PencilIcon from '@heroicons/react/solid/PencilAltIcon';
import CopyIcon from '@heroicons/react/solid/ClipboardCopyIcon';
import TagIcon from '@heroicons/react/solid/TagIcon';
import BagIcon from '@heroicons/react/outline/ShoppingBagIcon';
import CartIcon from '@heroicons/react/outline/ShoppingCartIcon';
// import ShareIcon from '@heroicons/react/solid/UserAddIcon';
import TrashIcon from '@heroicons/react/solid/TrashIcon';
import ChevronDownIcon from '@heroicons/react/outline/ChevronDownIcon';
import { ProfileLink } from '../../components/Profile/Link';
import { StatusToast, StatusType } from '../../components/Toasts/Status';
import DeleteModal from '../../components/Item/DeleteModal';
import { Option } from './option';
import NotFound from '../404';
import Disclaimer from '../../components/AffiliateDisclaimer';
import {
  CollectionListFragment,
  ItemDataFragment,
  ListItemCollection,
  Profile,
  useCopyItemMutation,
  useGetItemQuery,
  useUpdateItemMutation,
  ViewerInfoFragment,
} from '../../generated/graphql';
import { Dispatch } from 'react';
import { usePageView, useScrollToTop } from '../../lib/hooks';
import mixpanel from 'mixpanel-browser';

const AFFILIATES = {
  AMAZON: 'Amazon',
};

interface CollectionListProps {
  collections: CollectionListFragment;
  profileSlug: string;
}

const CollectionList = ({ collections, profileSlug }: CollectionListProps) => {
  if (!collections?.edges?.length) {
    return null;
  }

  const colls = collections.edges.map(
    ({ node: collection }: { node: ListItemCollection }) => (
      <Link
        key={collection.id}
        to={`/wishlist/${profileSlug}/collection/${collection.slug}`}
        className='inline px-4 m-2 text-sm font-medium text-gray-700 bg-gray-200 rounded-full'
      >
        {collection.title}
      </Link>
    )
  );

  return (
    <div className='block py-5'>
      <div className='text-sm font-medium text-gray-800'>Collections</div>
      <div className='flex flex-wrap -ml-2'>{colls}</div>
    </div>
  );
};

CollectionList.fragments = {
  itemCollections: gql`
    fragment CollectionList on ListItemsCollectionsConnection {
      edges {
        node {
          id
          title
          slug
        }
      }
    }
  `,
};

// const ShareButton = ({ title = '' }) => {
//   const [copyText, setText] = useState('Copy Item Link');

//   const share = () => {
//     if (navigator.share) {
//       navigator.share({
//         title: title,
//         text: '',
//         url: window.location.href,
//       });
//     }
//   };

//   const onCopy = () => {
//     setText('Copied!');
//     setTimeout(() => {
//       setText('Copy Item Link');
//     }, 3000);
//   };

//   return (
//     <>
//       <div className='hidden md:block'>
//         <Tooltip>
//           <Tooltip.Content className='h-10 text-xs whitespace-nowrap'>
//             {copyText}
//           </Tooltip.Content>
//           <CopyToClipboard text={window.location.href} onCopy={onCopy}>
//             <Option label='Share' icon={ShareIcon}>
//               <CopyIcon />
//             </Option>
//           </CopyToClipboard>
//         </Tooltip>
//       </div>
//       <div className='md:hidden'>
//         <Option label='Share' onClick={share} icon={ShareIcon} />
//       </div>
//     </>
//   );
// };

const MarkAsPurchasedBtn = ({ itemId, isArchived, profileSlug }) => {
  const [isCompleted, setComplete] = useState(false);
  const [markPurchased, { loading }] = useUpdateItemMutation();

  const onMarkPurchased = () => {
    markPurchased({
      variables: {
        input: {
          itemId: itemId,
          isArchived: true,
        },
      },
    }).then(() => {
      setComplete(true);
    });
  };

  return (
    <>
      <Option
        label='Mark as purchased'
        onClick={onMarkPurchased}
        className={`${isArchived ? 'hidden' : 'block'}`}
        icon={TagIcon}
        loading={loading}
      />
      <StatusToast
        open={isCompleted}
        onClose={setComplete}
        status={StatusType.SUCCESS}
      >
        Item moved to{' '}
        <Link to={`/wishlist/${profileSlug}/purchased`} className='underline'>
          My Purchases
        </Link>
      </StatusToast>
    </>
  );
};

interface AddToListButtonProps {
  itemId: string;
  profile: Pick<Profile, 'id' | 'slug'> | null;
}

const AddToListButton = ({ itemId, profile }: AddToListButtonProps) => {
  const [isCompleted, setComplete] = useState(false);
  const [copyListItem, { data, loading }] = useCopyItemMutation({
    onCompleted: () => {
      mixpanel.track('Copy Item');
      setComplete(true);
    },
  });

  if (!profile) {
    return null;
  }

  const copyItem = () => {
    copyListItem({
      variables: {
        input: {
          profileId: profile.id,
          itemId: itemId,
        },
      },
    });
  };

  return (
    <>
      <Option
        label='Copy to my List'
        onClick={copyItem}
        icon={CopyIcon}
        loading={loading}
      />
      <StatusToast
        open={isCompleted}
        onClose={setComplete}
        status={StatusType.SUCCESS}
      >
        <span className='mr-2'>Item successfully copied</span>
        <Link
          to={`/wishlist/${profile.slug}/${data?.copyListItem?.listItem.slug}`}
          className='underline'
        >
          View Item
        </Link>
      </StatusToast>
    </>
  );
};

interface DeleteButtonProps {
  profile: Pick<Profile, 'id' | 'slug'>;
  itemId: string;
}

const DeleteButton = ({ profile, itemId }: DeleteButtonProps) => {
  const [open, setOpen] = useState(false);

  const TIcon = ({ className }) => (
    <TrashIcon className={`text-red-500 ${className}`} />
  );

  return (
    <>
      <DeleteModal
        open={open}
        setOpen={setOpen}
        profile={profile}
        id={itemId}
      />
      <Option
        label='Delete'
        onClick={() => {
          setOpen(true);
        }}
        icon={TIcon}
      />
    </>
  );
};

const ItemLink = ({ domain, link, url, ...other }) => {
  const outboundLink = useRef(null);

  return (
    <a
      target='_blank'
      rel='noopener noreferrer'
      ref={outboundLink}
      href={url}
      onClick={() => {
        mixpanel.track('Item Click-Through');
      }}
      onMouseDown={() => {
        // @ts-ignore
        outboundLink.current.href = link;
      }}
      onMouseLeave={() => {
        // @ts-ignore
        outboundLink.current.href = url;
      }}
      {...other}
    >
      <CartIcon className='w-5 h-5 mr-3' />
      <span className='text-white'>
        {domain ? `View on ${AFFILIATES[domain.network]}` : 'View Item'}
      </span>
    </a>
  );
};

const isEllipsisActive = e => {
  if (e) {
    return e.scrollHeight > e.clientHeight;
  }

  return false;
};

const Title = ({ title }) => {
  const titleRef = useRef(null);
  const [isExpanded, setExpanded] = useState(false);
  const [isTruncated, setTruncated] = useState(false);

  useEffect(() => {
    setTruncated(isEllipsisActive(titleRef.current));
  }, [title]);

  return (
    <div title={title || ''}>
      <div
        ref={titleRef}
        className={`text-3xl font-bold md:mt-1 break-words ${
          isExpanded ? '' : 'line-clamp-2'
        }`}
      >
        {title}
      </div>
      {isTruncated && (
        <button
          className='text-sm block -mt-.5 text-blue-600 mb-2'
          onClick={() => setExpanded(!isExpanded)}
        >
          {isExpanded ? 'Collapse' : 'Expand'} Title
        </button>
      )}
    </div>
  );
};

interface ItemProps {
  item: ItemDataFragment;
  viewer: ViewerInfoFragment;
  setContext: Dispatch<SetStateAction<string>>;
}

const Item = ({ item, viewer, setContext }: ItemProps) => {
  const {
    id: itemId,
    image,
    title,
    link,
    url,
    profile,
    affiliate,
    createdAt,
    notes,
    quantity,
    collections,
    isArchived,
  } = item;
  const { viewerCanEditList, id } = profile;
  const [date, setDate] = useState(null);
  const [isMenuOpen, setMenuOpen] = useState(false);

  const isChildAccount = viewerCanEditList && viewer.profile?.id !== id;
  const isProxyProfile = item.profile?.user === null;
  const isAuthenticated = viewer.isAuthenticated;
  const reservationStatus = getReservationStatus({
    item,
    viewerCanEditList,
    isChildAccount,
    isProxyProfile,
    isAuthenticated,
  });
  dateFormatter(createdAt, res => setDate(res.date));

  const ItemLinkWrapper = ({ ...other }) => (
    <ItemLink domain={affiliate} link={link} url={url} {...other} />
  );

  const options = [
    {
      label: 'Edit',
      icon: PencilIcon,
      visible: viewerCanEditList,
      onClick: () => setContext('edit'),
    },
    {
      visible: viewerCanEditList && !isArchived,
      element: (
        <MarkAsPurchasedBtn
          key='purchasedButton'
          itemId={itemId}
          isArchived={isArchived}
          profileSlug={profile.slug}
        />
      ),
    },
    {
      visible: !viewerCanEditList && viewer.profile,
      element: (
        <AddToListButton
          key='addtoListButton'
          profile={viewer.profile ?? null}
          itemId={itemId}
        />
      ),
    },
    {
      visible: !isArchived && reservationStatus !== null,
      element: (
        <ReservedBtn key='ReserveBtn' item={item} status={reservationStatus} />
      ),
    },
    // {
    //   visible: true,
    //   element: <ShareButton key='shareButton' />,
    // },
    {
      visible: viewerCanEditList,
      element: (
        <DeleteButton key='DeleteBtn' profile={item.profile} itemId={itemId} />
      ),
    },
  ];

  const info = [
    {
      Icon: DesktopIcon,
      label: url ? psl.get(extractHostname(url)) : null,
    },
    {
      Icon: CalendarIcon,
      label: date,
    },
    {
      Icon: BagIcon,
      label: `Quantity: ${quantity}`,
    },
  ];

  const visibleOptions = options.filter(o => o.visible);

  return (
    <div className='px-3 md:px-0 mb-14 md:mb-0'>
      <Disclaimer />
      <div className='grid max-w-6xl grid-cols-1 py-4 mx-auto md:gap-10 md:grid-cols-2'>
        {/* @ts-ignore */}
        <Image
          image={image}
          uri={link}
          item={item}
          className='md:sticky md:top-0'
        />
        <div className='mt-3 md:mt-0'>
          <ProfileLink profile={profile} />
          <Title title={title} />
          <div className='flex flex-wrap -ml-2 text-gray'>
            {info
              .filter(entry => entry.label)
              .map(({ Icon, label }) => (
                <div key={label} className='flex items-center m-2'>
                  <Icon className='w-5 h-5 mr-2' />
                  <span className='text-sm'>{label}</span>
                </div>
              ))}
          </div>
          {notes && <div className='py-5 text-sm text-gray-700'>{notes}</div>}
          {link && (
            <ItemLinkWrapper className='hidden md:inline-flex justify-center block w-full my-5 px-4 py-3.5 text-sm text-center text-white rounded-md bg-primary' />
          )}
          <div className='hidden md:flex md:justify-start md:space-x-4 md:my-3'>
            {visibleOptions.map(option => {
              if (option.element) return option.element;

              return (
                <Option
                  key={option.label}
                  label={option.label}
                  onClick={option.onClick}
                  icon={option.icon}
                >
                  {option.icon}
                </Option>
              );
            })}
          </div>

          <div className='flex mt-5 md:hidden'>
            {link && (
              <ItemLinkWrapper className='inline-flex justify-center flex-grow block px-4 py-2 mr-2 text-sm text-center text-white rounded-md bg-primary' />
            )}
            {visibleOptions.length > 0 && (
              <div className='relative flex-grow'>
                <button
                  onClick={() => setMenuOpen(!isMenuOpen)}
                  className='flex items-center justify-center flex-grow w-full px-4 py-2 text-sm text-gray-700 border rounded-md'
                >
                  <span>More</span>
                  <ChevronDownIcon className='w-4 h-4 ml-3' />
                </button>
                {isMenuOpen && (
                  <div className='absolute right-0 z-10 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                    {visibleOptions.map(option => {
                      if (option.element) return option.element;

                      return (
                        <Option
                          key={option.label}
                          label={option.label}
                          onClick={() => {
                            option.onClick();
                          }}
                          icon={option.icon}
                        >
                          {option.icon}
                        </Option>
                      );
                    })}
                  </div>
                )}
              </div>
            )}
          </div>
          <div className='md:divide-y md:divide-gray-300'>
            {(!viewerCanEditList || isChildAccount) && !isArchived && (
              <Reserved item={item} status={reservationStatus} />
            )}

            <CollectionList
              collections={collections ?? { edges: [] }}
              profileSlug={profile?.slug}
            />
            <Comments comments={item.comments?.edges ?? []} itemId={item.id} />
          </div>
        </div>
      </div>
    </div>
  );
};

Item.fragments = {
  item: gql`
    fragment ItemData on ListItem {
      id
      title
      link
      url
      quantity
      slug
      isArchived
      createdAt
      notes
      affiliate {
        network
      }
      ...ItemReserved
      collections {
        ...CollectionList
      }
      image {
        ...ItemSourceImage
      }
      profile {
        id
        slug
        name
        viewerCanEditList
        user {
          id
        }
        image {
          fixed(input: { ratio: 1, width: 125 })
        }
        collections {
          edges {
            node {
              id
              title
              slug
            }
          }
        }
      }
      ...ItemComments
    }
    ${CollectionList.fragments.itemCollections}
    ${Comments.fragments.comments}
    ${Image.fragments.image}
    ${Reserved.fragments.itemReserved}
  `,
  viewer: gql`
    fragment ViewerInfo on Viewer {
      isAuthenticated
      profile {
        id
        slug
        user {
          id
        }
      }
    }
  `,
};

export default function Context() {
  const { itemSlug } = useParams();
  usePageView('Item');
  const [component, setContext] = useState('item');
  useScrollToTop();
  const { data, loading, error } = useGetItemQuery({
    variables: { input: { item: itemSlug } },
    fetchPolicy: 'cache-first',
    returnPartialData: true,
  });

  if (loading && isEmpty(data?.item)) {
    return (
      <div className='py-5'>
        <Loading />
      </div>
    );
  }

  if (error) {
    console.error(error);
    return 'There was an error fetching the item data';
  }

  if (!data?.item || !Object.keys(data.item).length) {
    console.log('Not Found');

    return <NotFound />;
  }

  return (
    <>
      <Item
        item={data?.item}
        viewer={data?.viewer}
        setContext={() => setContext('edit')}
      />
      {data?.item && (
        <Edit
          item={data?.item}
          open={component === 'edit'}
          setOpen={() => setContext('item')}
        />
      )}
    </>
  );
}

gql`
  query getItem($input: ItemSearchInput!) {
    viewer {
      ...ViewerReserved
      isAuthenticated
      profile {
        id
        slug
        user {
          id
        }
      }
    }
    item(input: $input) {
      ...ItemData
      profile {
        id
        slug
        collections {
          edges {
            node {
              id
              slug
              title
            }
          }
        }
      }
    }
  }
  ${Item.fragments.item}
  ${Reserved.fragments.viewerReserved}
`;

gql`
  mutation updateItem($input: UpdateListItemInput!) {
    updateListItem(input: $input) {
      listItem {
        ...ItemData
      }
    }
  }
  ${Item.fragments.item}
`;

gql`
  mutation reserveItem($input: ReserveListItemInput!) {
    reserveListItem(input: $input) {
      listItem {
        ...ItemData
      }
    }
  }
  ${Item.fragments.item}
`;

gql`
  mutation copyItem($input: CopyListItemInput!) {
    copyListItem(input: $input) {
      listItem {
        ...ItemData
      }
    }
  }
  ${Item.fragments.item}
`;
