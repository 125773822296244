import React from 'react';

interface SpinnerProps {
  className?: String;
}

interface LoadingProps {
  className?: String;
}

export const Spinner = ({ className }: SpinnerProps) => {
  return (
    <svg
      className={`animate-spin -ml-1 h-8 w-8 ${className}`}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
    >
      <path
        fill='currentColor'
        d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
      ></path>
    </svg>
  );
};

export const Loading = ({ className }: LoadingProps) => {
  return (
    <div className='flex items-center justify-center h-50'>
      <Spinner className={`text-primary ${className}`} />
    </div>
  );
};
