import { gql } from '@apollo/client';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetProfileFollowersQuery, User } from '../../generated/graphql';
import { Loading } from '../Loading';
import ProfileList from '../ProfileList';

export default function Followers() {
  const { slug } = useParams();

  const { data, loading, error } = useGetProfileFollowersQuery({
    variables: { input: { slug: slug } },
    fetchPolicy: 'cache-and-network',
  });

  if (error) {
    console.error(error);
  }

  if (loading) {
    return <Loading />;
  }

  const edges = data?.profile?.followers?.edges ?? [];
  const profiles = edges.map(({ node: user }: { node: User }) => {
    return { node: user.profile };
  });

  if (!profiles.length) {
    return <div>No users found</div>;
  } else {
    return <ProfileList profiles={profiles} />;
  }
}

gql`
  query getProfileFollowers($input: ProfileSearchInput!) {
    profile(input: $input) @connection(key: "profile") {
      id
      slug
      followers {
        edges {
          node {
            profile {
              ...ProfileData
            }
          }
        }
      }
    }
  }
  ${ProfileList.fragments.profile}
`;
