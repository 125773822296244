import React from 'react';
import { useItemLayout } from '../../lib/hooks';
import SortButton from '../SortButton';

export default () => {
  const [itemView, setView] = useItemLayout();

  return (
    <div className='col-auto pr-0 md:space-x-2'>
      <SortButton className='border border-gray-400 md:border-gray -mr-px px-2 rounded-l-lg md:border-none md:p-0' />
      <button
        className={`border border-gray-400 px-2 md:border-none  md:p-0 ${
          itemView === 'masonry' ? 'text-primary' : 'text-gray-400'
        }`}
        onClick={() => setView('masonry')}
      >
        <i className='fas fa-th-large'></i>
      </button>
      <button
        className={`border border-gray-400 -ml-px px-2 md:p-0 rounded-r-lg md:border-none ${
          itemView === 'condensed' ? 'text-primary' : 'text-gray-400'
        }`}
        onClick={() => setView('condensed')}
      >
        <i className='fas fa-list-ul'></i>
      </button>
    </div>
  );
};
