import React, { useState, useCallback, SetStateAction, useEffect } from 'react';
import ReactGA from 'react-ga';
import { Modal } from '../Modal';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { ReactComponent as CheckCircle } from '../../icons/check-circle.svg';
import { Option } from '../../pages/item/option';
import CheckIcon from '@heroicons/react/solid/CheckIcon';
import { Dispatch } from 'react';
import {
  ItemDataFragment,
  ItemReservedFragment,
  useReserveItemMutation,
  ViewerReservedFragment,
} from '../../generated/graphql';
import gql from 'graphql-tag';
import mixpanel from 'mixpanel-browser';

type ItemReserveType = Pick<
  ItemDataFragment,
  'id' | 'quantity' | 'reserved' | 'viewerHasReservedQuantity'
>;

export enum ReservationStatus {
  USER_NONE = 'USER_NONE',
  USER_PARTIAL = 'USER_PARTIAL',
  VISITOR = 'VISITOR',
  FULFILLED = 'FULFILLED',
  NONE = 'NONE',
}

interface IReservationStatus {
  isAuthenticated: boolean;
  isChildAccount: boolean;
  viewerCanEditList: boolean;
  isProxyProfile: boolean;
  item: ItemReservedFragment;
}

export const getReservationStatus = ({
  viewerCanEditList,
  isChildAccount,
  isAuthenticated,
  isProxyProfile,
  item,
}: IReservationStatus): ReservationStatus | null => {
  const { reserved, quantity, viewerHasReservedQuantity } = item;

  if (
    ((viewerCanEditList === false || isChildAccount) && isAuthenticated) ||
    isProxyProfile
  ) {
    if (!viewerHasReservedQuantity) {
      if (quantity - reserved >= 1) {
        if (!isAuthenticated) {
          return ReservationStatus.VISITOR;
        } else {
          return ReservationStatus.USER_NONE;
        }
      } else {
        return ReservationStatus.FULFILLED;
      }
    } else {
      return ReservationStatus.USER_PARTIAL;
    }
  }

  return null;
};

const Button = ({ children, onClick }) => (
  <button key={children} onClick={onClick} className='text-blue-600'>
    <div className='text-sm'>{children}</div>
  </button>
);

export const ReservedIcon = () => (
  <span className='flex items-center px-2 space-x-1 text-xs bg-white border-2 select-none rounded-xl border-primary'>
    <CheckCircle className='transform scale-125' />
    <span>Reserved</span>
  </span>
);

interface ReservedProps {
  item: ItemReservedFragment;
  status: ReservationStatus | null;
}

export const ReservedBtn = ({
  status,
  item,
}: Omit<ReservedProps, 'viewer'>) => {
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const location = useLocation();

  let btn;

  switch (status) {
    case ReservationStatus.USER_NONE:
      btn = (
        <Option
          label='Reserve Item'
          onClick={() => setOpen(true)}
          icon={CheckIcon}
        />
      );
      break;
    case ReservationStatus.USER_PARTIAL:
      btn = (
        <Option
          label='Change Reservation'
          onClick={() => setOpen(true)}
          icon={CheckIcon}
        />
      );
      break;
    case ReservationStatus.VISITOR:
      btn = (
        <Option
          label='Login to reserve item'
          onClick={() =>
            history.push(`/login?r=${encodeURIComponent(location.pathname)}`)
          }
          icon={CheckIcon}
        />
      );
  }

  return (
    <>
      {btn}
      <ReserveModal item={item} open={open} setOpen={setOpen} />
    </>
  );
};

const Reserved = ({ item, status }: ReservedProps) => {
  const [open, setOpen] = useState(false);
  const { reserved, quantity, viewerHasReservedQuantity } = item;
  const location = useLocation();

  let btn;

  switch (status) {
    case ReservationStatus.USER_NONE:
      btn = <Button onClick={() => setOpen(true)}>Reserve Item</Button>;
      break;
    case ReservationStatus.USER_PARTIAL:
      btn = <Button onClick={() => setOpen(true)}>Change Reservation</Button>;
      break;
    case ReservationStatus.VISITOR:
      btn = (
        <Link
          to={`/login?r=${encodeURIComponent(location.pathname)}`}
          className='text-blue-600'
        >
          Login to reserve this item
        </Link>
      );
      break;
  }

  let amountReservedElem: any = null;
  let viewerReservedElem: any = null;

  if (
    status === ReservationStatus.USER_PARTIAL ||
    status === ReservationStatus.USER_NONE ||
    status === ReservationStatus.FULFILLED
  ) {
    amountReservedElem = (
      <div className='inline text-sm text-gray-900'>
        {reserved} of {quantity} items reserved
      </div>
    );
  }

  if (viewerHasReservedQuantity) {
    viewerReservedElem = (
      <span className='pr-2 text-sm text-gray-900'>
        You reserved {viewerHasReservedQuantity} Items
      </span>
    );
  }

  if (!amountReservedElem && !viewerReservedElem && !btn) {
    return null;
  }

  return (
    <div className='py-5 space-y-1' data-cy='reservationGroup'>
      <div className='text-sm font-medium text-gray-800'>Reservations</div>
      <div className='text-smdivide-x'>{amountReservedElem}</div>
      <div className='text-sm divide-x'>
        {viewerReservedElem}
        <span className='even:pl-2'>{btn}</span>
      </div>
      <ReserveModal item={item} open={open} setOpen={setOpen} />
    </div>
  );
};

Reserved.fragments = {
  itemReserved: gql`
    fragment ItemReserved on ListItem {
      id
      quantity
      viewerHasReservedQuantity
      reserved
    }
  `,
  viewerReserved: gql`
    fragment ViewerReserved on Viewer {
      profile {
        id
      }
    }
  `,
};

interface ReserveModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  item: ItemReserveType;
}

export const ReserveModal = ({ item, open, setOpen }: ReserveModalProps) => {
  const { id, quantity, reserved, viewerHasReservedQuantity } = item;
  const [count, setCount] = useState(viewerHasReservedQuantity || 1);
  const [resItem] = useReserveItemMutation();

  useEffect(() => {
    setCount(viewerHasReservedQuantity || 1);
  }, [viewerHasReservedQuantity]);

  const reserveItem = useCallback(
    e => {
      let action = 'Reserve';
      let amount = Number(count);
      if (isRemoving(item)) {
        amount = 0;
        action = 'Remove';
      }

      e.preventDefault();
      resItem({
        variables: { input: { listItemId: id, quantity: amount } },
      });

      ReactGA.event({
        category: 'Item',
        action,
      });

      mixpanel.track(isRemoving(item) ? 'Clear Reservation' : 'Reserve Item');
    },
    [resItem, count, item, id]
  );

  const amountAvailableToViewer =
    quantity - (reserved - viewerHasReservedQuantity);
  const error = count > amountAvailableToViewer || count < 0;

  const options: React.ReactNode[] = [];

  for (let i: number = 0; i <= quantity; i++) {
    options.push(
      <option key={i} disabled={i > amountAvailableToViewer} value={i}>
        {i}
      </option>
    );
  }

  return (
    <Modal
      title={isRemoving(item) ? 'Remove Reservation' : 'Reserve Item'}
      label={isRemoving(item) ? 'Remove' : 'Reserve'}
      onSave={reserveItem}
      open={open}
      setOpen={setOpen}
    >
      {quantity !== 1 && amountAvailableToViewer !== 1 && (
        <div className='flex items-center space-x-2'>
          <select
            className='rounded-md border-gray-light focus:ring-primary/20 focus:border-gray-light focus:ring-4'
            onChange={e => {
              setCount(Number(e.target.value));
            }}
            value={count}
            data-cy='selectReservation'
          >
            {options}
          </select>
          <div>{` of ${amountAvailableToViewer} items`}</div>
        </div>
      )}
      {error && (
        <div className='px-3 pt-1 text-red-500'>
          Please enter a valid amount
        </div>
      )}
    </Modal>
  );
};

const isRemoving = (item: ItemReserveType) => {
  const { viewerHasReservedQuantity, reserved, quantity } = item;
  const amountAvailableToViewer =
    quantity - (reserved - viewerHasReservedQuantity);

  if (viewerHasReservedQuantity) {
    if (amountAvailableToViewer === 1) {
      return true;
    } else if (quantity === 1) {
      return true;
    }
  }
  return false;
};

export default Reserved;
