import React, { useCallback, useEffect, useState } from 'react';
import { gql } from '@apollo/client';
import ProfileList from '../components/ProfileList';
import { Link, useHistory } from 'react-router-dom';
import { Loading } from '../components/Loading';
import { usePageView, useQueryParams } from '../lib/hooks';
import Input from '../components/Input';
import { ReactComponent as SearchIcon } from '../icons/Search.svg';
import { useGetProfilesLazyQuery } from '../generated/graphql';

const PROFILES_KEY = 'profiles';

const RecentlySearched = () => {
  const [forceUpdate, setUpdate] = useState(false);
  let profiles = JSON.parse(localStorage.getItem(PROFILES_KEY) || '[]') || [];

  const clearStorage = () => {
    localStorage.clear();
    setUpdate(!forceUpdate);
  };

  return (
    <div className='container flex flex-col py-4'>
      <div className='flex items-center justify-between mb-3'>
        <h4 className='mb-0 font-bold'>Recently Searched</h4>
        <button className='text-blue-700' onClick={clearStorage}>
          Clear
        </button>
      </div>
      <div className='flex flex-col space-y-1'>
        {profiles.map(profile => (
          <Link key={profile.slug} to={`/wishlist/${profile.slug}`}>
            {profile.name}
          </Link>
        ))}
      </div>
    </div>
  );
};

const SearchResults = ({ profiles, setRecentQuery, loading }) => {
  const onProfileClick = profile => {
    setRecentQuery(profile);
  };

  return (
    <div className='container max-w-2xl'>
      <ProfileList profiles={profiles} onClick={onProfileClick} />
      {loading && <Loading />}
      {!profiles.length && !loading && (
        <div className='py-3 text-center md:text-left'>
          No users matching your search
        </div>
      )}
    </div>
  );
};

export default function Search() {
  usePageView('Search');
  const params = useQueryParams();
  const history = useHistory();
  const [query, updateQuery] = useState(params.get('q') || '');
  const [value, setValue] = useState(params.get('q') || '');

  const [searchProfiles, { data, loading, error }] = useGetProfilesLazyQuery();

  const executeSearch = useCallback(() => {
    searchProfiles({
      variables: {
        search: value,
      },
    });
    updateQuery(value);
  }, [searchProfiles, value]);

  useEffect(() => {
    if (query) {
      executeSearch();
    }
    // eslint-disable-next-line
  }, []);

  if (error) {
    console.error(error);
    return 'There was an error fetching the search results';
  }

  const setRecentQuery = profile => {
    var existing = JSON.parse(localStorage.getItem(PROFILES_KEY) || '[]') || [];
    const data = { name: profile.name, slug: profile.slug };
    existing.unshift(data);
    localStorage.setItem(PROFILES_KEY, JSON.stringify(existing));

    executeSearch();
  };

  const icon = (
    <button
      onClick={executeSearch}
      className='p-1 px-2 transition duration-200 rounded-md hover:bg-primary hover:text-white'
    >
      <SearchIcon className='fill-current' />
    </button>
  );

  return (
    <>
      <div className='pt-3 md:pt-0 md:hidden'>
        <form
          className='flex flex-col max-w-lg p-2 md:pr-10'
          onSubmit={e => {
            e.preventDefault();
            executeSearch();
          }}
        >
          <Input
            className='w-full'
            placeholder='Search Users'
            onChange={e => setValue(e.target.value)}
            value={value}
            icon={icon}
            data-cy='search-input'
          />
        </form>
      </div>

      {!query ? (
        <RecentlySearched />
      ) : (
        <div className='pb-8'>
          <SearchResults
            loading={loading}
            profiles={data?.profiles?.edges ?? []}
            setRecentQuery={setRecentQuery}
          />
        </div>
      )}
    </>
  );
}

gql`
  query getProfiles($search: String!, $count: Int! = 10000) {
    viewer {
      profile {
        id
        slug
      }
    }
    profiles(orderBy: UPDATED, first: $count, search: $search) {
      edges {
        node {
          ...ProfileData
        }
      }
    }
  }
  ${ProfileList.fragments.profile}
`;
