import React from 'react';
import { Image } from '../Item/Image';
import { ProfileLink } from '../Profile/Link';
import { Link } from 'react-router-dom';
import { ReservedIcon } from '../Item/Reserved';
import { Transition } from '@headlessui/react';
import { useDelay } from '../../lib/hooks';
import gql from 'graphql-tag';
import { ItemThumbnailFragment } from '../../generated/graphql';

interface IMinimalItemProps {
  item: ItemThumbnailFragment;
  hideName?: boolean;
  showReserved?: boolean;
  delay?: number;
}

const MinimalItem = ({
  item,
  hideName,
  showReserved,
  delay = 0,
}: IMinimalItemProps) => {
  const isReserved = showReserved && item.quantity - item.reserved === 0;
  const isDone = useDelay(delay);

  return (
    <Transition
      show={isDone}
      appear
      enter='transition-opacity transition-transform ease-in duration-300'
      enterFrom='opacity-0 transform -translate-x-2'
      enterTo='opacity-100'
      leave='transition-opacity duration-900'
      leaveFrom='opacity-100'
      leaveTo='opacity-0'
    >
      <Link
        to={`/wishlist/${item.profile?.slug}/${item.slug}`}
        className='flex items-center py-3 pl-1 space-x-3 text-black no-underline h-18 hover:bg-primary hover:bg-opacity-10'
      >
        <Image
          className='w-10 h-10 rounded-lg'
          item={item}
          image={item.image || null}
          fixed
        />
        <div className='flex flex-wrap items-center h-full overflow-x-hidden leading-none'>
          <div className='w-full text-sm truncate'>{item.title || '-'}</div>
          <span className='flex items-center space-x-4'>
            {!hideName && <ProfileLink profile={item.profile} diameter={16} />}
            {isReserved && <ReservedIcon />}
          </span>
        </div>
      </Link>
    </Transition>
  );
};

MinimalItem.fragments = {
  item: gql`
    fragment ItemCondensed on ListItem {
      id
      title
      slug
      reserved
      quantity
      image {
        ...ItemImage
      }
      profile {
        id
        ...ProfileThumbnail
      }
    }
    ${ProfileLink.fragments.profile}
    ${Image.fragments.image}
  `,
};

const Condensed = ({ items, hideName, showReserved }) => {
  return (
    <div className='divide-y divide-gray-light'>
      {items.map(({ node: item }, idx) => (
        <MinimalItem
          key={item.id}
          delay={(idx % 15) * 60}
          item={item}
          hideName={hideName}
          showReserved={showReserved}
        />
      ))}
    </div>
  );
};

export default Condensed;
