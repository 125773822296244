import React from 'react';
import { Switch, Route, Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Tab } from '../../components/Tab';
import ViewerFeed from './feed';
import Explore from './explore';
import Disclaimer from '../../components/AffiliateDisclaimer';

export default function Home() {
  const location = useLocation();

  return (
    <>
      <Disclaimer />
      <ul className='flex justify-start px-4 py-4 md:px-0'>
        <Tab isActive={location.pathname === '/feed'}>
          <Link
            to='/feed'
            className='px-3 text-sm no-underline text-inherit md:text-base'
          >
            Following
          </Link>
        </Tab>
        <Tab isActive={location.pathname === '/explore'}>
          <Link
            to='/explore'
            className='px-3 text-sm no-underline text-inherit md:text-base'
          >
            Explore
          </Link>
        </Tab>
      </ul>
      <div className='p-3'>
        <Switch>
          <Route exact path='/feed' component={ViewerFeed} />
          <Route exact path='/explore' component={Explore} />
        </Switch>
      </div>
    </>
  );
}
